import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import RHFRemoteAutocomplete from "../../components/hook-form/RHFRemoteAutocomplete";
import { LoadingButton } from "@mui/lab";
import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { FormProvider } from "../../components/hook-form";
import { dispatch, useSelector } from "../../redux/store";
import {
    closeAreasSelectModal,
    setLevel1Data,
    setLevel2Data,
    setLevel3Data,
    setPostData
} from "../../redux/slices/areasSelect";


interface AreasSelectDialogProps {
    onSave?: ((areaData: {}) => any) | null;
}

export default function AreasSelectDialog({ onSave = null, ...otherProps }: AreasSelectDialogProps) {

    const {
        modalIsOpen,
        urls
    } = useSelector((state) => state.areasSelect);

    const { t } = useTranslation('areas', { keyPrefix: 'areas_modal' });

    const defaultValues = {
        area_1_level: null,
        area_2_level: null,
        area_3_level: null,
        area_4_level: null,
    };

    const AreasSchema = Yup.object().shape({
        area_1_level: Yup.mixed().required(t('validator.area_1_level.required')),
    });

    const methods = useForm({ resolver: yupResolver(AreasSchema), defaultValues });

    const { reset, handleSubmit, setValue } = methods;

    const onSubmit = (data: {}) => {
        if (onSave) {
            onSave(data);
        }
        dispatch(setPostData(data));
        dispatch(closeAreasSelectModal());
        reset(defaultValues);
    };

    const onChangeL1 = (e: any, newValue: any) => {
        dispatch(setLevel1Data(newValue));
        setValue('area_2_level', null)
        dispatch(setLevel2Data({}));
        setValue('area_3_level', null)
        dispatch(setLevel3Data({}));
        setValue('area_4_level', null)
    };
    const onChangeL2 = (e: any, newValue: any) => {
        dispatch(setLevel2Data(newValue));
        setValue('area_3_level', null)
        dispatch(setLevel3Data({}));
        setValue('area_4_level', null)
    };
    const onChangeL3 = (e: any, newValue: any) => {
        dispatch(setLevel3Data(newValue));
        setValue('area_4_level', null)
    };

    return (
        <Dialog open={modalIsOpen} fullWidth maxWidth="sm">
            <DialogTitle>
                {t('title')}
            </DialogTitle>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

                <DialogContent dividers={true}>
                    <Stack spacing={3}>
                        <RHFRemoteAutocomplete
                            inputLabel={t('area_1_level.label')}
                            remoteUrl={urls.level1}
                            name={'area_1_level'}
                            onChange={onChangeL1}
                        />
                        <RHFRemoteAutocomplete
                            inputLabel={t('area_2_level.label')}
                            disabled={!urls.level2}
                            remoteUrl={urls.level2}
                            name={'area_2_level'}
                            onChange={onChangeL2}
                        />
                        <RHFRemoteAutocomplete
                            inputLabel={t('area_3_level.label')}
                            disabled={!urls.level3}
                            remoteUrl={urls.level3}
                            name={'area_3_level'}
                            onChange={onChangeL3}
                        />
                        <RHFRemoteAutocomplete
                            inputLabel={t('area_4_level.label')}
                            disabled={!urls.level4}
                            remoteUrl={urls.level4}
                            name={'area_4_level'}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <LoadingButton variant="outlined" color="inherit" onClick={() => dispatch(closeAreasSelectModal())}>
                        {t('common:button.cancel')}
                    </LoadingButton>
                    <LoadingButton variant="contained" color="primary" type="submit">
                        {t('common:button.save')}
                    </LoadingButton>
                </DialogActions>
            </FormProvider>

        </Dialog>
    );
};