import { lazy, useEffect, useState } from "react";
import useTabs from "../../../../hooks/useTabs";
import { PATH_DASHBOARD } from "../../../../routes/paths";
import { useNavigate } from "react-router-dom";
import { TenderState } from "../../../../@types/tender";

const TabBasicInfo = lazy(() => import('./step-tender-data/TabBasicInfo'))
const TabServices = lazy(() => import('./step-tender-data/TabServices'))
const TabFacilities = lazy(() => import('./step-tender-data/TabFacilities'))
const TabDetails = lazy(() => import('./step-tender-data/TabDetails'))
const TabCriteria = lazy(() => import('./step-tender-data/TabCriteria'))
const TabSearch = lazy(() => import('./step-search/TabSearch'))
const TabInvite = lazy(() => import('./step-search/TabInvite'))
const TabManageTenderClients = lazy(() => import('./step-tender/TabManageTenderClients'))
const TabPublish = lazy(() => import('./step-tender/TabPublish'))
const TabQuestions = lazy(() => import('./step-tender/TabQuestions'))
const TabOffers = lazy(() => import('./step-tender/TabOffers'))
const TabOffersAnalysis = lazy(() => import('./step-decision/TabOffersAnalysis'))
const TabDecision = lazy(() => import('./step-decision/TabDecision'))

const enum FacilityManagerTenderSteps {
    STEP_TENDER_DATA = 1,
    STEP_SEARCH = 2,
    STEP_TENDER = 3,
    STEP_DECISION = 4
}

export enum FacilityManagerTenderTabs {
    TAB_BASIC_INFO = "tab-basic-info",
    TAB_SERVICES = "tab-services",
    TAB_FACILITIES = "tab-facilities",
    TAB_DETAILS = "tab-details",
    TAB_CRITERIA = "tab-criteria",
    TAB_SEARCH = "tab-search",
    TAB_INVITE = "tab-invite",
    TAB_MANAGE_TENDER_CLIENTS = "tab-manage-tender-clients",
    TAB_PUBLISH = "tab-publish",
    TAB_QUESTIONS = "tab-questions",
    TAB_OFFERS = "tab-offers",
    TAB_OFFER_ANALYSIS = "tab-offer-analysis",
    TAB_DECISION = "tab-decision"
}

type TabsType = {
    step: FacilityManagerTenderSteps;
    value: string;
    component: Function;
    sliceKey?: keyof TenderState;
};

const TABS: TabsType[] = [
    // TABS STEP 1
    {
        step: FacilityManagerTenderSteps.STEP_TENDER_DATA,
        value: FacilityManagerTenderTabs.TAB_BASIC_INFO,
        component: TabBasicInfo,
        sliceKey: 'tabBasicInfo',
    }, {
        step: FacilityManagerTenderSteps.STEP_TENDER_DATA,
        value: FacilityManagerTenderTabs.TAB_SERVICES,
        component: TabServices,
        sliceKey: 'tabServices',
    }, {
        step: FacilityManagerTenderSteps.STEP_TENDER_DATA,
        value: FacilityManagerTenderTabs.TAB_FACILITIES,
        component: TabFacilities,
        sliceKey: 'tabFacilities',
    }, {
        step: FacilityManagerTenderSteps.STEP_TENDER_DATA,
        value: FacilityManagerTenderTabs.TAB_DETAILS,
        component: TabDetails,
        sliceKey: 'tabDetails',
    }, {
        step: FacilityManagerTenderSteps.STEP_TENDER_DATA,
        value: FacilityManagerTenderTabs.TAB_CRITERIA,
        component: TabCriteria,
        sliceKey: 'tabCriteria',
    },

    // TABS STEP 2
    {
        step: FacilityManagerTenderSteps.STEP_SEARCH,
        value: FacilityManagerTenderTabs.TAB_SEARCH,
        component: TabSearch,
        sliceKey: 'tabSearch',
    }, {
        step: FacilityManagerTenderSteps.STEP_SEARCH,
        value: FacilityManagerTenderTabs.TAB_INVITE,
        component: TabInvite,
        sliceKey: 'tabInvite',
    },

    // TABS STEP 3
    {
        step: FacilityManagerTenderSteps.STEP_TENDER,
        value: FacilityManagerTenderTabs.TAB_MANAGE_TENDER_CLIENTS,
        component: TabManageTenderClients,
        sliceKey: 'tabManageTenderClients',
    }, {
        step: FacilityManagerTenderSteps.STEP_TENDER,
        value: FacilityManagerTenderTabs.TAB_PUBLISH,
        component: TabPublish,
        sliceKey: 'tabPublish',
    }, {
        step: FacilityManagerTenderSteps.STEP_TENDER,
        value: FacilityManagerTenderTabs.TAB_QUESTIONS,
        component: TabQuestions,
        sliceKey: 'tabQuestions',
    }, {
        step: FacilityManagerTenderSteps.STEP_TENDER,
        value: FacilityManagerTenderTabs.TAB_OFFERS,
        component: TabOffers,
        sliceKey: 'tabOffers',
    },

    //TABS STEP 4
    {
        step: FacilityManagerTenderSteps.STEP_DECISION,
        value: FacilityManagerTenderTabs.TAB_OFFER_ANALYSIS,
        component: TabOffersAnalysis,
        sliceKey: 'tabOfferAnalysis',
    }, {
        step: FacilityManagerTenderSteps.STEP_DECISION,
        value: FacilityManagerTenderTabs.TAB_DECISION,
        component: TabDecision,
        sliceKey: 'tabDecision'
    }
];

export default function useFacilityManagerTenderNavigation(rfpId: string, requestedTab: string) {
    const navigate = useNavigate();

    const [completedStep, setCompletedStep] = useState<{ [k: number]: boolean; }>({});

    const [stepPopoverAnchorElement, setStepPopoverAnchorElement] = useState<HTMLButtonElement|null>(null);

    const [activeStep, setActiveStep] = useState(0);

    const { currentTab, setCurrentTab } = useTabs(requestedTab);

    const isStepPopoverOpen = Boolean(stepPopoverAnchorElement);

    const handleStepPopoverOpen = (event: React.MouseEvent<HTMLButtonElement>, step: number) => {
        setActiveStep(step);
        setStepPopoverAnchorElement(event.currentTarget);
    };

    const handleStepPopoverClose = () => {
        setStepPopoverAnchorElement(null);
    };

    const onChangeTab = (event: React.SyntheticEvent<Element, Event>, newValue: any) => {
        setCurrentTab(newValue);
        navigate(`${ PATH_DASHBOARD.tender.details(rfpId, newValue) }`)
    };

    useEffect(() => {
        setCurrentTab(requestedTab);
    }, [requestedTab, setCurrentTab]);

    useEffect(() => {
        const index = TABS.findIndex(object => object.value === currentTab);
        setCompletedStep({ ...[...Array(TABS[index].step)].map(k => true) });
        setStepPopoverAnchorElement(null);
    }, [currentTab]);

    return {
        TABS,
        completedStep,
        setCompletedStep,
        stepPopoverAnchorElement,
        handleStepPopoverOpen,
        handleStepPopoverClose,
        onChangeTab,
        activeStep,
        currentTab,
        isStepPopoverOpen
    };
}