// @mui
import { TableRow, TableCell } from '@mui/material';
//
import EmptyContent from '../EmptyContent';
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

type Props = {
    isNotFound: boolean;
};

export default function TableNoData({ isNotFound }: Props) {

    const { t } = useTranslation('', { keyPrefix: 'table.no_data' });
    return (
        <TableRow>
            { isNotFound ? (
                <TableCell colSpan={ 12 }>
                    <EmptyContent
                        title={t('title')}
                        sx={ {
                            '& span.MuiBox-root': { height: 160 },
                        } }
                    />
                </TableCell>
            ) : (
                <TableCell colSpan={ 12 } sx={ { p: 0 } }/>
            ) }
        </TableRow>
    );
}
