// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';
import { DesktopDatePicker } from "@mui/x-date-pickers";

// ----------------------------------------------------------------------

type IProps = {
    name: string;
    label: string;
};

type Props = IProps&TextFieldProps;

export default function RHFDateTimePicker({ name, label, ...other }: Props) {
    const { control } = useFormContext();

    return (
        <Controller
            name={ name }
            control={ control }
            render={ ({ field, fieldState: { error } }) =>
                (
                    <DesktopDatePicker
                        { ...field }
                        showToolbar={ true }
                        label={ label }
                        mask={ '__.__.____' }
                        disabled={ other.disabled || false }
                        inputFormat="dd.MM.yyyy"
                        renderInput={ (params) =>
                            <TextField
                                { ...params }
                                error={ !!error }
                                helperText={ error?.message }
                                { ...other }
                                fullWidth
                            /> }
                    />
                ) }
        />
    );
}
