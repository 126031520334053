import { m } from 'framer-motion';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, SxProps } from '@mui/material';
//
import ProgressBar from './ProgressBar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    right: 0,
    bottom: 0,
    zIndex: 99999,
    width: '100%',
    height: '100%',
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

type Props = {
    isDashboard?: boolean;
    sx?: SxProps;
};

export default function LoadingScreen({ isDashboard, ...other }: Props) {
    return (
        <>
            <ProgressBar/>

            { !isDashboard && (
                <RootStyle { ...other }>
                    <m.div
                        animate={ {
                            scale: [1, 0.9, 0.9, 1, 1],
                            opacity: [1, 0.48, 0.48, 1, 1],
                        } }
                        transition={ {
                            duration: 2,
                            ease: 'easeInOut',
                            repeatDelay: 1,
                            repeat: Infinity,
                        } }
                    >
                        <Box sx={ { width: 40, height: 40 } }>
                            <svg width="100%" height="100%" viewBox="0 0 48 48" version="1.1"
                                id="svg10" xmlns="http://www.w3.org/2000/svg"
                            >
                                <defs id="defs14" />
                                <g id="g1044" transform="translate(2.9999998,0.6660155)">
                                    <g id="g968" transform="translate(-6.9999998,-2.332031)">
                                        <g id="g962">
                                            <path
                                                fill="#5e88b2"
                                                d="M 23.332031,23.59375 V 2.332031 L 49,16.332031 V 49 H 23.332031 Z m 0,0"
                                                id="path4"
                                            />
                                            <path
                                                fill="#7cb9ae"
                                                d="M 23.332031,23.59375 49,36.167969 V 49 H 7 V 16.332031 Z m 0,0"
                                                id="path6"
                                            />
                                        </g>
                                        <path
                                            fill="#61a6a4"
                                            d="M 23.332031,23.59375 49,36.167969 V 49 H 23.332031 Z m 0,0"
                                            id="path8"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </Box>
                    </m.div>

                    <Box
                        component={ m.div }
                        animate={ {
                            scale: [1.2, 1, 1, 1.2, 1.2],
                            rotate: [270, 0, 0, 270, 270],
                            opacity: [0.25, 1, 1, 1, 0.25],
                            borderRadius: ['25%', '25%', '50%', '50%', '25%'],
                        } }
                        transition={ { ease: 'linear', duration: 3.2, repeat: Infinity } }
                        sx={ {
                            width: 100,
                            height: 100,
                            borderRadius: '25%',
                            position: 'absolute',
                            border: (theme) => `solid 3px ${ alpha(theme.palette.primary.dark, 0.24) }`,
                        } }
                    />

                    <Box
                        component={ m.div }
                        animate={ {
                            scale: [1, 1.2, 1.2, 1, 1],
                            rotate: [0, 270, 270, 0, 0],
                            opacity: [1, 0.25, 0.25, 0.25, 1],
                            borderRadius: ['25%', '25%', '50%', '50%', '25%'],
                        } }
                        transition={ {
                            ease: 'linear',
                            duration: 3.2,
                            repeat: Infinity,
                        } }
                        sx={ {
                            width: 120,
                            height: 120,
                            borderRadius: '25%',
                            position: 'absolute',
                            border: (theme) => `solid 8px ${ alpha(theme.palette.primary.dark, 0.24) }`,
                        } }
                    />
                </RootStyle>
            ) }
        </>
    );
}
