
export const dashboard = {
    title: 'Dashboard',

    tender_statistics: {
        title: 'Statystyki przetargów',
        settled_tenders: 'Zakończone',
        canceled_tenders: 'Anulowane',
        other_tenders: 'W trakcie',
        go_to_tender_list_button: 'Przejdź do listy przetargów'
    },
    upcoming_tender_dates: {
        title: 'Zbliżające się daty'
    },

    tender_client_statistics: {
        searches_count: 'Wyszukań dostawców',
        found_count: 'Znalezionych dostawców',
        invited_count: 'Zaproszonych dostawców',
    }
};
