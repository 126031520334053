import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
// types
import { SupplierTenderQuestionState } from "../../@types/supplierTenderQuestion";
import { dispatch } from "../store";
import { showSnackbar } from "./snackbar";

// ----------------------------------------------------------------------

const initialState: SupplierTenderQuestionState = {
    supplierTenderQuestionList: {
        isLoading: false,
        error: null,
        list: []
    },

    supplierTenderQuestionPreviewDialog: {
        isLoading: false,
        isOpen: false,
        error: null
    },

    supplierTenderQuestionAskDialog: {
        isLoading: false,
        isOpen: false,
        error: null
    }
};

const slice = createSlice({
    name: 'supplierTenderQuestion',
    initialState,
    reducers: {
        // LIST
        startSupplierTenderQuestionListLoading(state) {
            state.supplierTenderQuestionList.isLoading = true;
        },

        getSupplierTenderQuestionListSuccess(state, action) {
            state.supplierTenderQuestionList.isLoading = false;
            state.supplierTenderQuestionList.list = action.payload;
        },

        supplierTenderQuestionListHasError(state, action) {
            state.supplierTenderQuestionList.isLoading = false;
            state.supplierTenderQuestionList.error = action.payload;
        },

        // PREVIEW DIALOG
        startSupplierTenderQuestionPreviewDialogLoading(state) {
            state.supplierTenderQuestionPreviewDialog.isLoading = true;
        },

        openSupplierTenderQuestionPreviewDialog(state) {
            state.supplierTenderQuestionPreviewDialog.isOpen = true;
        },

        closeSupplierTenderQuestionPreviewDialog(state) {
            state.supplierTenderQuestionPreviewDialog.isOpen = false;
        },

        // ASK DIALOG
        startSupplierTenderQuestionAskDialogLoading(state) {
            state.supplierTenderQuestionAskDialog.isLoading = true;
        },

        openSupplierTenderQuestionAskDialog(state) {
            state.supplierTenderQuestionAskDialog.isOpen = true;
        },

        closeSupplierTenderQuestionAskDialog(state) {
            state.supplierTenderQuestionAskDialog.isOpen = false;
        },

        saveSupplierTenderQuestionSuccess(state) {
            state.supplierTenderQuestionAskDialog = initialState.supplierTenderQuestionAskDialog;
        },

        saveSupplierTenderQuestionHasError(state, action) {
            state.supplierTenderQuestionAskDialog.isLoading = false;
            state.supplierTenderQuestionAskDialog.error = action.payload;
        },
        // ----------------------------------------------------------------------

    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    openSupplierTenderQuestionPreviewDialog,
    closeSupplierTenderQuestionPreviewDialog,
    openSupplierTenderQuestionAskDialog,
    closeSupplierTenderQuestionAskDialog
} = slice.actions;

// ----------------------------------------------------------------------

export function getSupplierTenderQuestionList(id: string) {
    return async () => {
        dispatch(slice.actions.startSupplierTenderQuestionListLoading());
        try {
            const response = await axios.get(`/api/v1/frontend/supplier-tenders/${ id }/questions`);
            dispatch(slice.actions.getSupplierTenderQuestionListSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.supplierTenderQuestionListHasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function saveSupplierTenderQuestion(id: string, data: object) {
    return async () => {
        dispatch(slice.actions.startSupplierTenderQuestionAskDialogLoading());
        try {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            // await axios.post(`/api/v1/frontend/supplier-tenders/${ id }/questions`, data);
            dispatch(slice.actions.saveSupplierTenderQuestionSuccess());
            dispatch(showSnackbar({ message: 'common:success' }));
        } catch (error) {
            dispatch(slice.actions.saveSupplierTenderQuestionHasError(error));
            dispatch(showSnackbar({
                message: 'common:error', options: { variant: 'error' }
            }))
        }
    };
}

// ----------------------------------------------------------------------