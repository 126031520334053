// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
// hooks
import { FACILITY_MANAGER_ADMIN, FACILITY_MANAGER_USER, SUPPLIER_ADMIN, SUPPLIER_USER } from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
    <SvgIconStyle src={ `/assets/icons/navbar/${ name }.svg` } sx={ { width: 1, height: 1 } }/>
);

const navConfig = [
    {
        subheader: 'Ogólne',
        items: [{
            title: 'Strona główna',
            path: '/dashboard/dashboard',
            icon: getIcon('ic_dashboard'),
        }, {
            title: 'Przetargi',
            path: PATH_DASHBOARD.tender.list,
            icon: getIcon('ic_analytics'),
            roles: [FACILITY_MANAGER_ADMIN, FACILITY_MANAGER_USER],
        }, {
            title: 'Przetargi',
            icon: getIcon('ic_analytics'),
            path: PATH_DASHBOARD.supplierTender.list,
            roles: [SUPPLIER_ADMIN, SUPPLIER_USER],
        }],
    },

    {
        subheader: 'management',
        items: [{
            title: 'Zarządzanie użytkownikami',
            path: PATH_DASHBOARD.user.list,
            icon: getIcon('ic_user'),
            roles: [SUPPLIER_ADMIN, FACILITY_MANAGER_ADMIN]
        }, {
            title: 'Katalog obiektów',
            path: '/dashboard/facility/list',
            icon: getIcon('ic_banking'),
            roles: [FACILITY_MANAGER_ADMIN, FACILITY_MANAGER_USER]
        }, {
            title: 'Katalog usług',
            path: '/dashboard/services',
            icon: getIcon('ic_cart'),
            roles: [SUPPLIER_ADMIN, SUPPLIER_USER]
        }, {
            title: 'Dane o organizacji',
            path: '/dashboard/client/information',
            icon: getIcon('ic_stack'),
            roles: [SUPPLIER_ADMIN, FACILITY_MANAGER_ADMIN]
        }],
    },
];

export default navConfig;
