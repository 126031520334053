export const common = {

    button: {
        save: 'Zapisz',
        cancel: 'Anuluj',
        delete: 'Usuń',
        add: 'Dodaj',
        close: 'Zamknij',
        back_to_step: 'Szczegóły',
        restore: 'Przywróć'
    },


    table: {
        selected_actions: {
            selected_one: '{{count}} zaznaczony',
            selected_few: '{{count}} zaznaczone',
            selected_many: '{{count}} zaznaczonych',
            selected_other: '{{count}} zaznaczonych'
        },
        selected_actions_fem: {
            selected_one: '{{count}} zaznaczona',
            selected_few: '{{count}} zaznaczone',
            selected_many: '{{count}} zaznaczonych',
            selected_other: '{{count}} zaznaczonych'
        },
        no_data: {
            title: 'Brak danych'
        },
        pagination: {
            rows_per_page: 'Wierszy na stronie',
            displayed_rows: '{{from}}-{{to}} z {{count}}'
        },
        controls: {
            dense: 'Gęsto',
            all: 'Wszystkie',
        },
        filters: {
            search: 'Szukaj...',
        }
    },

    autocomplete: {
        loading: 'Ładowanie...'
    },

    docs: {
        hi: `Hi`,
        description: `Need help? \n Please check our docs.`,
        documentation: `documentation`,
    },
    app: `app`,
    user: `user`,
    list: `list`,
    edit: `edit`,
    shop: `shop`,
    blog: `blog`,
    post: `post`,
    mail: `mail`,
    chat: `chat`,
    cards: `cards`,
    posts: `posts`,
    create: `create`,
    kanban: `kanban`,
    general: `general`,
    banking: `banking`,
    booking: `booking`,
    profile: `profile`,
    account: `account`,
    product: `product`,
    invoice: `invoice`,
    details: `details`,
    checkout: `checkout`,
    calendar: `calendar`,
    analytics: `analytics`,
    ecommerce: `e-commerce`,
    management: `management`,
    menu_level_1: `menu level 1`,
    menu_level_2: `menu level 2`,
    menu_level_3: `menu level 3`,
    item_disabled: `item disabled`,
    item_label: `item label`,
    item_caption: `item caption`,
    description: `description`,
    other_cases: `other cases`,
    item_by_roles: `item by roles`,
    only_admin_can_see_this_item: `Only admin can see this item`,

    code: {
        feature_not_implemented: 'Funkcja nie jest jeszcze zaimplementowana.'
    },

    dropzone: {
        drop_or_select: 'Wybierz plik',
        drop_files_here: 'Przeciągnij i upuść plik tutaj lub',
        browse: 'kliknij tutaj aby wybrać plik',
        thorough_your_machine: 'z dysku komputera'
    },

    nav: {
        dashboard : {
            management : {
                title: 'Management',
                client : {
                    information : {
                        title: 'Edycja danych organizacji'
                    }
                }
            }
        },
    },

    validator: {
        required: 'To pole jest wymagane',
        invalid_date_format: 'Nieprawidłowy format daty',
        date_bigger_than: '{{date}} musi nastąpić później niż {{other}}',
        date_smaller_than: '{{date}} musi nastąpić wcześniej niż {{other}}',
        date_not_from_past: '{{date}} nie może być datą z przeszłości',
    },

    client_rating_card: {
        total_opinions_one: 'Łącznie {{count}} opinia',
        total_opinions_few: 'Łącznie {{count}} opinie',
        total_opinions_many: 'Łącznie {{count}} opinii',
        total_opinions_other: 'Łącznie {{count}} opinii',
        average_rating: 'Średnia ocen z danych kategorii',
    },

    path: {
        dashboard: 'Dashboard',
        tender_list: 'Lista przetargów',
        tender_details: 'Szczegóły przetargu',
        tender_rating: 'Wystaw ocenę',
        user_list: 'Lista użytkowników'
    },

    header: {
        back_to_backoffice_button: 'Wylogowanie i powrót do backoffice',
        logged_as: 'Jestes zalogowany na {{clientName}}'
    },

    cancel: 'Anuluj',
    delete: 'Usuń',
    back: 'Wstecz',
    next: 'Dalej',
    name: 'Nazwa',
    actions: 'Akcje',
    close: 'Zamknij',
    success: 'Sukces',
    error: 'Błąd',
    today: 'Dziś',
    cancel_tender: 'Anuluj przetarg',
    download_error: 'Błąd podczas pobierania',
    yes: 'Tak',
    no: 'Nie'
};