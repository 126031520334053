import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { dispatch, useSelector } from "../../../redux/store";
import { closeClientServiceDeleteModal, deleteClientService } from "../../../redux/slices/clientService";
import React from "react";
import { useTranslation } from "react-i18next";

interface ClientServiceDeleteDialogProps {
    onDeleteConfirm?: (() => any)|null;
}

export default function ClientServiceDeleteDialog({ onDeleteConfirm = null }: ClientServiceDeleteDialogProps) {

    const { t } = useTranslation('client_service', { keyPrefix: 'client_service_delete_modal' });

    const onConfirm = () => {
        if (clientServiceDeleteModal) {
            dispatch(deleteClientService(clientServiceDeleteModal.clientId, clientServiceDeleteModal.clientServiceId));
            if(onDeleteConfirm) {
                onDeleteConfirm();
            }
        }
    };

    // --- Reducer
    const {
        clientServiceDeleteModal
    } = useSelector((state) => state.clientService)

    return (
        <Dialog fullWidth maxWidth="xs" open={clientServiceDeleteModal.isOpen}>
            <DialogTitle>
                {t('title')}
            </DialogTitle>
            <DialogContent dividers={true}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {t('details')}
                </Typography>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    loading={clientServiceDeleteModal.isLoading}
                    variant="outlined"
                    color="inherit"
                    onClick={() => dispatch(closeClientServiceDeleteModal())}
                >
                    {t('common:button.cancel')}
                </LoadingButton>
                <LoadingButton
                    variant="contained"
                    color="error"
                    loading={clientServiceDeleteModal.isLoading}
                    onClick={onConfirm}>
                    {t('common:button.delete')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}