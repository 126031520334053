import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import axios from "../../utils/axios";
import { unionBy } from "lodash";


const initialState: any = {
    tenderClientRatings: [],
    isLoading: false,
    tenderClientRating: {},
    tenderClientModal: {
        isOpen: false,
        isLoading: false,
    },
};


const slice = createSlice({
    name: 'tenderClientRating',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },

        getTenderClientRatingsSuccess(state, action) {
            state.isLoading = false;
            state.tenderClientRatings = action.payload;
        },

        startModalLoading(state) {
            state.tenderClientModal.isLoading = true;
        },

        closeEditTenderClientRatingModal(state) {
            state.tenderClientModal = initialState.tenderClientModal;
        },

        openEditTenderClientRatingModal(state, action) {
            state.tenderClientModal = {
                isOpen: true,
                isLoading: false,
            };
            state.tenderClientRating = action.payload;
        },
        editTenderClientRatingSuccess(state, action) {
            state.tenderClientModal = initialState.tenderClientModal;
            state.tenderClientRatings = unionBy([ action.payload ], state.tenderClientRatings, obj => obj.client.id);
        },
    },
});

export default slice.reducer;

// export const {  } = slice.actions;

export function closeEditTenderClientRatingModal() {
    return async () => {
        dispatch(slice.actions.closeEditTenderClientRatingModal());
    }
}

export function openTenderClientRatingModal(tenderId: any, clientId: any) {
    return async () => {
        dispatch(slice.actions.startModalLoading());

        try {
            const response: { data: { data: [] } } = await axios.get(
                `/api/v1/frontend/tender/${tenderId}/client/${clientId}/rating`
            );

            dispatch(slice.actions.openEditTenderClientRatingModal(response.data.data))
        } catch (e) {
            ///
        }
    };
}

export function editTenderClientRating(tenderId: any, clientId: any, formData: {}) {
    return async () => {

        const response = await axios.put(
            `/api/v1/frontend/tender/${tenderId}/client/${clientId}/rating`,
            formData
        );

        dispatch(slice.actions.editTenderClientRatingSuccess(response.data.data[0]))
    }
}

export function getTenderClientRatings(tenderId: any) {
    return async () => {
        dispatch(slice.actions.startLoading());

        try {
            const response: { data: { data: [] } } = await axios.get(
                `/api/v1/frontend/tender/${tenderId}/rating`
            );

            dispatch(slice.actions.getTenderClientRatingsSuccess(response.data.data));

        } catch (error) {
            // tbd
        }


    };
}