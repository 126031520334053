import axios from "../../utils/axios";

export type TabManageTenderClientsStateResponse = {
    isReadonly: boolean;
    readonlyReason: string;
}

export const getTabManageTenderClientsState = (rfpId: string) => axios
    .get(`/api/v1/frontend/tenders/facility-manager/${ rfpId }/tab-manage-tender-clients-state`)
    .then((response: { data: { data: TabManageTenderClientsStateResponse } }) => response.data.data)
