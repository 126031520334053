// @mui
import { Theme } from '@mui/material/styles';
import { Box, Checkbox, SxProps, TableCell, TableHead, TableRow, TableSortLabel, } from '@mui/material';

// ----------------------------------------------------------------------

export const visuallyHidden = {
    border: 0,
    margin: -1,
    padding: 0,
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    clip: 'rect(0 0 0 0)',
} as const;

// ----------------------------------------------------------------------

type Props = {
    order?: 'asc'|'desc';
    orderBy?: string;
    headLabel: any[];
    rowCount?: number;
    numSelected?: number;
    onSort?: (id: string) => void;
    onSelectAllRows?: (checked: boolean) => void;
    activeRowCount?: number;
    sx?: SxProps<Theme>;
};

export default function TableHeadCustom({
                                            order,
                                            orderBy,
                                            rowCount = 0,
                                            headLabel,
                                            numSelected = 0,
                                            onSort,
                                            onSelectAllRows,
                                            activeRowCount,
                                            sx,
                                        }: Props) {
    return (
        <TableHead sx={ sx }>
            <TableRow>
                { onSelectAllRows && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={ numSelected > 0 && numSelected < (activeRowCount || rowCount) }
                            checked={ rowCount > 0 && numSelected === (activeRowCount || rowCount) }
                            onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
                                onSelectAllRows(event.target.checked)
                            }
                        />
                    </TableCell>
                ) }

                { headLabel.map((headCell) => (
                    <TableCell
                        key={ headCell.id }
                        align={ headCell.align || 'left' }
                        sortDirection={ orderBy === headCell.id ? order : false }
                        sx={ { width: headCell.width, minWidth: headCell.minWidth } }
                    >
                        { onSort ? (
                            <TableSortLabel
                                hideSortIcon
                                active={ orderBy === headCell.id }
                                direction={ orderBy === headCell.id ? order : 'asc' }
                                onClick={ () => onSort(headCell.id) }
                            >
                                { headCell.label }

                                { orderBy === headCell.id ? (
                                    <Box sx={ { ...visuallyHidden } }>
                                        { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                                    </Box>
                                ) : null }
                            </TableSortLabel>
                        ) : (
                            headCell.label
                        ) }
                    </TableCell>
                )) }
            </TableRow>
        </TableHead>
    );
}
