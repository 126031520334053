
export const user = {
    pages: {
        user_list: {
            page_header: 'Lista użytkowników konta {{clientName}}',
            instructions: 'Poniżej znajdziesz listę użytkowników Twojej organizacji. Nadawaj stosowne role dla poszczególnych osób, modyfikuj, uzupełnij i aktualizuj dane kontaktowe, aby zapewnić szybki kontakt w razie potrzeby w trakcie procesów przetargowych.\n',
            header_tab: 'Zarządzanie użytkownikami',
        }
    },

    sections: {
        edit_dialog: {
            tab_general: {
                role: "Rola",
                submit_button: 'Zapisz i zaktualizuj liste',
                name: 'Imię',
                surname: 'Nazwisko',
                position: 'Stanowisko',
                mobile_phone_number: 'Telefon kontaktowy #1',
                other_phone_number: 'Telefon kontaktowy #2',
                validator: {
                    name: {
                        max: 'Imię nie może być dłuższe niż {{amount}} znaków',
                        required: 'Imię jest wymagane'
                    },
                    surname: {
                        max: 'Nazwisko nie może być dłuższe niż {{amount}} znaków',
                        required: 'Nazwisko jest wymagane'
                    },
                    position: {
                        max: 'Pole pozycja nie może być dłuższe niż {{amount}} znaków',
                        required: 'Pozycja jest wymagana'
                    },
                    role: {
                        required: 'Rola jest wymagana'
                    },
                    email: {
                        required: 'E-mail jest wymagany',
                        email: 'E-mail musi być prawidłowym adresem e-mail',
                        max: 'E-mail nie może być dłuższy niż {{amount}} znaków'
                    }
                }

            }
        }
    }
};