import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Card, Link, Container, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';
// routes
import { PATH_AUTH } from '../../routes/paths';
import useSettings from "../../hooks/useSettings";
import useResponsive from "../../hooks/useResponsive";

import { RootStyle, ContentStyle ,SectionStyle } from "./AuthStyles"

// ----------------------------------------------------------------------

export default function Login() {

    const { themeMode } = useSettings();

    const smUp = useResponsive('up', 'sm');

    return (
        <Page title="Login">
            <RootStyle>

                <Container maxWidth={ false }>
                    <SectionStyle>
                        <Card>
                            { smUp && (<Image
                                visibleByDefault
                                disabledEffect
                                src={ themeMode === 'light' ? "/assets/logo_dark.png" : "/assets/logo_white.png" }
                                alt="logo"
                                sx={ { width: 250, margin: 'auto', mt: 3 } }
                            />) }

                            <Box sx={ { flexGrow: 2 } }>
                                <Typography align={ 'center' } variant="h4" sx={ { mt: 3 } }>
                                    Witaj ponownie na platformie
                                </Typography>
                                <Typography color={ 'primary' } variant="h4" align={ 'center' }
                                            sx={ { px: 5, mt: 0, mb: 0 } }>
                                    Connect Facilities
                                </Typography>
                            </Box>
                            <ContentStyle>
                                <LoginForm/>

                                <Typography variant="body2" sx={ { mt: 3, textAlign: 'center' } }>
                                    Nie masz konta? { '' }
                                    <Link variant="subtitle2" component={ RouterLink }
                                          to={ PATH_AUTH.register }>
                                        Zarejestruj się
                                    </Link>
                                </Typography>
                                <Typography sx={ { width: 400, mt: 3 } } fontSize={ 12 } textAlign={ "center" }>
                                    Projekt ConnectFacilities - cyfrowa, inteligentna platforma zakupowa dla firm
                                    zamawiających usługi oraz dostawców usług z obszaru rynku obsługi nieruchomości,
                                    jest współfinansowany przez Unię Europejską ze środków Europejskiego Funduszu
                                    Rozwoju Regionalnego w ramach Programu Operacyjnego Polska Wschodnia.
                                </Typography>
                                { smUp && (<Image
                                    visibleByDefault
                                    disabledEffect
                                    src={ themeMode === 'light' ? "/assets/fe_dark.png" : "/assets/fe_white.png" }
                                    alt="logo"
                                    sx={ { width: 420, mt: 3 } }
                                />) }
                            </ContentStyle>
                        </Card>
                    </SectionStyle>
                </Container>
            </RootStyle>
        </Page>
    );
}
