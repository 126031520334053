import { Card, Tab, Tabs, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { JSXElementConstructor, ReactElement } from "react";


export type HeaderTab = {
    key?: string;
    value?: string;
    label: string;
    icon?: string | ReactElement<any, string | JSXElementConstructor<any>>;
};

interface HeaderTabsProps {
    selectedTabValue: string;
    tabs: HeaderTab[];
    title: string;
}

const TabsWrapperStyle = styled('div')(({ theme }) => ({
    // display: 'flex',
    backgroundColor: theme.palette.background.paper,
    // justifyContent: 'flex-start',
    paddingLeft: theme.spacing(3),
}));


export default function HeaderTabs({ tabs, selectedTabValue, title, ...other }: HeaderTabsProps) {
    return (
        <Card
            sx={{
                mb: 3,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}
        >
            <Typography
                sx={{
                    flexGrow: 2,
                    p: 3,
                    color: 'common.white',
                    backgroundColor: (theme) => theme.palette.grey[700]
                }}
                variant="h4">
                {title}
            </Typography>
            <TabsWrapperStyle>
                <Tabs
                    allowScrollButtonsMobile
                    variant="scrollable"
                    scrollButtons="auto"
                    value={selectedTabValue}
                >
                    {tabs?.map((tab) => (
                        <Tab
                            disableRipple
                            key={tab.key ?? tab.label}
                            value={tab.value ?? tab.label}
                            icon={tab.icon}
                            label={tab.label}
                        />
                    ))}
                </Tabs>
            </TabsWrapperStyle>

        </Card>
    );
};
