import { ClientService, ClientServiceState } from "../../@types/clientService";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import axios from "../../utils/axios";
import { UnitOfMeasure } from "../../@types/unitOfMeasure";
import { pick, unionBy } from "lodash";


const initialState: ClientServiceState = {
    isLoading: false,
    error: null,
    clientService: undefined,
    clientServices: [],
    clientServiceModal: {
        isLoading: false,
        isOpen: false,
        hasClientService: false,
        hasAreas: false,
        hasAttributes: false,
    },
    clientServiceDeleteModal: {
        isLoading: false,
        isOpen: false,
        clientId: null,
        clientServiceId: null,
    },
    unitsOfMeasure: [],
    clientServiceDefaultValues: {
        clientService: { id: '', name: '' },
        clientServiceDescription: '',
        attributes: [],
        areas: [],
    },
};

const slice = createSlice({
    name: 'clientService',
    initialState,
    reducers: {
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        openClientServiceDeleteModal(state, action) {
            state.clientServiceDeleteModal.isOpen = true;
            state.clientServiceDeleteModal.clientId = action.payload.clientId;
            state.clientServiceDeleteModal.clientServiceId = action.payload.clientServiceId;
        },

        closeClientServiceDeleteModal(state) {
            state.clientServiceDeleteModal = initialState.clientServiceDeleteModal;
        },

        startLoadingDelete(state) {
            state.clientServiceDeleteModal.isLoading = true;
        },

        getClientServiceDeleteSuccess(state, action) {
            state.clientServices = state.clientServices.filter((row) => row.id !== action.payload);
            state.clientServiceDeleteModal = initialState.clientServiceDeleteModal;
        },

        startLoading(state) {
            state.isLoading = true;
        },

        startModalLoading(state) {
            state.clientServiceModal.isLoading = true;
        },

        getClientServicesSuccess(state, action) {
            state.isLoading = false;
            state.clientServices = action.payload;
        },

        editClientServiceSuccess(state, action) {
            state.clientServiceModal.isLoading = false;
            state.clientServiceModal.isOpen = false;
            state.clientServices = unionBy([ action.payload as ClientService ], state.clientServices, 'id');
        },

        openEditClientServiceSuccess(state, action) {
            state.clientServiceModal.isOpen = true;
            state.clientServiceModal.hasClientService = action.payload.modal.editClientService;
            state.clientServiceModal.hasAreas = action.payload.modal.editAreas;
            state.clientServiceModal.hasAttributes = action.payload.modal.editAttributes;
            state.clientServiceModal.isLoading = false;
            state.unitsOfMeasure = action.payload.unitsOfMeasure;

            state.clientService = initialState.clientService;
            state.clientServiceDefaultValues = initialState.clientServiceDefaultValues;

            if (action.payload.clientService) {

                state.clientService = { ...action.payload.clientService, isDirty: false };
                state.clientServiceDefaultValues = {
                    clientService: pick(action.payload.clientService.service, [ 'id', 'name' ]),
                    clientServiceDescription: action.payload.clientService.description ?? '',
                    // ze względu na inną strukturę z api, atrybuty należy przemapować
                    attributes: action.payload.clientService.service_attributes.map((v: any, k: any) => (
                        {
                            attribute: {
                                id: v.id,
                                name: v.name
                            },
                            quantity: v.pivot?.value,
                            unitOfMeasure: v.unit_of_measure.id

                        }
                    )),
                    areas: action.payload.clientService.service_areas,
                };
            }
        },

        closeEditClientServiceModal(state) {
            state.clientServiceModal.isOpen = false;
            state.clientServiceModal.isLoading = false;
        },

        updateClientService(state, action) {
            state.clientService = { ...action.payload, isDirty: true };
        },

        updatePartialClientService(state, action) {
            state.clientService = { ...state.clientService, ...action.payload, isDirty: true };
        }
    }
});

export default slice.reducer;

export const {
    closeEditClientServiceModal,
    closeClientServiceDeleteModal
} = slice.actions;

export function updatePartialClientService(clientServiceData: Partial<ClientService>) {
    return async () => {
        dispatch(slice.actions.updatePartialClientService(clientServiceData));
    }
}

export function openClientServiceDeleteModal(clientId: any, clientServiceId: any) {
    return async () => {
        dispatch(slice.actions.openClientServiceDeleteModal({ clientId, clientServiceId }));
    };
}

export function deleteClientService(clientId: any, clientServiceId: any) {
    return async () => {
        dispatch(slice.actions.startLoadingDelete());
        try {
            await axios.delete(`/api/v1/clients/${clientId}/services/${clientServiceId}`);
            dispatch(slice.actions.getClientServiceDeleteSuccess(clientServiceId));
        } catch (error) {
            //
        }
    };
}

export function getClientServices(clientId: any) {
    return async () => {
        dispatch(slice.actions.startLoading());

        try {
            const response: { data: { data: ClientService[] } } = await axios.get(
                `/api/v1/clients/${clientId}/services?with=service,service_attributes_count,service_areas_count`
            );

            dispatch(slice.actions.getClientServicesSuccess(response.data.data));


        } catch (e) {
            ///
        }
    };
}

export function openClientServiceEditModal(clientId: any, id: string | null, editClientService: boolean = true, editAreas: boolean = false, editAttributes: boolean = false) {
    return async () => {
        dispatch(slice.actions.startModalLoading());

        try {
            let clientService = null;
            if (id) {
                const response: { data: { data: ClientService[] } } = await axios.get(
                    `/api/v1/clients/${clientId}/services/${id}?with=service,service_attributes_count,service_areas_count,service_attributes,attribute_unit_of_measure,service_areas`
                );
                clientService = response.data.data[0];
            }
            const unitsOfMeasureResponse: { data: { data: UnitOfMeasure[] } } = await axios.get(
                `/api/v1/units-of-measure`
            );

            dispatch(slice.actions.openEditClientServiceSuccess({
                clientService: clientService,
                unitsOfMeasure: unitsOfMeasureResponse.data.data,
                modal: {
                    ...{ editClientService },
                    ...{ editAreas },
                    ...{ editAttributes }
                }
            }));


        } catch (e) {
            ///
        }
    };
}

export function addClientService(clientId: string, formData: {}) {
    dispatch(slice.actions.startModalLoading());
    return async () => {
        try {
            const response = await axios.post(`/api/v1/clients/${clientId}/services?with=service,service_attributes_count,service_areas_count`, formData);
            dispatch(slice.actions.editClientServiceSuccess(response.data.data[0]));
        } catch (error) {
            // tbd
        }
    };
}

export function editClientService(clientId: string, clientServiceId: string, formData: {}) {
    dispatch(slice.actions.startModalLoading());
    return async () => {
        try {
            const response = await axios.put(`/api/v1/clients/${clientId}/services/${clientServiceId}?with=service,service_attributes_count,service_areas_count`, formData);
            dispatch(slice.actions.editClientServiceSuccess(response.data.data[0]));
        } catch (error) {
            // tbd
        }
    };
}