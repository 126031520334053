import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import { OptionsObject } from "notistack";

// ----------------------------------------------------------------------

interface SnackbarProps {
    message: string,
    options?: OptionsObject|{}
}

const initialState: SnackbarProps = {
    message: '',
    options: {
        variant: 'info'
    },
};

const slice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        showSnackbar(state, action) {
            state.message = action.payload.message;
            state.options = action.payload.options;
        },

        clearSnackbar(state) {
            state.message = initialState.message;
            state.options = initialState.options;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    clearSnackbar
} = slice.actions;

// ----------------------------------------------------------------------

export function showSnackbar(snackbar: SnackbarProps) {
    return () => {
        dispatch(slice.actions.showSnackbar(snackbar));
    }
}
