import { useEffect } from "react";
import { useSnackbar } from "notistack";
// hooks
import useTabs from "../../../../hooks/useTabs";
// @mui
import { Box, Dialog, DialogTitle, DialogContent, Tab, Tabs } from '@mui/material';
// components
import Iconify from "../../../../components/Iconify";
//redux
import { dispatch, useSelector } from "../../../../redux/store";
import { closeEditUserModal, hideFailSnackbar, hideSuccessSnackbar } from "../../../../redux/slices/user";
// sections
import TabGeneral from "./tabs/TabGeneral";
import TabChangePassword from "./tabs/TabChangePassword";
//hooks
import useAuth from "../../../../hooks/useAuth";

// ----------------------------------------------------------------------

export default function UserEditDialogForm() {

    const { enqueueSnackbar } = useSnackbar();

    const authUser = useAuth().user;

    const {
        isOpenModal,
        showSuccessSnackbar,
        showFailSnackbar,
        userId
    } = useSelector((state) => state.user);

    useEffect(() => {
        if (showSuccessSnackbar) {
            enqueueSnackbar('Sukces!');
            dispatch(hideSuccessSnackbar())
        }
    }, [enqueueSnackbar, showSuccessSnackbar]);

    useEffect(() => {
        if (showFailSnackbar) {
            enqueueSnackbar('Błąd!', { variant: 'error' });
            dispatch(hideFailSnackbar())
        }
    }, [enqueueSnackbar, showFailSnackbar]);

    const TABS = [
        {
            value: 'general',
            label: 'informacje ogólne',
            icon: <Iconify icon={ 'ic:round-account-box' } width={ 20 } height={ 20 }/>,
            component: <TabGeneral/>,
        },

        ...(userId === authUser?.id ? [{
            value: 'change_password',
            label: 'Zmiana hasła',
            icon: <Iconify icon={ 'ic:round-vpn-key' } width={ 20 } height={ 20 }/>,
            component: <TabChangePassword/>,
        }] : []),
    ];

    const { currentTab, onChangeTab } = useTabs('general');

    return (
        <Dialog fullWidth maxWidth="md" open={ isOpenModal } onClose={ () => dispatch(closeEditUserModal()) }>
            <DialogTitle>Edycja użytkownika</DialogTitle>

            <DialogContent >
                <Tabs value={ currentTab } onChange={ onChangeTab } sx={ { mt: 2 } }>

                    { TABS.map((tab) => (
                        <Tab
                            disableRipple
                            key={ tab.value }
                            label={ (tab.label) }
                            icon={ tab.icon }
                            value={ tab.value }
                        />
                    )) }
                </Tabs>
            </DialogContent>

            { TABS.map((tab) => {
                const isMatched = tab.value === currentTab;
                return isMatched && <Box key={ tab.value }>{ tab.component }</Box>;
            }) }

        </Dialog>
    );
}
