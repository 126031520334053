import axios from "../../utils/axios";

export type TabBasicInfoStateResponse = {
    isReadonly: boolean;
    tabAlertMessage: string;
    fields: { name: string, isDisabled: boolean }[]
    sections: { name: string, canEdit: boolean, canDelete: boolean, canAdd: boolean }[]
}

export const getTabBasicInfoState = (rfpId: string) => axios
    .get(`/api/v1/frontend/tenders/facility-manager/${ rfpId }/tab-basic-info-state`)
    .then((response: { data: { data: TabBasicInfoStateResponse } }) => response.data.data)
