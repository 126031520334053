export const auth = {
    pages: {
        verify_email: {
            page_title: 'Potwierdź email',
            header: 'Dziękujemy za zarejestrowanie się!',
            error_header: 'Bład podczas potwierdzania adresu e-mail',
            verify_message: 'Na podany przy rejestracji adres emailowy wysłany został mail aktywacyjny. Po jego otrzymaniu należy kliknąć w link “zweryfikuj adres” po kliknięciu którego otwarta zostanie strona w aplikacji Connect Facillities.',
            send_again_button: 'Wyślij ponownie email weryfikacyjny',

        }
    }
};