import { useMemo, ReactNode } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
    createTheme,
    ThemeOptions,
    ThemeProvider as MUIThemeProvider,
    StyledEngineProvider, alpha,
} from '@mui/material/styles';
// hooks
import useSettings from '../hooks/useSettings';
//
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import useAuth, { SUPPLIER_ADMIN, SUPPLIER_USER } from "../hooks/useAuth";
import getColorPresets from "../utils/getColorPresets";

// ----------------------------------------------------------------------

type Props = {
    children: ReactNode;
};

export default function ThemeProvider({ children }: Props) {
    const { themeMode, themeDirection } = useSettings();

    const isLight = themeMode === 'light';

    const { user } = useAuth();

    const themeOptions: ThemeOptions = useMemo(
        () => {
            const paletteColor = (user?.role === SUPPLIER_ADMIN || user?.role === SUPPLIER_USER) ? 'blue' : 'default';

            return ({
                palette: { ...isLight ? palette.light : palette.dark, primary: getColorPresets(paletteColor) },
                typography,
                breakpoints,
                shape: { borderRadius: 8 },
                direction: themeDirection,
                shadows: isLight ? shadows.light : shadows.dark,
                customShadows: {
                    ...isLight ? customShadows.light : customShadows.dark,
                    primary: `0 8px 16px 0 ${ alpha(getColorPresets(paletteColor).main, 0.24) }`,
                },
            });
        },
        [isLight, themeDirection, user]
    );

    const theme = createTheme(themeOptions);

    theme.components = componentsOverride(theme);

    return (
        <StyledEngineProvider injectFirst>
            <MUIThemeProvider theme={ theme }>
                <CssBaseline/>
                { children }
            </MUIThemeProvider>
        </StyledEngineProvider>
    );
}
