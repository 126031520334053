// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

export const common = {
    docs: {
        hi: `Hi`,
        description: `Need help? \n Please check our docs.`,
        documentation: `documentation`,
    },
    app: `app`,
    user: `user`,
    list: `list`,
    edit: `edit`,
    shop: `shop`,
    blog: `blog`,
    post: `post`,
    mail: `mail`,
    chat: `chat`,
    cards: `cards`,
    posts: `posts`,
    create: `create`,
    kanban: `kanban`,
    general: `general`,
    banking: `banking`,
    booking: `booking`,
    profile: `profile`,
    account: `account`,
    product: `product`,
    invoice: `invoice`,
    details: `details`,
    checkout: `checkout`,
    calendar: `calendar`,
    analytics: `analytics`,
    ecommerce: `e-commerce`,
    management: `management`,
    menu_level_1: `menu level 1`,
    menu_level_2: `menu level 2`,
    menu_level_3: `menu level 3`,
    item_disabled: `item disabled`,
    item_label: `item label`,
    item_caption: `item caption`,
    description: `description`,
    other_cases: `other cases`,
    item_by_roles: `item by roles`,
    only_admin_can_see_this_item: `Only admin can see this item`,
};