import { ElementType, lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
import RoleBasedGuard from "../guards/RoleBasedGuard";
//pages
import Login from "../pages/auth/Login";
// hooks
import { FACILITY_MANAGER_ADMIN, SUPPLIER_ADMIN, SUPPLIER_USER, } from '../hooks/useAuth';
import ClientServices from "../pages/client/ClientServices";

// ----------------------------------------------------------------------

export const Loadable = (Component: ElementType) => (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={ <LoadingScreen isDashboard={ pathname.includes('/dashboard') }/> }>
            <Component { ...props } />
        </Suspense>
    );
};

export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: <Navigate to="/auth/login" replace/>,
        },
        {
            path: 'auth',
            children: [
                {
                    path: 'login',
                    element: (
                        <GuestGuard>
                            <Login/>
                        </GuestGuard>
                    ),
                },
                {
                    path: 'register',
                    element: (
                        <GuestGuard>
                            <Register/>
                        </GuestGuard>
                    ),
                },
                { path: 'reset-password', element: <ResetPassword/> },
                { path: 'new-password', element: <NewPassword/> },
                { path: 'verify-email', element: <VerifyEmail/> },
            ],
        },
        {
            path: 'dashboard',
            element: (
                <AuthGuard>
                    <DashboardLayout/>
                </AuthGuard>
            ),
            children: [
                { element: <Navigate to="/dashboard/dashboard" replace/>, index: true },
                { path: 'dashboard', element: <Dashboard/> },
                // { path: 'tenders', element: <TenderList/> },
                { path: 'facilities', element: <Maintenance/> },
                { path: 'services', element: <ClientServices /> },
                { path: 'tenders', element: <Maintenance/> },
                {
                    path: 'facility',
                    children: [
                        { element: <Navigate to="/dashboard/facility" replace/>, index: true },
                        { path: 'list', element: <FacilityList/> },
                        { path: ':id/details', element: <FacilityDetails/> }
                    ],
                },
                { path: 'services', element: <Maintenance/> },

                {
                    path: 'tender',
                    children: [
                        { element: <Navigate to="/dashboard/tender/list" replace/>, index: true },
                        { path: 'list', element: <TenderList/> },
                        { path: ':rfpId/details/:activeTab', element: <TenderDetails/> },
                        { path: ':id/client-rating-list', element: <TenderClientRatingList/> },
                    ],
                },

                {
                    path: 'user',
                    children: [
                        { element: <Navigate to="/dashboard/user" replace/>, index: true },
                        {
                            path: 'list',
                            element:
                                <RoleBasedGuard roles={ [FACILITY_MANAGER_ADMIN, SUPPLIER_ADMIN] }>
                                    <UserList/>
                                </RoleBasedGuard>
                        },
                    ],
                },

                {
                    path: 'supplier-tender',
                    children: [
                        { element: <Navigate to="/supplier-tender" replace/>, index: true },
                        {
                            path: 'list',
                            element:
                                <RoleBasedGuard roles={ [SUPPLIER_ADMIN, SUPPLIER_USER] }>
                                    <SupplierTenderList/>
                                </RoleBasedGuard>
                        },
                        { path: ':rfpId/details/:activeTab', element: <SupplierTenderDetails/> },
                        { path: ':rfpId/offers/:rfpOfferId/:step', element: <SupplierTenderOfferDetails/> },
                    ],
                },

                {
                    path: 'client',
                    children: [
                        { element: <Navigate to="/dashboard/client" replace/>, index: true },
                        {
                            path: 'information',
                            element:
                                <RoleBasedGuard roles={ [SUPPLIER_ADMIN, FACILITY_MANAGER_ADMIN] }>
                                    <ClientInformation/>
                                </RoleBasedGuard>
                        },
                    ],
                },

            ],
        },
        {
            path: '*',
            element: <LogoOnlyLayout/>,
            children: [
                { path: '404', element: <NotFound/> },
                { path: '*', element: <Navigate to="/404" replace/> },
            ],
        },
        { path: '*', element: <Navigate to="/404" replace/> },
    ]);
}

// auth
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyEmail = Loadable(lazy(() => import('../pages/auth/VerifyEmail')));

// dashboard
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));

// user
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));

// tender
const TenderList = Loadable(lazy(() => import('../pages/dashboard/TenderList')));
const TenderDetails = Loadable(lazy(() => import('../pages/dashboard/TenderDetails')));
const TenderClientRatingList = Loadable(lazy(() => import('../pages/dashboard/TenderClientRatingList')));

// client
const ClientInformation = Loadable(lazy(() => import('../pages/client/information/ClientInformationPage')));

// supplier-tender
const SupplierTenderList = Loadable(lazy(() => import('../pages/dashboard/SupplierTenderList')));
const SupplierTenderDetails = Loadable(lazy(()=> import('../pages/dashboard/SupplierTenderDetails')));
const SupplierTenderOfferDetails = Loadable(lazy(()=> import('../pages/dashboard/SupplierTenderOfferDetails')));

// other
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));

// facility
const FacilityList = Loadable(lazy(() => import('../pages/facility/FacilityListPage')));
const FacilityDetails = Loadable(lazy(() => import('../pages/facility/FacilityDetailsPage')));
