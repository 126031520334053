import axios from "../../utils/axios";

export type TabInviteStateResponse = {
    isReadonly: boolean;
    readonlyReason: string;
}

export const getTabInviteState = (rfpId: string) => axios
    .get(`/api/v1/frontend/tenders/facility-manager/${ rfpId }/tab-invite-state`)
    .then((response: { data: { data: TabInviteStateResponse } }) => response.data.data)
