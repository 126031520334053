// @mui
import { styled } from '@mui/material/styles';
import { AppBar, Box, Button, Stack, Toolbar, Typography } from '@mui/material';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, HOST_API, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
import AccountPopover from './AccountPopover';
import useAuth from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

type RootStyleProps = {
    isCollapse: boolean;
    isOffset: boolean;
    verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
    shouldForwardProp: (prop) =>
        prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
    ...cssStyles(theme).bgBlur(),
    boxShadow: 'none',
    height: HEADER.MOBILE_HEIGHT,
    zIndex: theme.zIndex.appBar + 1,
    transition: theme.transitions.create(['width', 'height'], {
        duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('lg')]: {
        height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
        width: `calc(100% - ${ NAVBAR.DASHBOARD_WIDTH + 1 }px)`,
        ...(isCollapse && {
            width: `calc(100% - ${ NAVBAR.DASHBOARD_COLLAPSE_WIDTH }px)`,
        }),
        ...(isOffset && {
            height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
        }),
        ...(verticalLayout && {
            width: '100%',
            height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
            backgroundColor: theme.palette.background.default,
        }),
    },
}));

// ----------------------------------------------------------------------

type Props = {
    onOpenSidebar: VoidFunction;
    isCollapse?: boolean;
    verticalLayout?: boolean;
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false, }: Props) {
    const { t } = useTranslation();

    const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

    const isDesktop = useResponsive('up', 'lg');

    const { user: authUser } = useAuth();

    return (
        <RootStyle isCollapse={ isCollapse } isOffset={ isOffset } verticalLayout={ verticalLayout }>
            <Toolbar
                sx={ {
                    minHeight: '100% !important',
                    px: { lg: 5 },
                } }
            >
                { isDesktop && verticalLayout && <Logo sx={ { mr: 2.5 } }/> }

                { !isDesktop && (
                    <IconButtonAnimate onClick={ onOpenSidebar } sx={ { mr: 1, color: 'text.primary' } }>
                        <Iconify icon="eva:menu-2-fill"/>
                    </IconButtonAnimate>
                ) }

                <Box sx={ { flexGrow: 1 } }/>

                <Stack direction="row" alignItems="center" spacing={ { xs: 0.5, sm: 1.5 } }>
                    { authUser?.is_super_admin &&
                        <>
                            { isDesktop &&
                                <Typography
                                    variant={ 'subtitle1' }
                                    color={ 'error' }
                                >
                                    { t('header.logged_as', {
                                        clientName: authUser?.client?.latest_client_information?.registry_name
                                    }) }
                                </Typography>
                            }
                            <Button
                                sx={ { color: 'text.secondary' } }
                                variant={ "outlined" }
                                color={ "inherit" }
                                href={ `${ HOST_API }/admin` }
                            >
                                { t('header.back_to_backoffice_button') }
                            </Button>
                        </>
                    }
                    <AccountPopover/>
                </Stack>
            </Toolbar>
        </RootStyle>
    );
}
