import Page from "../../components/Page";
import {
    Box,
    Button,
    Card,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Stack,
    Switch,
    Table,
    TableBody,
    TableContainer,
    TablePagination,
    TextField,
} from "@mui/material";
import Iconify from "../../components/Iconify";
import HeaderTabs, { HeaderTab } from "../../components/HeaderTabs";
import React, { useEffect, useState } from "react";
import { ClientServiceTableToolbar } from "../../sections/@dashboard/client-service/list";
import Scrollbar from "../../components/Scrollbar";
import {
    TableEmptyRows,
    TableHeadCustom,
    TableNoData,
    TableSelectedActions,
    TableSkeleton
} from "../../components/table";
import useTable, { emptyRows, getComparator } from "../../hooks/useTable";
import useAuth from "../../hooks/useAuth";
import { dispatch, useSelector } from "../../redux/store";
import { getClientServices, openClientServiceEditModal } from "../../redux/slices/clientService";
import { ClientService } from "../../@types/clientService";
import ClientServiceTableRow from "../../sections/@dashboard/client-service/list/ClientServiceTableRow";
import { forEach, uniqBy } from "lodash";
import { CategoryOption } from "../../sections/@dashboard/client-service/list/ClientServiceTableToolbar";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import ClientServiceEditDialog from "../../sections/@dashboard/client-service/ClientServiceEditDialog";
import ClientServiceDeleteDialog from "../../sections/@dashboard/client-service/ClientServiceDeleteDialog";
import InstructionBox from "../../components/InstructionBox";
import { PATH_DASHBOARD } from "../../routes/paths";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'service.name', label: 'Nazwa usługi', align: 'left' },
    { id: 'description', label: 'Opis', align: 'left' },
    { id: 'service.service_category.name', label: 'Kategoria', align: 'left' },
    { id: '', label: 'Akcje' },
];

// ----------------------------------------------------------------------

export default function ClientServices() {
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        setPage,
        selected,
        setSelected,
        onSelectRow,
        onSelectAllRows,
        onSort,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable();

    const { t } = useTranslation('client_service', { keyPrefix: 'list' });

    const [ requestMissingOpen, setRequestMissingOpen ] = useState(false);

    // --- Reducer
    const {
        clientServices,
        isLoading
    } = useSelector((state) => state.clientService)

    // --- state tabeli
    const [ tableData, setTableData ] = useState<ClientService[]>([]);

    const table_filter_all_label = t('common:table.controls.all');

    // --- lista kategorii
    const [ categoryOptions, setCategoryOptions ] = useState<CategoryOption[]>([ {
        label: table_filter_all_label,
        value: 'all'
    } ]);

    // -- Wywoływanie pobrania listy usług
    const authUser = useAuth().user;
    useEffect(() => {
        dispatch(getClientServices(authUser?.client_id));
    }, [ authUser?.client_id ]);

    useEffect(() => {
        if (clientServices.length) {
            // The 'parseFilterCategory' function makes the dependencies of useEffect Hook change on every render. Move it inside the useEffect callback.
            const parseFilterCategory = (clientServices: ClientService[]) => {
                let categories: CategoryOption[] = [];
                categories.push({
                    label: table_filter_all_label,
                    value: 'all'
                });
                forEach(clientServices, function (value) {
                    categories.push({
                        label: value.service.service_category.name,
                        value: value.service.service_category.id
                    });
                });
                return uniqBy(categories, 'value');
            };
            setCategoryOptions(parseFilterCategory(clientServices));
        }
        if(clientServices){
            setTableData(clientServices);
        }
    }, [ table_filter_all_label, clientServices ]);


    // --- Wyszukiwanie
    const [ filterSearch, setFilterSearch ] = useState('');
    const handleSearchValueChange = (name: string) => {
        setFilterSearch(name);
        setPage(0);
    };

    // --- Kategoria
    const [ filterCategory, setFilterCategory ] = useState('all');
    const handleCategoryValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterCategory(event.target.value);
        setPage(0);
    };

    const dataFiltered = applySortFilter({
        tableData,
        comparator: getComparator(order, orderBy),
        filterSearch,
        filterCategory,
    });

    const denseHeight = dense ? 52 : 72;

    const isNotFound =
        (!dataFiltered.length && !!filterSearch) ||
        (!dataFiltered.length && !!filterCategory) ||
        (!isLoading && !dataFiltered.length);


    const TABS: HeaderTab[] = [
        {
            label: t('tabs.my_services.label'),
            icon: <Iconify icon={'eva:menu-2-fill'}/>
        }
    ];

    return (
        <Page title={ t('title') }>
            <Container maxWidth={ 'xl' }>

                <Stack direction={ "row" } justifyContent={ "space-between" } alignItems={ "flex-end" }>
                    <HeaderBreadcrumbs
                        sx={ { mb: 0 } }
                        heading={ t('title') }
                        links={ [
                            { name: t('common:path.dashboard'), href: PATH_DASHBOARD.root },
                            { name: t('title') },
                        ] }
                    />
                    <Button
                        sx={ { mb: 2 } }
                        startIcon={ <Iconify icon={ 'ic:round-forum' }/> }
                        variant="outlined"
                        size="small"
                        color="info"
                        onClick={ () => setRequestMissingOpen(true) }
                    >
                        { t('button_request_missing.label') }
                    </Button>
                </Stack>

                <HeaderTabs selectedTabValue={ t('tabs.my_services.label') } tabs={ TABS } title={ t('tabs.title') }/>

                <InstructionBox sx={ { mb: 3 } }>
                    { t('details') }
                </InstructionBox>

                <Card>
                    <ClientServiceTableToolbar
                        searchValue={ filterSearch }
                        onSearchChange={ handleSearchValueChange }
                        categoryValue={ filterCategory }
                        onCategoryChange={ handleCategoryValue }
                        categoryOptions={ categoryOptions }
                    />

                    <Scrollbar>

                        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                            {selected.length > 0 && (
                                <TableSelectedActions
                                    dense={dense}
                                    numSelected={selected.length}
                                    rowCount={tableData.length}
                                    onSelectAllRows={(checked) =>
                                        onSelectAllRows(
                                            checked,
                                            tableData.map((row) => row.id)
                                        )
                                    }
                                />
                            )}

                            <Table size={dense ? 'small' : 'medium'}>
                                <TableHeadCustom
                                    order={order}
                                    orderBy={orderBy}
                                    onSort={onSort}
                                    headLabel={TABLE_HEAD}
                                    onSelectAllRows={(checked) =>
                                        onSelectAllRows(
                                            checked,
                                            tableData.map((row) => row.id)
                                        )
                                    }
                                />

                                <TableBody>

                                    {(isLoading ? [ ...Array(rowsPerPage) ] : dataFiltered)
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) =>
                                            row ? (
                                                <ClientServiceTableRow key={row.id} row={row}
                                                                       selected={selected.includes(row.id)}
                                                                       onSelectRow={() => onSelectRow(row.id)}/>

                                            ) : (
                                                !isNotFound &&
                                                <TableSkeleton key={index} sx={{ height: denseHeight }}/>
                                            )
                                        )}

                                    <TableEmptyRows height={denseHeight}
                                                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}/>

                                    <TableNoData isNotFound={isNotFound}/>

                                </TableBody>


                            </Table>

                        </TableContainer>

                    </Scrollbar>

                    <Box sx={{ position: 'relative' }}>
                        <TablePagination
                            component="div"
                            count={dataFiltered.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={onChangePage}
                            onRowsPerPageChange={onChangeRowsPerPage}
                        />

                        <FormControlLabel
                            control={<Switch checked={dense} onChange={onChangeDense}/>}
                            label={t('common:table.controls.dense')}
                            sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                        />
                    </Box>


                </Card>
                <Button
                    sx={{ mt: 3, px: 3, py: 1.5, float: 'right' }}
                    variant="contained"
                    startIcon={<Iconify icon={'eva:plus-fill'}/>}
                    onClick={() => dispatch(openClientServiceEditModal(authUser?.client_id, null, true, true, true))}
                >
                    {t('button_add.label')}
                </Button>

                <ClientServiceEditDialog/>
                <ClientServiceDeleteDialog onDeleteConfirm={() => setSelected([])}/>

                <Dialog fullWidth maxWidth="md" open={requestMissingOpen}>
                    <DialogTitle>
                        {t('request_missing_modal.title')}
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <TextField
                            multiline
                            maxRows={6}
                            rows={3}
                            label={t('request_missing_modal.message.label')}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            loading={false}
                            variant="outlined"
                            color="error"
                            onClick={() => setRequestMissingOpen(false)}
                        >
                            {t('common:button.cancel')}
                        </LoadingButton>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            loading={false}
                            onClick={() => setRequestMissingOpen(false)}
                        >
                            {t('request_missing_modal.button_send')}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>


            </Container>
        </Page>
    )
};


// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterSearch, filterCategory }: {
    tableData: ClientService[];
    comparator: (a: any, b: any) => number;
    filterSearch: string;
    filterCategory: string;
}) {
    const stabilizedThis = tableData.map((el, index) => [ el, index ] as const);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    tableData = stabilizedThis.map((el) => el[0]);

    if (filterSearch) {
        tableData = tableData.filter(
            (item: Record<string, any>) =>
                item.service.name.toLowerCase().indexOf(filterSearch.toLowerCase()) !== -1 ||
                (item.description && item.description.toLowerCase().indexOf(filterSearch.toLowerCase()) !== -1) ||
                (item.service.service_category.name && item.service.service_category.name.toLowerCase().indexOf(filterSearch.toLowerCase()) !== -1)
        );
    }

    if (filterCategory !== 'all') {
        tableData = tableData.filter((item: Record<string, any>) => item.service.service_category.id === filterCategory);
    }

    return tableData;
}
