// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
// hooks
import useAuth from '../../../hooks/useAuth';
// components
import MyAvatar from '../../../components/MyAvatar';
//redux
import { dispatch } from "../../../redux/store";
import { openUserEditModal } from "../../../redux/slices/user";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.grey[500_12],
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
    }),
}));

// ----------------------------------------------------------------------

type Props = {
    isCollapse: boolean | undefined;
};

export default function NavbarAccount({ isCollapse }: Props) {
    const { user } = useAuth();

    const fullName = user?.name + ' ' + user?.surname;

    const userRole = user?.role;

    return (
        <Link sx={ { cursor: 'pointer' } } underline="none" color="inherit"
              onClick={ () => dispatch(openUserEditModal(user?.id)) }
        >
            <RootStyle
                sx={ {
                    ...(isCollapse && {
                        bgcolor: 'transparent',
                    }),
                } }
            >
                <MyAvatar/>

                <Box
                    sx={ {
                        ml: 2,
                        transition: (theme) =>
                            theme.transitions.create('width', {
                                duration: theme.transitions.duration.shorter,
                            }),
                        ...(isCollapse && {
                            ml: 0,
                            width: 0,
                        }),
                    } }
                >
                    <Typography variant="subtitle2" fontSize={ '13px' } noWrap>
                        { fullName.length > 20 ? fullName.substring(0, 20) + '...' : fullName }
                    </Typography>
                    <Typography variant="body2" noWrap fontSize={ '12px' } sx={ { color: 'text.secondary' } }>
                        { userRole.length > 20 ? userRole.substring(0, 20) + '...' : userRole }
                    </Typography>
                </Box>
            </RootStyle>
        </Link>
    );
}
