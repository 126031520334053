import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
// @types
import { ActionMap, AuthState, AuthUser, SanctumContextType } from '../@types/auth';

// ----------------------------------------------------------------------

enum Types {
    Initial = 'INITIALIZE',
    Login = 'LOGIN',
    Logout = 'LOGOUT',
    Register = 'REGISTER',
}

type SanctumAuthPayload = {
    [Types.Initial]: { isAuthenticated: boolean; isVerified: boolean, user: AuthUser; };
    [Types.Login]: { user: AuthUser, isVerified: boolean };
    [Types.Logout]: undefined;
    [Types.Register]: undefined;
};

export type SanctumActions = ActionMap<SanctumAuthPayload>[keyof ActionMap<SanctumAuthPayload>];

const initialState: AuthState = {
    isAuthenticated: false,
    isInitialized: false,
    isVerified: false,
    user: null,
};

const SanctumReducer = (state: AuthState, action: SanctumActions) => {
    switch (action.type) {
        case 'INITIALIZE':
            return {
                isAuthenticated: action.payload.isAuthenticated,
                isVerified: action.payload.isVerified,
                isInitialized: true,
                user: action.payload.user,
            };
        case 'LOGIN':
            return {
                ...state,
                isAuthenticated: true,
                isVerified: action.payload.isVerified,
                user: action.payload.user,
            };
        case 'LOGOUT':
            return {
                ...state,
                isAuthenticated: false,
                isVerified: false,
                user: null,
            };
        case 'REGISTER':
            return {
                ...state,
                isAuthenticated: true,
                isVerified: false,
                user: null,
            };
        default:
            return state;
    }
};

const AuthContext = createContext<SanctumContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = { children: ReactNode; };

function AuthProvider({ children }: AuthProviderProps) {
    const [state, dispatch] = useReducer(SanctumReducer, initialState);

    useEffect(() => {
        const initialize = async () => {
            try {
                axios.get('/api/user').then(response => {
                    const user = response.data.data;

                    // @ts-ignore
                    if(typeof smCreateCookie === 'function') {
                        // @ts-ignore
                        smCreateCookie('smclient', user.sm_client_id);
                    }

                    dispatch({
                        type: Types.Initial,
                        payload: {
                            isVerified: !!user.email_verified_at,
                            isAuthenticated: true,
                            user,
                        },
                    });
                }).catch(error => {
                    dispatch({
                        type: Types.Initial,
                        payload: {
                            isVerified: false,
                            isAuthenticated: false,
                            user: null,
                        },
                    });

                });
            } catch (err) {
                console.error(err);
                dispatch({
                    type: Types.Initial,
                    payload: {
                        isVerified: false,
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        };

        initialize();
    }, []);

    const login = async (email: string, password: string) => {
        await axios.get('/sanctum/csrf-cookie');
        const response = await axios.post('/api/login', {
            email,
            password,
        });

        const user = response.data.data

        // @ts-ignore
        if(typeof smCreateCookie === 'function') {
            // @ts-ignore
            smCreateCookie('smclient', user.sm_client_id);
        }

        dispatch({
            type: Types.Login,
            payload: {
                isVerified: !!user.email_verified_at,
                user,
            },
        });
    };

    const register = async (client_type_id: number, email: string, password: string, password_confirmation: string, name: string, surname: string) => {
        await axios.get('/sanctum/csrf-cookie');
        await axios.post('/api/register', {
            client_type_id,
            email,
            password,
            password_confirmation,
            name,
            surname,
        });

        dispatch({
            type: Types.Register,
        });
    };

    const logout = async () => {
        await axios.post('/api/logout');
        dispatch({ type: Types.Logout });
    };

    return (
        <AuthContext.Provider value={ { ...state, method: 'sanctum', login, logout, register } }>
            { children }
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };
