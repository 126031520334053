import { ClientService } from "../../../../@types/clientService";
import { Checkbox, Stack, TableCell, TableRow } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { dispatch, useSelector } from "../../../../redux/store";
import Iconify from "../../../../components/Iconify";
import { openClientServiceDeleteModal, openClientServiceEditModal } from "../../../../redux/slices/clientService";
import useAuth from "../../../../hooks/useAuth";


type Props = {
    row: ClientService;
    selected: boolean;
    onSelectRow: VoidFunction;
};
export default function ClientServiceTableRow({ row, selected, onSelectRow }: Props) {


    const authUser = useAuth().user;

    const nowrapCellSX = {
        maxWidth: '10em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    };

    // --- Reducer
    const {
        clientServiceModal
    } = useSelector((state) => state.clientService)

    return (
        <TableRow hover selected={selected}>
            <TableCell padding="checkbox">
                <Checkbox checked={selected} onClick={onSelectRow}/>
            </TableCell>

            <TableCell>
                {row.service.name}
            </TableCell>

            <TableCell sx={ nowrapCellSX }>
                { row.description }
            </TableCell>

            <TableCell>
                { row.service.service_category.name }
            </TableCell>

            <TableCell width={ 1 }>
                <Stack direction={ "row" } spacing={ 1 }>
                    <LoadingButton
                        sx={ { width: 100 } }
                        loading={ clientServiceModal.isLoading }
                        variant={ 'contained' }
                        size={ "small" }
                        color="secondary"
                        onClick={ () => dispatch(openClientServiceEditModal(authUser?.client_id, row.id, false, false, true)) }
                    >
                        Atrybuty ({ row.service_attributes_count ?? 0 })
                    </LoadingButton>
                    <LoadingButton
                        sx={ { width: 100 } }
                        loading={ clientServiceModal.isLoading }
                        variant={ 'contained' }
                        size={ "small" }
                        color="secondary"
                        onClick={ () => dispatch(openClientServiceEditModal(authUser?.client_id, row.id, false, true)) }
                    >
                        Regiony ({ row.service_areas_count ?? 0 })
                    </LoadingButton>

                    <LoadingButton
                        sx={ {} }
                        loading={ clientServiceModal.isLoading }
                        variant={ 'outlined' }
                        size={ "small" }
                        color="warning"
                        onClick={ () => dispatch(openClientServiceEditModal(authUser?.client_id, row.id, true, true, true)) }
                    >
                        <Iconify icon={ 'eva:edit-2-outline' }/>
                    </LoadingButton>

                    <LoadingButton
                        loading={ clientServiceModal.isLoading }
                        variant={ 'outlined' }
                        size={ "small" }
                        color="error"
                        onClick={ () => dispatch(openClientServiceDeleteModal(authUser?.client_id, row.id)) }
                    >
                        <Iconify icon={ 'eva:trash-2-outline' }/>
                    </LoadingButton>
                </Stack>
            </TableCell>

        </TableRow>
    );
};