
export const areas = {
    areas_modal: {
        validator: {
            area_1_level: {
                required: 'Pole kraj jest wymagane!'
            }
        },
        title: 'Dodaj rejon',
        area_1_level: {
            label: 'Kraj',
        },
        area_2_level: {
            label: 'Województwo',
        },
        area_3_level: {
            label: 'Powiat',
        },
        area_4_level: {
            label: 'Gmina',
        },
    },
};