import axios from "../../utils/axios";

export type TabSearchStateResponse = {
    isReadonly: boolean;
    readonlyReason: string;
}

export const getTabSearchState = (rfpId: string) => axios
    .get(`/api/v1/frontend/tenders/facility-manager/${ rfpId }/tab-search-state`)
    .then((response: { data: { data: TabSearchStateResponse } }) => response.data.data)
