import { dispatch, useSelector } from "../../../redux/store";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
    Box,
    Button,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import React, { useEffect } from "react";
import Iconify from "../../../components/Iconify";
import { useTranslation } from "react-i18next";
import { openAreasSelectModal } from "../../../redux/slices/areasSelect";

interface ClientServiceAreasProps {
    title?: string | boolean;
}

export default function ClientServiceAreas({ title = false }: ClientServiceAreasProps) {
    const { t } = useTranslation('client_service', { keyPrefix: 'client_service_modal.areas' });

    const {
        postData
    } = useSelector((state) => state.areasSelect);

    const { control } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'areas'
    });

    useEffect(() => {
        if (postData !== null) {
            append(postData);
        }
    }, [ postData, append ]);

    const getLowestLevelName = (area: any): any => {
        if (area.area_4_level) {
            return 'area_4_level';
        }
        if (area.area_3_level) {
            return 'area_3_level';
        }
        if (area.area_2_level) {
            return 'area_2_level';
        }
        if (area.area_1_level) {
            return 'area_1_level';
        }
    };

    const getLowestLevel = (area: any): any => {
        const level = getLowestLevelName(area);
        return { ...area[level], levelName: level };
    };

    return (
        <>
            {(title &&
              <Typography variant="h6" sx={{ mb: 3 }}>
                  {title}
              </Typography>
            )}
            <Stack spacing={3}>

                {fields.map((area, index) => (

                    <Stack key={index}
                           direction="row" justifyContent="space-between">
                        <Box sx={{ minWidth: 240 }}>
                            <Typography variant="subtitle2" noWrap>
                                {getLowestLevel(area)?.name}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                                {t(`levels.${getLowestLevel(area)?.levelName}_label`)}
                            </Typography>
                        </Box>
                        <Box justifySelf="end">
                            <IconButton onClick={() => remove(index)}>
                                <Iconify icon={'eva:trash-2-outline'} color="red"/>
                            </IconButton>
                        </Box>

                    </Stack>
                ))}

                <Box sx={{ mt: 1 }}>

                    <Button variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => dispatch(openAreasSelectModal())}
                            startIcon={<Iconify icon={'ic:baseline-plus'}/>}
                    >
                        {(fields.length > 0 && t('button_add.label_first') || t('button_add.label_next'))}
                    </Button>
                </Box>

            </Stack>
        </>
    );
};