import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
//routes
import { PATH_AUTH } from '../../../routes/paths';

// ----------------------------------------------------------------------

type FormValuesProps = {
    email: string;
    password: string;
    afterSubmit?: string;
};

export default function LoginForm() {
    const { login } = useAuth();

    const isMountedRef = useIsMountedRef();

    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('E-mail musi być prawidłowym adresem e-mail').required('E-mail jest wymagany'),
        password: Yup.string().required('Hasło jest wymagane'),
    });

    const defaultValues = {
        email: '',
        password: '',
    };

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        reset,
        setError,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = methods;

    const onSubmit = async (data: FormValuesProps) => {
        try {
            await login(data.email, data.password);
        } catch (error) {
            reset();

            if (isMountedRef.current) {
                setError('afterSubmit', { ...error, message: error.message });
            }
        }
    };

    return (
        <FormProvider methods={ methods } onSubmit={ handleSubmit(onSubmit) }>
            <Stack spacing={ 3 }>
                { !!errors.afterSubmit && <Alert severity="error">{ errors.afterSubmit.message }</Alert> }

                <RHFTextField name="email" label="Email address"/>

                <RHFTextField
                    name="password"
                    label="Password"
                    type={ showPassword ? 'text' : 'password' }
                    InputProps={ {
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={ () => setShowPassword(!showPassword) } edge="end">
                                    <Iconify icon={ showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill' }/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    } }
                />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={ { my: 2 } }>
                <Link component={ RouterLink } variant="subtitle2" to={ PATH_AUTH.resetPassword }>
                    Zapomniałeś hasła?
                </Link>
            </Stack>

            <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={ isSubmitting }
            >
                Zaloguj się
            </LoadingButton>
        </FormProvider>
    );
}
