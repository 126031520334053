import { InputAdornment, MenuItem, Stack, TextField } from "@mui/material";
import Iconify from "../../../../components/Iconify";
import React from "react";


export type CategoryOption = {
    value: string;
    label?: string;
    key?: string;
};

interface ClientServiceTableToolbarProps {
    searchValue: string;
    onSearchChange: (value: string) => void;
    categoryValue: string;
    onCategoryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    categoryOptions: CategoryOption[];

}

export default function ClientServiceTableToolbar({
                                                      searchValue,
                                                      onSearchChange,
                                                      categoryValue,
                                                      categoryOptions,
                                                      onCategoryChange,
                                                      ...other
                                                  }: ClientServiceTableToolbarProps) {
    return (
        <Stack spacing={2} direction={{ xs: 'column', 'sm': 'row' }} sx={{ py: 2.5, px: 3 }}>
            <TextField
                fullWidth
                select
                label="Kategorie"
                value={categoryValue}
                onChange={onCategoryChange}
                SelectProps={{
                    MenuProps: {
                        sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                    },
                }}
                sx={{
                    maxWidth: { sm: 240 },
                    textTransform: 'capitalize',
                }}
            >
                {categoryOptions.map((option) => (
                    <MenuItem
                        key={option.key ?? option.value}
                        value={option.value}
                        sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: 'body2',
                            textTransform: 'capitalize',
                        }}
                    >
                        {option.label ?? option.value}
                    </MenuItem>
                ))}
            </TextField>

            <TextField fullWidth
                       value={searchValue}
                       onChange={(e) => onSearchChange(e.target.value)}
                       placeholder="Szukaj..."
                       InputProps={{
                           startAdornment: (
                               <InputAdornment position="start">
                                   <Iconify
                                       icon={'eva:search-fill'}
                                       sx={{ color: 'text.disabled', width: 20, height: 20 }}
                                   />
                               </InputAdornment>
                           ),
                       }}
            />
        </Stack>
    )
};