export const supplier_tender = {
    pages: {
        supplier_tender_list: {

            page_title: 'Przetargi',
            header_tab: 'Lista przetargów',
            page_header: 'Lista przetargów',
            page_instruction: 'Poniższa lista zawiera wszystkie Twoje przetargi, w których uczestniczysz za pomocą Connect Facilities. Filtry w kolumnach pozwalają na wyszukanie konkretnego postępowania.',
            procedure_type: 'Typ procedury',
            participation: 'Uczestnictwo',
            nda_status: 'Status NDA',
            tender_status: 'Status przetargu',
            offer_status: 'Status oferty',
            published_at: 'Opublikowano',
            tender_end_date: 'Data końca przetargu',
            rfp_client_status_pending: 'Oczekuje',
            rfp_client_status_accepted: 'Potwierdzone',
            rfp_client_status_rejected: 'Odrzucone',
            offer_status_submitted: 'Złożona',
            offer_status_during_preparation: 'W trakcie przygotowania',
            nda_signed: 'Podpisane',
            nda_not_signed: 'Niepodpisane',
            state_options: {
                open: 'W trakcie',
                archived: 'Anulowane',
                closed: 'Zakończone'
            }
        },

        supplier_tender_details: {
            page_header: '{{tenderName}} - etap {{round}} ({{statusName}})',
            page_title: 'Przetarg',

            header_tabs_title: 'Przetarg',
            header_tabs: {
                general_before_invitation: 'Informacje ogólne',
                general: 'Informacje ogólne',
                offers: 'Oferty',
                questions: 'Pytania',
            },
        },

        supplier_tender_offer_details: {
            page_header: 'Strona Główna / Moje przetargi / ',
            page_title: 'Oferta przetargu',
            new_offer: 'Nowa oferta',
            offer: 'Oferta',
            step_1: 'Informacje ogólne',
            step_2: 'Usługi',
            step_3: 'Inne wymagania',
        }
    },

    sections: {
        tab_general_before_invitation_accepted: {
            tender_name: 'Nazwa przetargu',
            procedure_type: 'Typ procedury',
            deadline_offer_submission: 'Termin złożenia ofert',
            required_nda: 'Wymagana umowa NDA',
            signed_nda_date: 'Data złożenia podpisanej umowy ',
            cancel_invitation: 'Odrzuć uczestnictwo',
            accept_invitation: 'Zaakceptuj uczestnictwo',
            info_alert: 'Nie możesz wyświetlić szczegółów przetargu, dopóki nie zaakceptujesz chęci swojego udziału oraz nie zostanie ono zaakceptowane przez składającego przetarg.',
            preview_button: 'Zobacz podgląd',
        },

        tab_general: {
            card_tender_name: 'Nazwa przetargu',
            card_facility_manager_client_name: 'Rozpisany przez',
            card_offer_form: 'Wymagana forma oferty',
            card_procedure_type: 'Typ procedury',
            card_nda_deadline: 'Termin złożenia NDA',
            card_questions: 'Pytania i odpowiedzi',
            card_questions_button: 'Przejdź do sekcji',
            latest: 'Ostatnie'
        },

        tab_offers: {
            add_button: 'Nowy draft',
            instructions: 'Poniżej znajduje się lista przygotowanych przez firmę ofert w ramach przetargu. Jeżeli lista jest pusta, należy dodać nową ofertę. Wybraną z listy ofertę można w odpowiednim momencie wysłać do Zamawiającego. Po wysłaniu, oferta nie może być cofnięta.',
            offer_list: {
                offer_name: 'Nazwa oferty',
                created_at: 'Data utworzenia',
                updated_at: 'Data modyfikacji',
                created_user_name: 'Autor',
                offer_status_name: 'Status',
                publish_button: 'Opublikuj',
                publish_supplier_tender_offer_dialog: {
                    title: 'Opublikuj ofertę'
                },
                delete_supplier_tender_offer_dialog: {
                    title: 'Usuń ofertę'
                }
            }
        },

        general: {
            reject_tender_invitation_dialog: {
                title: 'Czy na pewno chcesz odrzucić dalszy udział w przetargu?',
                cancel_button: 'Nie, anuluj',
                confirm_button: 'Tak, chcę',
                instructions: 'Twoja firma została wyszukana w procesie przygotowywania przetargu i spełnia postawione przez Zamawiającego wymagania. Odrzucenie zaproszenia oznacza brak możliwości dalszego udziału w procesie przetargu i złożenia oferty.',
            },
            facility_equipment_dialog: {
                title: 'Podgląd wyposażenia ',
            },
            service_attributes_dialog: {
                title: 'Atrybuty usługi '
            },
            facility_services_dialog: {
                title: 'Usługi / obiekty'
            },
            sla_kpi_dialog: {
                title: 'SLA / KPI Usługi '
            },
            facility_service_dialog: {
                title: 'Obiekty i usługi',
                facility: 'Obiekt',
                services: 'Usługi'
            },

            facility_services: {
                card_title: 'Usługi i obiekty',
                description: 'Wykaz obiektów i usług wybranych przez zamawiającego do przetargu',
                show_attributes_button: 'Atrybuty ({{attributes_count}})',
                show_sla_kpi_button: 'SLA ({{sla_count}}) / KPI ({{kpi_count}})',
                show_equipment_button: 'Wyposażenie ({{equipment_count}})',
                show_service_price_component_button: 'Metoda wyceny',
                service_price_component_preview_dialog: {
                    title: 'Metoda wyceny'
                }
            },

            incoming_dates: {
                card_title: 'Terminarz przetargu'
            },

            my_offers: {
                go_to_offer_button: 'Przejdź do ofert',
                my_offer_drafts: 'Moje drafty ofert:',
                sent_offer: 'Wysłana oferta:'
            },

            other_requirements: {
                card_title: 'Inne wymagania',
            },

        },

        offer_details: {
            step_general: {
                step_header: 'Dane podstawowe',
                instructions: 'W poniższe pola należy wpisać nazwę tworzonej oferty oraz jej opis. Obie informacje zostaną potem przekazane Zamawiającemu. Dodatkowo należy załączyć plik z treścią oferty, w którym można podać informacje na temat swojej firmy i proponowanych rozwiązań.',
                form: {
                    offer_name: 'Twoja nazwa oferty',
                    offer_description: 'Opis oferty',
                    document_helper_text: 'Plik załącznika nie może przekraczać 10 Mb.',
                    submit: 'Następny krok',
                },
                validator: {
                    name: {
                        max: 'Nazwa nie może być dłuższa niż {{max}} znaków',
                        required: 'Nazwa jest wymagana'
                    },
                    description: {
                        max: 'Opis nie może być dłuższy niż {{max}} znaków',
                        required: 'Opis jest wymagany'
                    },
                }
            },

            step_services: {
                step_header: 'Zaoferuj cenę usług',
                instructions: 'W poniższe pola należy wpisać ceny jednostkowe dla poszczególnych usług wg. założonego przez Zamawiającego sposobu wyceny. Dodatkowo, należy podać łączną cenę (wartość) dla całego przetargu, licząc po cenach jednostkowych i zamawianych ilościach.',
                edit_service_price_button: 'Edytuj cenę',
                offer_service_price_button: 'Zaoferuj cenę',
                edit_total_offer_price_button: 'Edytuj cenę całkowitą oferty',
                total_offer_price: 'Wpisz całkowitą cenę oferty',
                previous_step_button: 'Poprzedni krok',
                save_as_draft_button: 'Zapisz jako draft',
            },

            step_other_requirements: {
                step_header: 'Inne wymagania',
                instructions: 'W poniższe pola należy wpisać odpowiedzi na zadane przez Zamawiającego wymagania, wraz z załączeniem plików opisujących sposób rozwiązania wymagań lub potwierdzających spełnienie wymagań.',
                requirement: 'Wymaganie',
                previous_step_button: 'Poprzedni krok',
                back_to_list_button: 'Powrót do listy',
                cancel_offer_button: 'Anuluj ofertę',
                other_requirement_answer_dialog: {
                    title: 'Odpowiedz na wymaganie',
                    form: {
                        answer: 'Odpowiedź'
                    }
                }
            },

            cancel_offer_dialog: {
                title: 'Czy chcesz anulować tworzenie oferty?',
                instructions: 'Anulowanie oferty spowoduje jej usunięcie',
                yes_button: 'Tak, anuluj'
            },

            edit_service_price_dialog: {
                title: 'Propozycja ceny usługi:',
            },

            service_value_history_dialog: {
                title: 'Historia PriceComponent-ów',
                round: 'Etap'
            },

            service_value_edit_dialog: {
                title: 'Propozycja ceny usługi:',
                validator: {
                    value: {
                        invalid_decimal: 'Należy podać liczbę z 2 miejscami po przecinku',
                        type_error: 'Nieprawidłowa wartośc',
                        positive: 'Należy podać liczbę dodatnią',
                    }
                }
            },

            edit_offer_total_dialog: {
                title: 'Cena całkowita oferty',
                validator: {
                    value: {
                        type_error: 'Należy podać liczbę!',
                        positive: 'Należy podać liczbę dodatnią',
                        invalid_decimal: 'Należy podać liczbę z 2 miejscami po przecinku'
                    }
                }
            }

        },
    }
};
