import { FormProvider, RHFTextField } from "../../../../../components/hook-form";
import { dispatch, useSelector } from "../../../../../redux/store";
import { Alert, Box, Button, DialogActions, Divider, DialogContent } from "@mui/material";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { useEffect } from "react";
import { addUser, closeEditUserModal, editUser } from "../../../../../redux/slices/user";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../../../hooks/useAuth";
import RHFRemoteAutocomplete from "../../../../../components/hook-form/RHFRemoteAutocomplete";
import { useTranslation } from "react-i18next";

export default function TabGeneral() {

    const { t } = useTranslation('user', { keyPrefix: 'sections.edit_dialog.tab_general' });

    const authUser = useAuth().user;

    const {
        userId,
        editUserError,
        isOpenModal,
        isUserLoading,
        userEditModalDefaultValues
    } = useSelector((state) => state.user);

    const UserFromSchema = Yup.object().shape({
        name: Yup.string()
            .max(50, t('validator.name.max', { amount: '50' }))
            .required(t('validator.name.required')),
        surname: Yup.string()
            .max(50, t('validator.surname.max', { amount: '50' }))
            .required(t('validator.surname.required')),
        position: Yup.string()
            .max(50, t('validator.position.max', { amount: '50' }))
            .required(t('validator.position.required')),
        mobile_phone_number: Yup.string(),
        other_phone_number: Yup.string(),
        user_role_id: Yup.object({ id: Yup.number().typeError(t('validator.role.required')) }),
        email: Yup.string()
            .required(t('validator.email.required'))
            .email(t('validator.email.email'))
            .max(100, t('validator.email.max', { amount: '100' })),
    });

    const defaultValues = userEditModalDefaultValues;

    const methods = useForm({ resolver: yupResolver(UserFromSchema), defaultValues });

    const { reset, handleSubmit } = methods;

    useEffect(() => {
        if (isOpenModal) {
            reset(userEditModalDefaultValues);
        }
    }, [userEditModalDefaultValues, isOpenModal, reset]);

    const onSubmit = (data: any) => {
        data.user_role_id = data.user_role_id.id;
        userId ? dispatch(editUser(userId, data)) : dispatch(addUser(data));
    };

    return (
        <FormProvider methods={ methods } onSubmit={ handleSubmit(onSubmit) }>
            <DialogContent sx={ { pt: 1 } }>
                { !!editUserError &&
                    <Alert severity="error" sx={ { mb: 3 } }>{ editUserError }
                    </Alert>
                }
                <Box sx={ {
                    display: 'grid',
                    rowGap: 3,
                    columnGap: 2,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                } }
                >
                    <RHFTextField name="name" label={ t('name') }/>
                    <RHFTextField name="surname" label={ t('surname') }/>
                    <RHFTextField name="position" label={ t('position') }/>
                    <RHFTextField disabled={ !!userId } name="email" label="E-mail"/>
                    <RHFTextField name="mobile_phone_number" label={ t('mobile_phone_number') }/>
                    <RHFTextField name="other_phone_number" label={ t('other_phone_number') }/>

                    <RHFRemoteAutocomplete
                        disabled={ authUser?.id === userId }
                        name={ `user_role_id` }
                        inputLabel={ t('role') }
                        remoteUrl={ `/api/v1/users/roles` }
                    />
                </Box>
            </DialogContent>

            <Divider/>

            <DialogActions>
                <Button color="error" variant="outlined" onClick={ () => dispatch(closeEditUserModal()) }>
                    { t('common:cancel') }
                </Button>
                <LoadingButton type="submit" color="primary" variant="contained" loading={ isUserLoading }>
                    { t('submit_button') }
                </LoadingButton>
            </DialogActions>
        </FormProvider>
    );
}
