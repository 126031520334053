import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from "@mui/material";
import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import axios from "../../utils/axios";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import { pick, uniq } from "lodash";

interface RHFRemoteAutocompleteProps extends Partial<AutocompleteProps<any, any, any, any>> {
    inputLabel: string;
    labelKey?: string;
    remoteUrl: string;
    defaultValue?: any;
    name: string;
    helperText?: string;
    onChange?: (e: any, newValue: any) => void;
    required?: boolean;
    pluck?: boolean|string[];
    emptyOptionLabel?: string|null;
    disabledOptions?: any|any[];
}

export default function RHFRemoteAutocomplete({
                                                  inputLabel,
                                                  labelKey = 'name',
                                                  remoteUrl,
                                                  name,
                                                  onChange = (e, newValue) => {
                                                  },
                                                  helperText = '',
                                                  defaultValue = {},
                                                  required = false,
                                                  disabledOptions = [],
                                                  pluck = ['id', labelKey],
                                                  emptyOptionLabel = null,
                                                  ...otherProps
                                              }: RHFRemoteAutocompleteProps) {
    const { t } = useTranslation()


    const [ open, setOpen ] = React.useState(false);
    const [ options, setOptions ] = React.useState<readonly any[]>([]);
    const loading = open && options.length === 0;

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            const response: { data: { data: any[] } } = await axios.get(remoteUrl);

            if (active) {
                if (emptyOptionLabel !== null) {
                    setOptions([...[{ id: null, [labelKey]: emptyOptionLabel }], ...response.data.data]);
                } else {
                    setOptions([...response.data.data]);
                }
            }
        })();

        return () => {
            active = false;
        };
    }, [labelKey, emptyOptionLabel, loading, remoteUrl]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [ open ]);

    const { control, setValue } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }: any) => (
                <Autocomplete
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={ () => {
                        setOpen(false);
                    } }
                    getOptionDisabled={ option => disabledOptions.includes(option.id) }
                    value={ field.value }
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option[labelKey] ?? ''}
                    onChange={(e, newValue) => {
                        onChange(e, newValue);
                        if (pluck === false) {
                            return setValue(name, newValue);
                        }
                        if (pluck === true) {
                            return setValue(name, ['id', labelKey]);
                        }
                        // if(pluck.length === 1) {
                        //     setValue(name, newValue[pluck[0]]);
                        // }
                        return setValue(name, pick(newValue, uniq([...['id', labelKey], ...pluck])));
                    }}
                    options={options}
                    loading={loading}
                    // renderOption={(props, option) => <li {...props}>{option.title}<small>SMALL</small></li>}
                    loadingText={t('common:autocomplete.loading')}
                    disableClearable={true}

                    renderInput={params => <TextField
                        {...params}
                        error={!!error}
                        label={inputLabel}
                        required={required}
                        helperText={error?.message || error?.id?.message || helperText}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />}

                    {...otherProps}
                />
            )}
        />
    );
}