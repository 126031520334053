// @mui
import { arSD, enUS, frFR, plPL, viVN, zhCN } from '@mui/material/locale';
// components
import { SettingsValueProps } from './components/settings/type'; // Polish
import * as Yup from 'yup';
import { DateSchema, NumberSchema, StringSchema } from 'yup';


// API
// ----------------------------------------------------------------------
export const HOST_API = process.env.REACT_APP_HOST_API || 'http://localhost';
export const MAX_DECIMAL_VALUE = 999999999999.99

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
    MOBILE_HEIGHT: 64,
    MAIN_DESKTOP_HEIGHT: 88,
    DASHBOARD_DESKTOP_HEIGHT: 92,
    DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
    BASE_WIDTH: 260,
    DASHBOARD_WIDTH: 280,
    DASHBOARD_COLLAPSE_WIDTH: 88,
    //
    DASHBOARD_ITEM_ROOT_HEIGHT: 48,
    DASHBOARD_ITEM_SUB_HEIGHT: 40,
    DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
    NAVBAR_ITEM: 22,
    NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
    themeMode: 'light',
    themeDirection: 'ltr',
    themeContrast: 'default',
    themeLayout: 'horizontal',
    themeColorPresets: 'blue',
    themeStretch: false,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
    {
        label: 'English',
        value: 'en',
        systemValue: enUS,
        icon: '/assets/icons/flags/ic_flag_pl.svg',
    },
    {
        label: 'French',
        value: 'fr',
        systemValue: frFR,
        icon: '/assets/icons/flags/ic_flag_fr.svg',
    },
    {
        label: 'Vietnamese',
        value: 'vn',
        systemValue: viVN,
        icon: '/assets/icons/flags/ic_flag_vn.svg',
    },
    {
        label: 'Chinese',
        value: 'cn',
        systemValue: zhCN,
        icon: '/assets/icons/flags/ic_flag_cn.svg',
    },
    {
        label: 'Arabic (Sudan)',
        value: 'ar',
        systemValue: arSD,
        icon: '/assets/icons/flags/ic_flag_sa.svg',
    },
    {
        label: 'Polish',
        value: 'pl',
        systemValue: plPL,
        icon: '/assets/icons/flags/ic_flag_pl.svg',
    },
];

export const defaultLang = allLangs[5];

// ----------------------------------------------------------------------
// YUP extensions https://github.com/jquense/yup#extending-built-in-schema-with-new-methods

declare module 'yup' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface StringSchema<TType, TContext, TOut> {
        incoming(): this;
        outgoing(): this;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface NumberSchema<TType, TContext, TOut> {
        incoming(): this;
        outgoing(): this;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface DateSchema<TType, TContext, TOut> {
        incoming(): this;
        outgoing(): this;
    }
}

Yup.addMethod<StringSchema>(Yup.string, 'incoming', function incoming() {
    return this.transform((v: any) => v === null || v === "NaN" ? '' : v);
});

Yup.addMethod<StringSchema>(Yup.string, 'outgoing', function outgoing() {
    return this.transform((v: any) => v === null || v === "NaN" || v.trim() === '' ? null : v.trim());
});

Yup.addMethod<NumberSchema>(Yup.number, 'incoming', function incoming() {
    return this.transform((v: any) => v === null || isNaN(v) ? 0 : v);
});

Yup.addMethod<NumberSchema>(Yup.number, 'outgoing', function outgoing() {
    return this.transform((v: any) => v === null || isNaN(v) || v === 0 ? null : v);
});

Yup.addMethod<DateSchema>(Yup.date, 'incoming', function incoming() {
    return this.transform((v: any) => v === null || v === "NaN" ? '' : v);
});

Yup.addMethod<DateSchema>(Yup.date, 'outgoing', function outgoing() {
    return this.transform((v: any) => v === null || isNaN(v) ? null : v);
});