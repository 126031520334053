// ----------------------------------------------------------------------

import { FacilityManagerTenderTabs } from "../sections/@dashboard/tender/details/useFacilityManagerTenderNavigation";

function path(root: string, sublink: string) {
    return `${ root }${ sublink }`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    newPassword: path(ROOTS_AUTH, '/new-password'),
    register: path(ROOTS_AUTH, '/register'),
    verifyEmail: path(ROOTS_AUTH, '/verify-email'),
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    dashboard: '/dashboard',
    user: {
        root: path(ROOTS_DASHBOARD, '/user'),
        account: path(ROOTS_DASHBOARD, '/user/account'),
        list: path(ROOTS_DASHBOARD, '/user/list'),
    },
    supplierTender: {
        root: path(ROOTS_DASHBOARD, '/supplier-tender/list'),
        list: path(ROOTS_DASHBOARD, '/supplier-tender/list'),
        details: (rfpId: string, activeTab = 'general') => path(ROOTS_DASHBOARD, `/supplier-tender/${ rfpId }/details/${ activeTab }`),
        offerDetails: (rfpId: string, rfpOfferId: string, step = 'general') => path(ROOTS_DASHBOARD, `/supplier-tender/${ rfpId }/offers/${ rfpOfferId }/${ step }`),

    },
    tender: {
        root: path(ROOTS_DASHBOARD, '/tender'),
        list: path(ROOTS_DASHBOARD, '/tender/list'),
        details: (rfpId: string, activeTab = FacilityManagerTenderTabs.TAB_BASIC_INFO) => path(ROOTS_DASHBOARD, `/tender/${ rfpId }/details/${ activeTab }`),
        clientRatingList: (id: string) => path(ROOTS_DASHBOARD, `/tender/${ id }/client-rating-list`),
    },
    facility: {
        list: path(ROOTS_DASHBOARD, '/facility/list'),
        details: (id: string) => path(ROOTS_DASHBOARD, `/facility/${ id }/details`),
    },
};

