// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { FormControlLabel, FormControlLabelProps, Switch } from '@mui/material';

// ----------------------------------------------------------------------

type IProps = Omit<FormControlLabelProps, 'control'>;

interface Props extends IProps {
    name: string;
    disabled?: boolean;
}

export default function RHFSwitch({ name, disabled = false, ...other }: Props) {
    const { control } = useFormContext();

    return (
        <FormControlLabel
            control={
                <Controller
                    name={ name }
                    control={ control }
                    render={ ({ field }) => <Switch { ...field } disabled={ disabled } checked={ field.value }/> }
                />
            }
            { ...other }
        />
    );
}
