import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { SystemServices, TenderState } from '../../@types/tender';
import { dispatch } from '../store';
import { showSnackbar } from "./snackbar";
import { forEach } from "lodash";
import { getFacilityManagerTenderState } from "../../apis/FacilityManagerTenderState/getFacilityManagerTenderState";
import { getTabBasicInfoState } from "../../apis/FacilityManagerTenderState/getTabBasicInfoState";
import { getTabCriteriaState } from "../../apis/FacilityManagerTenderState/getTabCriteriaState";
import { getTabDecisionState } from "../../apis/FacilityManagerTenderState/getTabDecisionState";
import { getTabDetailsState } from "../../apis/FacilityManagerTenderState/getTabDetailsState";
import { getTabFacilitiesState } from "../../apis/FacilityManagerTenderState/getTabFacilitiesState";
import { getTabInviteState } from "../../apis/FacilityManagerTenderState/getTabInviteState";
import { getTabManageTenderClientsState } from "../../apis/FacilityManagerTenderState/getTabManageTenderClientsState";
import { getTabOfferAnalysisState } from "../../apis/FacilityManagerTenderState/getTabOfferAnalysisState";
import { getTabOffersState } from "../../apis/FacilityManagerTenderState/getTabOffersState";
import { getTabPublishState } from "../../apis/FacilityManagerTenderState/getTabPublishState";
import { getTabQuestionsState } from "../../apis/FacilityManagerTenderState/getTabQuestionsState";
import { getTabSearchState } from "../../apis/FacilityManagerTenderState/getTabSearchState";
import { getTabServicesState } from "../../apis/FacilityManagerTenderState/getTabServicesState";
import { RfiStatus } from "../../@types/rfiStatus";

// ----------------------------------------------------------------------

const initialState: TenderState = {
    rfp_id: '',
    rfiStatusId: 1,
    rfp_name: '',
    rfp_status_id: '',
    round: 1,
    awarded_rfp_offer_id: '',
    isLoaded: false,
    tabBasicInfo: {
        isLoaded: false,
        isReadonly: false,
        isDisabled: false,
        sections: [{ name: 'other_requirements', canEdit: true, canAdd: true, canDelete: true }]
    },
    tabServices: { isReadonly: false, isDisabled: true, systemServices: [], tenderServices: [] },
    tabFacilities: {
        isReadonly: false,
        isDisabled: true,
        isLoading: false,
        facilityServices: { facilities: [] },
        addToSelectedFacilitiesDialog: { isOpen: false },
        addFacilityFirstStepDialog: { isOpen: false },
        selectExistingFacilityDialog: { isOpen: false },
        createNewFacilityDialog: { isOpen: false },
        selectExistingNestedFacilityDialog: {
            isOpen: false,
            parentFacilityId: undefined,
            facilities: [],
        },
        services: [],
    },
    tabCriteria: { isReadonly: false, isDisabled: true },
    tabSearch: { isReadonly: false, isDisabled: true },
    tabDetails: { isReadonly: false, isDisabled: true, },
    tabInvite: { isReadonly: false, isDisabled: true },
    tabManageTenderClients: { isReadonly: false, isDisabled: true },
    tabPublish: { isReadonly: false, isDisabled: true },
    tabQuestions: { isReadonly: false, isDisabled: true },
    tabOffers: { isReadonly: false, isDisabled: true },
    tabOfferAnalysis: { isReadonly: false, isDisabled: true },
    tabDecision: { isReadonly: false, isDisabled: true }
};

const slice = createSlice({
    name: 'tender',
    initialState,
    reducers: {
        resetTenderState: () => initialState,

        setIsLoaded(state) {
            state.isLoaded = true;
        },

        setTabBasicInfoIsLoaded(state) {
            state.tabBasicInfo.isLoaded = true;
        },

        getFacilityManagerTenderStateSuccess(state, action) {
            state.isLoaded = true;
            state.rfp_id = action.payload.rfp_id;
            state.rfp_name = action.payload.rfp_name;
            state.rfp_status_id = action.payload.rfp_status_id;
            state.awarded_rfp_offer_id = action.payload.awarded_rfp_offer_id;
            state.round = action.payload.round;
            state.rfiStatusId = action.payload.rfi_status_id;
            forEach(action.payload.tabs, function (value, tab: string) {
                state[tab as keyof TenderState].disabledReason = value.disabledReason;
                state[tab as keyof TenderState].isDisabled = value.isDisabled;
            })
        },

        getTabBasicInfoStateSuccess(state, action) {
            state.tabBasicInfo.isLoaded = true;
            state.tabBasicInfo.isReadonly = action.payload.isReadonly;
            state.tabBasicInfo.tabAlertMessage = action.payload.tabAlertMessage;
            state.tabBasicInfo.sections = action.payload.sections;
            state.tabBasicInfo.fields = action.payload.fields;
        },

        getTabCriteriaStateSuccess(state, action) {
            state.tabCriteria.isReadonly = action.payload.isReadonly;
            state.tabCriteria.tabAlertMessage = action.payload.tabAlertMessage;
            state.tabCriteria.sections = action.payload.sections;
            state.tabCriteria.fields = action.payload.fields;
        },

        getTabDecisionStateSuccess(state, action) {
            state.tabDecision.isReadonly = action.payload.isReadonly;
            state.tabDecision.tabAlertMessage = action.payload.tabAlertMessage;
            state.tabDecision.sections = action.payload.sections;
            state.tabDecision.fields = action.payload.fields;
        },

        getTabDetailsStateSuccess(state, action) {
            state.tabDetails.isReadonly = action.payload.isReadonly;
            state.tabDetails.tabAlertMessage = action.payload.tabAlertMessage;
            state.tabDetails.sections = action.payload.sections;
            state.tabDetails.fields = action.payload.fields;
        },

        getTabFacilitiesStateSuccess(state, action) {
            state.tabFacilities.isReadonly = action.payload.isReadonly;
            state.tabFacilities.tabAlertMessage = action.payload.tabAlertMessage;
            state.tabFacilities.sections = action.payload.sections;
            state.tabFacilities.fields = action.payload.fields;
        },

        getTabInviteStateSuccess(state, action) {
            state.tabInvite.isReadonly = action.payload.isReadonly;
            state.tabInvite.tabAlertMessage = action.payload.tabAlertMessage;
            state.tabInvite.sections = action.payload.sections;
            state.tabInvite.fields = action.payload.fields;
        },

        getTabManageTenderClientsStateSuccess(state, action) {
            state.tabManageTenderClients.isReadonly = action.payload.isReadonly;
            state.tabManageTenderClients.tabAlertMessage = action.payload.tabAlertMessage;
            state.tabManageTenderClients.sections = action.payload.sections;
            state.tabManageTenderClients.fields = action.payload.fields;
        },

        getTabOfferAnalysisStateSuccess(state, action) {
            state.tabOfferAnalysis.isReadonly = action.payload.isReadonly;
            state.tabOfferAnalysis.tabAlertMessage = action.payload.tabAlertMessage;
            state.tabOfferAnalysis.sections = action.payload.sections;
            state.tabOfferAnalysis.fields = action.payload.fields;
        },

        getTabOffersStateSuccess(state, action) {
            state.tabOffers.isReadonly = action.payload.isReadonly;
            state.tabOffers.tabAlertMessage = action.payload.tabAlertMessage;
            state.tabOffers.sections = action.payload.sections;
            state.tabOffers.fields = action.payload.fields;
        },

        getTabPublishStateSuccess(state, action) {
            state.tabPublish.isReadonly = action.payload.isReadonly;
            state.tabPublish.tabAlertMessage = action.payload.tabAlertMessage;
            state.tabPublish.sections = action.payload.sections;
            state.tabPublish.fields = action.payload.fields;
        },

        getTabQuestionsStateSuccess(state, action) {
            state.tabQuestions.isReadonly = action.payload.isReadonly;
            state.tabQuestions.tabAlertMessage = action.payload.tabAlertMessage;
            state.tabQuestions.sections = action.payload.sections;
            state.tabQuestions.fields = action.payload.fields;
        },

        getTabSearchStateSuccess(state, action) {
            state.tabSearch.isReadonly = action.payload.isReadonly;
            state.tabSearch.tabAlertMessage = action.payload.tabAlertMessage;
            state.tabSearch.sections = action.payload.sections;
            state.tabSearch.fields = action.payload.fields;
        },

        getTabServicesStateSuccess(state, action) {
            state.tabServices.isReadonly = action.payload.isReadonly;
            state.tabServices.tabAlertMessage = action.payload.tabAlertMessage;
            state.tabServices.sections = action.payload.sections;
            state.tabServices.fields = action.payload.fields;
        },

        setTabReadonly(state, action) {
            const {
                readonly,
                tab,
                readonlyReason
            }: { readonly: boolean; tab: keyof TenderState; readonlyReason: string; } = action.payload;
            state[tab].isReadonly = readonly;
            state[tab].readonlyReason = readonlyReason;
        },


        // ----------------------------------------------------------------------
        // tab_services
        // ----------------------------------------------------------------------
        getSystemServicesSuccess(state, action) {
            state.tabServices.systemServices = action.payload;
        },
        // ----------------------------------------------------------------------
        // tab_facilities
        // ----------------------------------------------------------------------
        setTabFacilitiesLoading(state, action) {
            state.tabFacilities.isLoading = action.payload;
        },
        getTenderFacilityServices(state, action) {
            state.tabFacilities.facilityServices = { facilities: action.payload };
        },
        getTenderServicesSuccess(state, action) {
            state.tabFacilities.services = action.payload;
        },
        openAddToSelectedFacilitiesDialog(state) {
            state.tabFacilities.addToSelectedFacilitiesDialog.isOpen = true;
        },
        closeAddToSelectedFacilitiesDialog(state) {
            state.tabFacilities.addToSelectedFacilitiesDialog.isOpen = false;
        },
        openAddFacilityFirstStepDialog(state) {
            state.tabFacilities.addFacilityFirstStepDialog.isOpen = true;
        },
        closeAddFacilityFirstStepDialog(state) {
            state.tabFacilities.addFacilityFirstStepDialog.isOpen = false;
        },
        openSelectExistingFacilityDialog(state) {
            state.tabFacilities.selectExistingFacilityDialog.isOpen = true;
        },
        closeSelectExistingFacilityDialog(state) {
            state.tabFacilities.selectExistingFacilityDialog.isOpen = false;
        },
        openCreateNewFacilityDialog(state) {
            state.tabFacilities.createNewFacilityDialog.isOpen = true;
        },
        closeCreateNewFacilityDialog(state) {
            state.tabFacilities.createNewFacilityDialog.isOpen = false;
        },
        openSelectExistingNestedFacilityDialog(state, action) {
            state.tabFacilities.selectExistingNestedFacilityDialog.isOpen = true;
            state.tabFacilities.selectExistingNestedFacilityDialog.facilities = action.payload.facilities;
        },
        closeSelectExistingNestedFacilityDialog(state) {
            state.tabFacilities.selectExistingNestedFacilityDialog = initialState.tabFacilities.selectExistingNestedFacilityDialog;
        },


        // TAB SEARCH
        startSearching(state) {
            state.rfiStatusId = Number(RfiStatus.SEARCH_IN_PROGRESS);
        },

        initTenderSearchSuccess(state, action) {
            state.rfiStatusId = action.payload;
        },

        getTenderStatusSuccess(state, action) {
            state.rfiStatusId = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    resetTenderState,
    closeSelectExistingFacilityDialog,
    closeSelectExistingNestedFacilityDialog,
    closeCreateNewFacilityDialog,
    closeAddFacilityFirstStepDialog,
    closeAddToSelectedFacilitiesDialog,
    openAddFacilityFirstStepDialog,
    openAddToSelectedFacilitiesDialog,
    openCreateNewFacilityDialog,
    openSelectExistingFacilityDialog,
} = slice.actions;


export function setFacilityManagerTenderState(rfpId: string) {
    return async () => {
        try {
            if (rfpId === 'new') {
                dispatch(slice.actions.setIsLoaded());
            } else {
                const response = await getFacilityManagerTenderState(rfpId);
                dispatch(slice.actions.getFacilityManagerTenderStateSuccess(response));
            }
        } catch (error) {
            dispatch(showSnackbar({ message: error.message, options: { variant: 'error' } }));
        }
    }
}

export function setTabBasicInfoState(rfpId: string) {
    return async () => {
        try {
            if (rfpId === 'new') {
                dispatch(slice.actions.setTabBasicInfoIsLoaded());
            } else {
                const response = await getTabBasicInfoState(rfpId);
                dispatch(slice.actions.getTabBasicInfoStateSuccess(response));
            }
        } catch (error) {
            dispatch(showSnackbar({ message: error.message, options: { variant: 'error' } }));
        }
    }
}

export function setTabCriteriaState(rfpId: string) {
    return async () => {
        try {
            const response = await getTabCriteriaState(rfpId);
            dispatch(slice.actions.getTabCriteriaStateSuccess(response));
        } catch (error) {
            dispatch(showSnackbar({ message: error.message, options: { variant: 'error' } }));
        }
    }
}

export function setTabDecisionState(rfpId: string) {
    return async () => {
        try {
            const response = await getTabDecisionState(rfpId);
            dispatch(slice.actions.getTabDecisionStateSuccess(response));
        } catch (error) {
            dispatch(showSnackbar({ message: error.message, options: { variant: 'error' } }));
        }
    }
}

export function setTabDetailsState(rfpId: string) {
    return async () => {
        try {
            const response = await getTabDetailsState(rfpId);
            dispatch(slice.actions.getTabDetailsStateSuccess(response));
        } catch (error) {
            dispatch(showSnackbar({ message: error.message, options: { variant: 'error' } }));
        }
    }
}

export function setTabFacilitiesState(rfpId: string) {
    return async () => {
        try {
            const response = await getTabFacilitiesState(rfpId);
            dispatch(slice.actions.getTabFacilitiesStateSuccess(response));
        } catch (error) {
            dispatch(showSnackbar({ message: error.message, options: { variant: 'error' } }));
        }
    }
}

export function setTabInviteState(rfpId: string) {
    return async () => {
        try {
            const response = await getTabInviteState(rfpId);
            dispatch(slice.actions.getTabInviteStateSuccess(response));
        } catch (error) {
            dispatch(showSnackbar({ message: error.message, options: { variant: 'error' } }));
        }
    }
}

export function setTabManageTenderClientsState(rfpId: string) {
    return async () => {
        try {
            const response = await getTabManageTenderClientsState(rfpId);
            dispatch(slice.actions.getTabManageTenderClientsStateSuccess(response));
        } catch (error) {
            dispatch(showSnackbar({ message: error.message, options: { variant: 'error' } }));
        }
    }
}

export function setTabOfferAnalysisState(rfpId: string) {
    return async () => {
        try {
            const response = await getTabOfferAnalysisState(rfpId);
            dispatch(slice.actions.getTabOfferAnalysisStateSuccess(response));
        } catch (error) {
            dispatch(showSnackbar({ message: error.message, options: { variant: 'error' } }));
        }
    }
}

export function setTabOffersState(rfpId: string) {
    return async () => {
        try {
            const response = await getTabOffersState(rfpId);
            dispatch(slice.actions.getTabOffersStateSuccess(response));
        } catch (error) {
            dispatch(showSnackbar({ message: error.message, options: { variant: 'error' } }));
        }
    }
}

export function setTabPublishState(rfpId: string) {
    return async () => {
        try {
            const response = await getTabPublishState(rfpId);
            dispatch(slice.actions.getTabPublishStateSuccess(response));
        } catch (error) {
            dispatch(showSnackbar({ message: error.message, options: { variant: 'error' } }));
        }
    }
}

export function setTabQuestionsState(rfpId: string) {
    return async () => {
        try {
            const response = await getTabQuestionsState(rfpId);
            dispatch(slice.actions.getTabQuestionsStateSuccess(response));
        } catch (error) {
            dispatch(showSnackbar({ message: error.message, options: { variant: 'error' } }));
        }
    }
}

export function setTabSearchState(rfpId: string) {
    return async () => {
        try {
            const response = await getTabSearchState(rfpId);
            dispatch(slice.actions.getTabSearchStateSuccess(response));
        } catch (error) {
            dispatch(showSnackbar({ message: error.message, options: { variant: 'error' } }));
        }
    }
}

export function setTabServicesState(rfpId: string) {
    return async () => {
        try {
            const response = await getTabServicesState(rfpId);
            dispatch(slice.actions.getTabServicesStateSuccess(response));
        } catch (error) {
            dispatch(showSnackbar({ message: error.message, options: { variant: 'error' } }));
        }
    }
}

// ----------------------------------------------------------------------

const BASE_URL = '/api/v1/frontend/facility-manager-tenders';

export function getSystemServices() {
    return async () => {
        try {
            const response: { data: { data: SystemServices[] } } = await axios.get('/api/v1/frontend/facility-manager-tenders/services');

            dispatch(slice.actions.getSystemServicesSuccess(response.data.data));

        } catch (error) {
            dispatch(showSnackbar({ message: "common:error", options: { variant: 'error' } }));
        }
    }
}

// ----------------------------------------------------------------------

export function getTenderFacilityServices(rfpId: string) {
    return async () => {
        dispatch(slice.actions.setTabFacilitiesLoading(true));
        const response: { data: any } = await axios.get(`/api/v1/frontend/facility-manager-tenders/${ rfpId }/tender-facility-services`);
        dispatch(slice.actions.getTenderFacilityServices(response.data.data));
        dispatch(slice.actions.setTabFacilitiesLoading(false));
    }
}

export function openSelectExistingNestedFacilityDialog(parentFacilityId: string | number) {
    return async () => {
        const response: { data: { data: any } } = await axios.get(`/api/v1/frontend/facility-manager-tenders/facilities/${ parentFacilityId }`);


        dispatch(slice.actions.openSelectExistingNestedFacilityDialog({
            parentFacilityId,
            facilities: response.data.data,
        }));
    }
}

export function saveCreateNewFacilityDialog(formData: any) {
    return async () => {
        const response = await axios.post('/api/v1/facilities', formData);
        return response.data;
    };
}

export function saveTabFacilities(rfpId: string, formData: any) {
    return async () => {
        dispatch(slice.actions.setTabFacilitiesLoading(true));
        await axios.put(`/api/v1/frontend/facility-manager-tenders/${ rfpId }/facilities-services`, formData);
        dispatch(slice.actions.setTabFacilitiesLoading(false));
    };
}

// ----------------------------------------------------------------------

export function initTenderSearch(rfpId: string) {
    dispatch(slice.actions.startSearching());

    return async () => {
        try {
            const response = await axios.post(`${ BASE_URL }/${ rfpId }/search/init-search`);
            dispatch(slice.actions.initTenderSearchSuccess(response.data.rfi_status_id));
        } catch (error) {
            dispatch(showSnackbar({ message: error.message, options: { variant: 'error' } }));
        }
    }
}

export function getTenderStatus(rfpId: string) {
    return async () => {
        try {
            const response = await axios.get(`${ BASE_URL }/${ rfpId }/search/tender-status`);
            dispatch(slice.actions.getTenderStatusSuccess(response.data.rfi_status_id));
        } catch (error) {
            dispatch(showSnackbar({ message: error.message, options: { variant: 'error' } }));
        }
    }
}
