// @mui
import { Box, Typography, Stack } from '@mui/material';
// assets
import { UploadIllustration } from '../../assets';
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

export default function BlockContent() {

    const { t } = useTranslation();

    return (
        <Stack
            spacing={ 2 }
            alignItems="center"
            justifyContent="center"
            direction={ { xs: 'column', md: 'row' } }
            sx={ { width: 1, textAlign: { xs: 'center', md: 'left' } } }
        >
            <UploadIllustration sx={ { width: 220 } }/>

            <Box sx={ { p: 3 } }>
                <Typography gutterBottom variant="h5">
                    { t('dropzone.drop_or_select') } {/*Drop or Select file*/}
                </Typography>

                <Typography variant="body2" sx={ { color: 'text.secondary' } }>
                    { t('dropzone.drop_files_here') }&nbsp; {/*Drop files here or click&nbsp;*/}
                    <Typography
                        variant="body2"
                        component="span"
                        sx={ { color: 'primary.main', textDecoration: 'underline' } }
                    >
                        { t('dropzone.browse') }{/*browse*/}
                    </Typography>
                    &nbsp;{ t('dropzone.thorough_your_machine') } {/*&nbsp;thorough your machine*/}
                </Typography>
            </Box>
        </Stack>
    );
}
