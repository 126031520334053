// @mui
import { Checkbox, Stack, StackProps, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

interface Props extends StackProps {
    dense?: boolean;
    actions?: React.ReactNode;
    rowCount: number;
    numSelected: number;
    onSelectAllRows: (checked: boolean) => void;
    selectedTranslateSuffix?: string;
    activeRowCount?: number
}

export default function TableSelectedActions({
                                                 dense,
                                                 actions,
                                                 rowCount,
                                                 activeRowCount,
                                                 numSelected,
                                                 onSelectAllRows,
                                                 sx,
                                                 selectedTranslateSuffix = '',
                                                 ...other
                                             }: Props) {
    const { t } = useTranslation('', { keyPrefix: `table.selected_actions${ selectedTranslateSuffix }` });
    return (
        <Stack
            direction="row"
            alignItems="center"
            sx={ {
                px: 2,
                top: 0,
                left: 8,
                right: 8,
                zIndex: 9,
                height: 58,
                borderRadius: 1,
                position: 'absolute',
                bgcolor: 'primary.lighter',
                ...(dense && {
                    pl: 1,
                    height: 38,
                }),
                ...sx,
            } }
            { ...other }
        >
            <Checkbox
                indeterminate={ numSelected > 0 && numSelected < (activeRowCount || rowCount) }
                checked={ rowCount > 0 && numSelected === (activeRowCount || rowCount) }
                onChange={ (event: React.ChangeEvent<HTMLInputElement>) =>
                    onSelectAllRows(event.target.checked)
                }
            />

            <Typography
                variant="subtitle1"
                sx={ {
                    ml: 2,
                    flexGrow: 1,
                    color: 'primary.main',
                    ...(dense && {
                        ml: 3,
                    }),
                } }
            >
                { t('selected', { count: numSelected }) }
            </Typography>

            { actions && actions }
        </Stack>
    );
}
