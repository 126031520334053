import axios from "../../utils/axios";

export type TabOffersStateResponse = {
    isReadonly: boolean;
    readonlyReason: string;
}

export const getTabOffersState = (rfpId: string) => axios
    .get(`/api/v1/frontend/tenders/facility-manager/${ rfpId }/tab-offers-state`)
    .then((response: { data: { data: TabOffersStateResponse } }) => response.data.data)
