import { Navigate, useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
// @mui
import { styled } from '@mui/material/styles';
import { Alert, Container, Typography } from '@mui/material';
import { LoadingButton } from "@mui/lab";
// components
import Page from '../../components/Page';
import { FormProvider } from '../../components/hook-form';
import AccountPopover from "../../layouts/dashboard/header/AccountPopover";
import { SentIcon } from '../../assets';
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// hooks
import { useForm } from "react-hook-form";
import useAuth from "../../hooks/useAuth";
import useIsMountedRef from "../../hooks/useIsMountedRef";
// utils
import axios from "../../utils/axios";
// pages
import Login from "./Login";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'flex-end',
}));

// ----------------------------------------------------------------------

export default function VerifyEmail() {
    const { t } = useTranslation('auth', { keyPrefix: 'pages.verify_email' })

    const isMountedRef = useIsMountedRef();

    const methods = useForm();

    const { isAuthenticated, isVerified } = useAuth();

    const verifyUrl = new URLSearchParams(window.location.search).get('verify_url');

    const { pathname } = useLocation();

    const [requestedLocation, setRequestedLocation] = useState<string|null>(null);

    const { handleSubmit, setError, formState: { errors, isSubmitting } } = methods;

    const verifyEmail = useCallback(async () => {
        if (!isVerified && verifyUrl) {
            try {
                await axios.get(verifyUrl);
                window.location.reload()
            } catch (error) {
                if (isMountedRef.current) {
                    setError('afterSubmit', { ...error, message: error.message });
                }
            }
        }
    }, [isVerified, verifyUrl, isMountedRef, setError]);

    useEffect(() => {
        if (verifyUrl && !isVerified) {
            verifyEmail().then();
        }
    }, [verifyUrl, isVerified, verifyEmail])


    if (!isAuthenticated) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <Login/>;
    }

    if (isVerified && isAuthenticated) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <Navigate to={ PATH_DASHBOARD.dashboard }/>;
    }

    const onSubmit = async () => {
        try {
            await axios.post('/api/email/verification-notification');
        } catch (error) {
            if (isMountedRef.current) {
                setError('afterSubmit', { ...error, message: error.message });
            }
        }
    };

    return (
        <Page title={ t('page_title') }>
            <HeaderStyle>
                <AccountPopover/>
            </HeaderStyle>

            <Container>
                <ContentStyle sx={ { textAlign: 'center' } }>
                    <SentIcon sx={ { mb: 5, mx: 'auto', height: 120 } }/>

                    { !!errors.afterSubmit ?
                        <>
                            <Typography variant="h4" gutterBottom> { t('error_header') } </Typography>
                            <Alert severity="error" sx={ { my: 3 } }>{ errors.afterSubmit.message } </Alert>
                        </>
                        :
                        <>
                            <Typography variant="h4" gutterBottom>
                                { t('header') }
                            </Typography>
                            <Typography>
                                { t('verify_message') }
                            </Typography>
                        </>
                    }

                    <FormProvider methods={ methods } onSubmit={ handleSubmit(onSubmit) }>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={ isSubmitting }
                            sx={ { mt: 5 } }
                        >
                            { t('send_again_button') }
                        </LoadingButton>
                    </FormProvider>

                </ContentStyle>
            </Container>
        </Page>
    );
}
