export const facility = {
    list: {
        page: {
            title: 'Katalog obiektów',
            breadcrumb: {
                heading: 'Katalog obiektów',
                name: 'Katalog obiektów'
            },
        },
        tab: 'Lista moich obiektów',
        card: {
            header: 'Katalog obiektów'
        },
        facility_list: {
            instructions: 'Poniżej znajduje się lista Twoich obiektów. Zakładaj, aktualizuj i uzupełniaj listę obiektów wraz ze szczegółowymi informacjami dotyczącymi ich infrastruktury. Im bardziej szczegółowe dane o obiekcie, tym więcej czasu zaoszczędzisz każdorazowo podczas przygotowywania danych do przetargów na usługi i produkty.',
            table: {
                filter: {
                    facilityType: {
                        label: 'Typ',
                        any: '-'
                    },
                    facilityFunction: {
                        label: 'Funkcja',
                        any: '-'
                    }
                },
                column: {
                    name: 'Nazwa',
                    gross_area: 'Powierzchnia',
                    address: 'Adres',
                    facility_type_id: 'Typ',
                    facility_function_id: 'Funkcja',
                    facility_id: 'Rodzaj',
                    actions: 'Akcje'
                },
                tooltip: {
                    edit: 'Edytuj',
                    delete: 'Usuń',
                    parent: 'Obiekt nadrzędny',
                    child: 'Obiekt podrzędny'
                },
                dense: 'Gęsto'
            },
            button: {
                new: 'Dodaj obiekt'
            },
            search: {
                placeholder: 'Szukaj'
            },
            message_box: {
                button: {
                    confirm: 'Tak',
                    cancel: 'Nie',
                },
                delete: {
                    title: 'Usuwanie obiektu',
                    text: 'Czy na pewno chcesz usunąć wskazany obiekt?',
                    succeeded: 'Obiekt został usunięty,',
                    failed: 'Wystąpił błąd podczas usuwania obiektu.'
                },
                edit: {
                    title: 'Edycja obiektu',
                    text: 'Czy na pewno chcesz edytować wzkazany obiekt?'
                }
            },
        },
        add_dialog: {
            title: 'Dodawanie nowego obiektu',
            button: {
                cancel: 'Anuluj',
                submit: 'Zapisz',
                add_area: 'Wybierz region'
            },
            validation: {
                name: {
                    required: 'Nazwa obiektu jest wymagana.',
                    max: 'Nazwa rejestrowa nie może być dłuższa niż ${max} znaków.'
                },
                facility_type_id: {
                    required: 'Proszę wybrać typ obiektu z listy rozwijanej.',
                    integer: 'Identyfikator typu obiektu musi być liczbą całkowitą',
                    positive: 'Identyfikator typu obiektu musi byc liczbą dodatnią'
                },
                facility_function_id: {
                    required: 'Proszę wybrać funkcję obiektu z listy rozwijanej.',
                    integer: 'Identyfikator funkcji obiektu musi być liczbą całkowitą',
                    positive: 'Identyfikator funkcji obiektu musi byc liczbą dodatnią'
                },
                area_1_level_id: {
                    required: 'Proszę wybrać kraj z listy rozwijanej.',
                    integer: 'Identyfikator kraju musi być liczbą całkowitą',
                    positive: 'Identyfikator kraju musi byc liczbą dodatnią'
                },
                area_2_level_id: {
                    required: 'Proszę wybrać województwo z listy rozwijanej.',
                    integer: 'Identyfikator województwa musi być liczbą całkowitą',
                    positive: 'Identyfikator województwa musi byc liczbą dodatnią'
                },
                area_3_level_id: {
                    required: 'Proszę wybrać powiat z listy rozwijanej.',
                    integer: 'Identyfikator powiatu musi być liczbą całkowitą',
                    positive: 'Identyfikator powiatu musi byc liczbą dodatnią'
                },
                area_4_level_id: {
                    required: 'Proszę wybrać gminę z listy rozwijanej.',
                    integer: 'Identyfikator gminy musi być liczbą całkowitą',
                    positive: 'Identyfikator gminy musi byc liczbą dodatnią'
                },
                city: {
                    required: 'Nazwa miasta jest wymagana.',
                    max: 'Nazwa miasta nie może być dłuższa niż ${max} znaków.'
                },
                street: {
                    max: 'Nazwa ulicy nie może być dłuższa niż ${max} znaków.'
                },
                zip_code: {
                    max: 'Kod pocztowy nie może być dłuższy niż ${max} znaków.'
                },
                areas: {
                    required: 'Proszę wybrać region działania obiektu.'
                }
            },
            form: {
                name: 'Nazwa obiektu',
                facility_function_id: 'Funkcja obiektu',
                facility_type_id: 'Typ obiektu',
                area_1_level_id: 'Kraj',
                area_2_level_id: 'Województwo',
                area_3_level_id: 'Powiat',
                area_4_level_id: 'Gmina',
                city: 'Miasto',
                street: 'Ulica',
                zip_code: 'Kod pocztowy',
                areas: 'Region działania'
            },
            label: {
                areas: 'Region działania obiektu',
                address: 'Dane adresowe',
                location: 'Lokalizacja'
            },
            message: {
                submit: {
                    succeeded: 'Nowy obiekt został zapisany prawidłowo.',
                    failed: 'Wystąpił błęd podczas zapisywania nowego obiektu.'
                },
                areas: {
                    not_selected: 'Wybierz region działania obiektu.'
                }
            }
        }
    },
    details: {
        page: {
            title: 'Edycja obiektu',
            breadcrumb: {
                heading: 'Edycja obiektu',
                name: 'Edycja obiektu'
            }
        },
        tab: {
            general: {
                label: 'Informacja ogólne',
            },
            documents: {
                label: 'Dokumenty',
            },
            equipment: {
                label: 'Wyposażenie'
            },
            certificates: {
                label: 'Certyfikaty'
            }
        },
        card: {
            header: 'Edycja obiektu'
        },
        general: {
            instructions: 'Uzupełnij dane o obiekcie. Im bardziej szczegółowo wypełnisz informacje tym bardziej precyzyjne będą Twoje przetargi i zebrane oferty. Skróci to również czas potrzebny na odpowiadanie na pytania Dostawców w procesie. Postaraj się na bieżąco korygować informacje o obiekcie, aby w każdej chwili stworzenie przetargu zajęło jak najmniej czasu.',
            form: {
                facility_id: 'Jednostka nadrzędna',
                name: 'Nazwa',
                description: 'Opis',
                facility_type_id: 'Typ obiektu',
                facility_function_id: 'Funkcja obiektu',
                facility_manager_user_id: 'Opiekun',
                year_of_construction: 'Rok budowy',
                gross_area: 'Powierzchnia (m2)',
                classification: 'Klasyfikacja',
                zip_code: 'Kod pocztowy',
                city: 'Miasto',
                street: 'Ulica',
                unit_number: 'Numer budynku',
                subunit_number: 'Numer lokalu',
                areas: 'Region',
                owner_company_name: 'Właściciel obiektu',
                owner_company_nip: 'NIP właściciela obiektu',
                google_address_short_code: 'Kod adresowy Google'
            },
            label: {
                basic_data: 'Dane podstawowe',
                address_data: 'Dane adresowe',
                areas: 'Region działania',
                other: 'Pozostałe',
            },
            button: {
                areas: 'Wybierz region',
                submit: 'Zapisz'
            },
            message: {
                submit: {
                    succeeded: 'Dane obiektu zostały zapisane prawidłowo.'
                },
                incoming: {
                    error: 'Wystąpił błąd w trakcie weryfikowania pobranych danych: ',
                    empty: 'Otrzymano błędną odpowiedź serwera, nie zawierającą żadnych danych obiektu.'
                },
                outgoing: {
                    error: 'Wystąpił błąd w trakcie przygotowywania danych do zapisu: '
                }
            },
            validation: {
                incoming: {
                },
                outgoing: {
                    facility_id: {
                        integer: 'Identyfikator obiektu nadrzędnego musi być liczbą naturalną.',
                        positive: 'Identyfikator obiektu nadrzędnego musi być liczbą dodatnią.'
                    },
                    facility_type_id: {
                        integer: 'Identyfikator typu obiektu nadrzędnego musi być liczbą naturalną.',
                        positive: 'Identyfikator typu obiektu nadrzędnego musi być liczbą dodatnią.',
                        required: 'Identyfikator typu obiektu nadrzędnego jest wymagany.'
                    },
                    facility_function_id: {
                        integer: 'Identyfikator funkcji obiektu nadrzędnego musi być liczbą naturalną.',
                        positive: 'Identyfikator funkcji obiektu nadrzędnego musi być liczbą dodatnią.',
                        required: 'Identyfikator funkcji obiektu nadrzędnego jest wymagany.'
                    },
                    name: {
                        required: 'Nazwa obiektu jest wymagana',
                        max: 'Nazwa obiektu nie może być dłuższa niz ${max} znaków.'
                    },
                    description: {
                        max: 'Opis obiektu nie może być dłuższy niż ${max} znaków.'
                    },
                    google_addres_short_code: {
                        max: 'Kod aresowy Google nie może być dłuzszy niż ${max} znaków.'
                    },
                    owner_company_name: {
                        max: 'Oznaczenie właściciela obiektu nie może dłuższe niż ${max} znaków.'
                    },
                    owner_company_nip: {
                        matches: 'Numer NIP właściciela obiektu ma nieprawidłowy format (10 cyfr, bez spacji, bez oznaczenia kraju).'
                    },
                    in_portfolio_from: {

                    },
                    in_portfolio_to: {

                    },
                    year_of_construction: {
                        min: 'Rok budowy obiektu nie może być wcześniejszy niż ${min}.',
                        max: 'Rok budowy obiektu nie może być późniejszy niż ${3000}'
                    },
                    gross_area: {
                        positive: 'Powierzchnia obiektu musi być liczbą dodatnią.',
                        max: 'Powierzchnia obiektu nie może być większa niż ${max}.'
                    },
                    classification: {
                        max: 'Klasyfikacja obiektu nie może być dłuższa niż ${max} znaków.'
                    },
                    city: {
                        max: 'Nazwa miasta obiektu nie może być dłuższa niż ${max} znaków.',
                        required: 'Nazwa miasta obiektu jest wymagana'
                    },
                    street: {
                        max: 'Nazwa ulicy obiektu nie może być dłuższa niż ${max} znaków.'
                    },
                    unit_number: {
                        max: 'Numer budynku obiektu nie może być dłuższy niż ${max} znaków.'
                    },
                    subunit_number: {
                        max: 'Number lokalu w obiekcie nie może być dłuższy niż ${max} znaków.'
                    },
                    zip_code: {
                        max: 'Kod pocztowy obiektu nie może być dłuższy niż ${max} znaków.',
                        matches: 'Kod pocztowy obiektu musi mieć format XX-XXX.'
                    }
                }
            }
        },
        documents: {
            instructions: 'Poniżej znajduje się lista dokumentów związanych z bieżącym obiektem. Uzupełniaj listę dokumentów o statystyki placówki, plany, raporty, itp...',
            table: {
                column: {
                    description: 'Opis techniczny',
                    original_file_name: 'Nazwa pliku',
                    actions: 'Akcje'
                },
                tooltip: {
                    download: 'Pobierz',
                    delete: 'Usuń',
                },
                dense: 'Gęsto'
            },
            search: {
                placeholder: 'Szukaj'
            },
            message_box: {
                button: {
                    cancel: 'Anuluj',
                    confirm: 'Tak'
                },
                delete: {
                    title: 'Usuwanie dokumentów obiektu',
                    text: 'Czy na pewno chcesz usunąć wskazany dokument obiektu?'
                },
            },
            button: {
                new: 'Dodaj dokument'
            },
            dialog: {
                edit: {
                    title: 'Edycja dokumentu obiektu',
                    form: {
                        name: 'Nazwa pliku',
                        technical_description: 'Opis techniczny'
                    },
                    label: {
                        upload: 'Plik załącznika nie może przekraczać 3 MB.',
                        file: 'Załączony plik'
                    },
                    schema: {
                        validation: {
                            name: {
                                max: 'Nazwa pliku dokumentu nie może być dłuższa niż ${max} znaków.',
                                required: 'Nazwa pliku dokumentu jest wymagana',
                            },
                            description: {
                                max: 'Opis techniczny dokumentu nie może być dłuższy niż ${max} znaków.'
                            }
                        },
                        outgoing: {
                            error: 'Wystąpił błąd podczas przygotowywania danych do zapisu: '
                        }
                    },
                },
            },
            message: {
                delete: {
                    succeeded: 'Dokument obiektu został usunięty',
                    failed: 'Wystąpił błąd podczas usuwania dokumentu obiektu: ',
                }
            },
        },
        equipment: {
            instructions: 'Poniżej znajduje się lista wyposażenia bieżącego obiektu. Uzupełniaj listę o składniki istotne dla prowadzenia przetargów, oraz edytuj ich atrybuty.',
            search: {
                placeholder: 'Szukaj'
            },
            table: {
                column: {
                    name: 'Nazwa',
                    description: 'Opis',
                    attributes: 'Atrybuty',
                    actions: 'Akcje'
                },
                tooltip: {
                    edit: 'Edytuj',
                    delete: 'Usuń',
                },
                dense: 'Gęsto'
            },
            message: {
                incoming: {
                    error: "Wystąpił błąd podczas weryfikowania pobranych danych: ",
                },
            },
            message_box: {
                delete: {
                    title: 'Usuwanie wyposażenie obiektu',
                    text: 'Czy na pewno chcesz usunąć wskazane wyposażenie obiektu?',
                    succeeded: 'Wyposażenie obiektu zostało usunięte.',
                    failed: 'Wystąpił błąd podczas usuwania wyposażenia obiektu.'
                },
                edit: {
                    title: 'Edytowanie wyposażenia obiektu',
                    text: 'Czy na pewno chcesz etytować wskazane wyposażenie obiektu?',
                    succeeded: 'Wyposażenie obiektu zostało zaktualizowane.',
                    failed: 'Wystąpił błąd podczas aktualizowania wyposażenia obiektu.'
                },
                button: {
                    cancel: 'Anuluj',
                    confirm: 'Tak',
                }
            },
            button: {
                new: 'Dodaj wyposażenie obiektu'
            },
            add: {
                title: 'Dodawanie wyposażenia obiektu',
                form: {
                    facility_equipment_category_id: 'Kategoria wyposażenia',
                    facility_equipment_id: 'Wyposażenie',
                    facility_equipment_attribute_id: 'Atrybuty wyposażenia'
                },
                schema: {
                    validation: {
                        object: {
                            required: 'Należy wybrać opcję w selektorze. Opcje pojawią się po wybraniu kategorii.'
                        },
                        facility_equipment_id: {
                            id: {
                                required: 'Należy wybrać element wyposażenia.'
                            }
                        },
                        facility_equipment_attribute_id: {
                            required: 'Należy wybrać co najmniej jeden atrybut wyposażenia.'
                        }
                    },
                    outgoing: {
                        error: 'Wystąpił błąd podczas przygotowywania danych do zapisu: '
                    },
                    incoming: {
                        empty: 'Wystąpił błąd podczas weryfikowania pobranych danych. Brak obiektu lub pól danych.'
                    }
                },

            },
            edit: {
                title: 'Edytowanie wyposażenia obiektu',
                form: {
                    facility_equipment_attribute_id: 'Atrybuty wyposażenia',
                },
                schema: {
                    incoming: {
                        empty: 'Wystąpił błąd podczas weryfikowanie pobranych danych. Brak obiektu lub pól danych.'
                    },
                    outgoing: {
                        error: 'Wystąpił błąd podczas przygotowywania danych do zapisu: '
                    }
                },
            }
        },
        certificates: {
            instructions: 'Poniżej znajduje się lista zdefiniowanych przez Ciebie certyfikatów dla wybranego obiektu. Na bieżąco dodawaj, modyfikuj i usuwaj informacje o certyfikatach, aby były aktualne w momencie przetargów.',
            search: {
                placeholder: 'Szukaj',
            },
            table: {
                column: {
                    name: 'Nazwa',
                    description: 'Opis',
                    actions: 'Akcje'
                },
                tooltip: {
                    delete: 'Usuń'
                },
                dense: 'Gęsto'
            },
            message: {
                incoming: {
                    error: 'Wystapił błąd podczas weryfikowanie pobranych danych: '
                },
                submit: {
                    succeeded: 'Certyfikat obiektu został dodany prawidłowo.'
                }
            },
            button: {
                new: 'Dodaj certyfikat obiektu'
            },
            message_box: {
                button: {
                    cancel: 'Anuluj',
                    confirm: 'Tak'
                },
                delete: {
                    title: 'Usuwanie certyfikatu obiektu',
                    text: 'Czy na pewno chcesz usunąć wskazany certyfikat obiektu?',
                    succeeded: 'Certyfikat obiektu został usunięty.',
                    failed: 'Wystąpił błąd podczas usuwania certyfikatu obiektu'
                }
            },
            dialog: {
                add: {
                    title: 'Dodawanie nowego certyfikatu obiektu',
                    button: {
                        cancel: 'Anuluj',
                        submit: 'Zapisz'
                    },
                    form: {
                        facility_certificate_id: 'Certyfikat'
                    },
                    message: {
                        outcoming: {
                            error: 'Wystąpił błąd podczas przygotowywania danych do zapisu: '
                        }
                    }
                }
            },
        },
    },
};