// @mui
import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';

// ----------------------------------------------------------------------

export const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    backgroundImage: `url(/assets/bg_dark.jpg)`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    alignItems: 'center',
    backgroundSize: 'cover',
}));

export const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    minWidth: 360,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
        minWidth: 480
    }
}));

export const SectionStyle = styled(Card)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        float: 'right'
    },
    [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(20),
    },
}));
