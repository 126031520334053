// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components;
import ScrollToTop from './components/ScrollToTop';
import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import i18n from "./locales/i18n";
import { I18nextProvider } from "react-i18next";
import UserEditDialogForm from "./sections/@dashboard/user/edit-dialog/UserEditDialogForm";
import Snackbar from "./components/Snackbar";

// ---------------------------------------------------------------------

export default function App() {
    return (
        <MotionLazyContainer>
            <ThemeProvider>
                {/*<ThemeSettings>*/}
                    <NotistackProvider>
                        <I18nextProvider i18n={ i18n }>
                            <ProgressBarStyle/>
                            <ScrollToTop/>
                            <Router/>
                            <UserEditDialogForm/>
                            <Snackbar/>
                        </I18nextProvider>
                    </NotistackProvider>
                {/*</ThemeSettings>*/}
            </ThemeProvider>
        </MotionLazyContainer>
    );
}
