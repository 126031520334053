
export const client_service = {
    list: {
        title: 'Katalog usług',
        details:
            `Poniżej znajduje się lista Twoich usług.
            Zakładaj, aktualizuj i uzupełniaj listę usług wraz ze szczegółowymi informacjami dotyczącymi
            ich parametrów.
            Im bardziej szczegółowe dane o usłudze tym większa możliwość spełnienia kryteriów
            zamawiającego podczas procesu wyszukiwania dostawców (RFI).`,

        button_add: {
            label: 'Dodaj usługę'
        },

        button_request_missing: {
            label: 'Brakuje Twojej usługi?'
        },
        request_missing_modal: {
            title: 'Zgłoś brakującą pozycję słownikową',
            message: {
                label: 'Pisz tutaj'
            },
            button_send: 'Wyślij wiadomość'
        },

        tabs: {
            title: 'Katalog usług',
            my_services: {
                label: 'Moje usługi'
            }
        },

    },

    client_service_delete_modal: {
        title: 'Czy na pewno?',
        details: `Usunięcie wybranej usługi spowoduje, że nie będzie ona brana pod uwage w wyszukiwaniu w nowych przetargach`
    },

    client_service_modal: {
        title: {
            all: 'Szczegóły usługi',
            attributes: 'Atrybuty usługi',
            areas: 'Regiony'
        },

        validator: {
            client_service: {
                type_error: 'Pole wymagane',
            },
            quantity: {
                type_error: 'Niewłaściwy format liczbowy',
                required: 'Pole wymagane',
            },
            attribute: {
                type_error: 'Pole wymagane',
            },
            unit_of_measure: {
                required: 'Pole wymagane',
            }
        },

        client_service: {
            label: 'Nazwa usługi',
            helper_text: 'Zmiana usługi spowoduje zresetowanie atrybutów!'
        },

        client_service_description: {
            label: 'Opis usługi',
        },

        attributes: {
            attribute: {
                label: 'Atrybut'
            },
            quantity: {
                label: 'Ilość'
            },
            unit_of_measure: {
                label: 'Jednostka miary'
            },

            button_add: {
                label_first: 'Dodaj atrybut',
                label_next: 'Dodaj kolejny atrybut'
            }
        },

        areas: {
            levels: {
                area_1_level_label: 'Cały kraj',
                area_2_level_label: 'Całe województwo',
                area_3_level_label: 'Cały powiat',
                area_4_level_label: 'Cała gmina'
            },
            button_add: {
                label_first: 'Dodaj region',
                label_next: 'Dodaj nowy region'
            }
        },
    }
};