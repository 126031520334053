import axios from "../../utils/axios";

export type FacilityManagerTenderStateResponse = {
    rfp_id: string;
    rfi_status_id: string;
    rfp_status_id: string;
    tabs: {
        tabBasicInfo: {
            isDisabled: boolean;
            disabledReason: string;
        },
        tabServices: {
            isDisabled: boolean;
            disabledReason: string;
        },
        tabFacilities: {
            isDisabled: boolean;
            disabledReason: string;
        },
        tabDetails: {
            isDisabled: boolean;
            disabledReason: string;
        },
        tabCriteria: {
            isDisabled: boolean;
            disabledReason: string;
        },
        tabSearch: {
            isDisabled: boolean;
            disabledReason: string;
        },
        tabInvite: {
            isDisabled: boolean;
            disabledReason: string;
        },
        tabManageTenderClients: {
            isDisabled: boolean;
            disabledReason: string;
        },
        tabPublish: {
            isDisabled: boolean;
            disabledReason: string;
        },
        tabQuestions: {
            isDisabled: boolean;
            disabledReason: string;
        },
        tabOffers: {
            isDisabled: boolean;
            disabledReason: string;
        },
        tabOfferAnalysis: {
            isDisabled: boolean;
            disabledReason: string;
        },
        tabDecision: {
            isDisabled: boolean;
            disabledReason: string;
        },
    }
}


export const getFacilityManagerTenderState = (rfpId: string) => axios
    .get(`/api/v1/frontend/tenders/facility-manager/${ rfpId }/state`)
    .then((response: { data: { data: FacilityManagerTenderStateResponse } }) => response.data.data)
