import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import clientServiceReducer from './slices/clientService';
import areasSelect from './slices/areasSelect';
import supplierTenderReducer from './slices/supplierTender';
import supplierTenderQuestionReducer from './slices/supplierTenderQuestion';
import tenderReducer from './slices/tender';
import snackbar from './slices/snackbar';
import tenderClientRatingReducer from './slices/tenderClientRating';

// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: [],
};

const userPersistConfig = {
    key: 'user',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['sortBy', 'checkout'],
};

const clientServicePersistConfig = {
    key: 'clientService',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['sortBy', 'checkout'],
};

const areasSelectPersistConfig = {
    key: 'areasSelect',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['sortBy', 'checkout'],
};

const supplierTenderPersistConfig = {
    key: 'supplierTender',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['sortBy', 'checkout'],
};

const supplierTenderQuestionPersistConfig = {
    key: 'supplierTenderQuestion',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['sortBy', 'checkout'],
};

const tenderPersistConfig = {
    key: 'tender',
    storage,
    keyPrefix: 'redux-',
    whitelist: [ 'sortBy', 'checkout' ],
};
const tenderClientRatingPersistConfig = {
    key: 'tenderClientRating',
    storage,
    keyPrefix: 'redux-',
    whitelist: [ 'sortBy', 'checkout' ],
};

const rootReducer = combineReducers({
    user: persistReducer(userPersistConfig, userReducer),
    clientService: persistReducer(clientServicePersistConfig, clientServiceReducer),
    areasSelect: persistReducer(areasSelectPersistConfig, areasSelect),
    supplierTender: persistReducer(supplierTenderPersistConfig, supplierTenderReducer),
    supplierTenderQuestion: persistReducer(supplierTenderQuestionPersistConfig, supplierTenderQuestionReducer),
    tender: persistReducer(tenderPersistConfig, tenderReducer),
    snackbar: snackbar,
    tenderClientRating: persistReducer(tenderClientRatingPersistConfig, tenderClientRatingReducer),
});

export { rootPersistConfig, rootReducer };
