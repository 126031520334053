import * as Yup from "yup";
import { useSnackbar } from "notistack";
// @mui
import { Alert, Button, DialogActions, DialogContent, Divider, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { FormProvider, RHFTextField } from "../../../../../components/hook-form";
// utils
import axios from "../../../../../utils/axios";
// hooks
import useIsMountedRef from "../../../../../hooks/useIsMountedRef";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// redux
import { dispatch } from "../../../../../redux/store";
import { closeEditUserModal } from "../../../../../redux/slices/user";

// ----------------------------------------------------------------------

type FormValuesProps = {
    current_password: string;
    password: string;
    password_confirmation: string;
};

export default function TabChangePassword() {

    const { enqueueSnackbar } = useSnackbar();

    const isMountedRef = useIsMountedRef();

    const ChangePassWordSchema = Yup.object().shape({
        current_password: Yup.string().required('Stare hasło nie może być puste'),
        password: Yup.string()
            .min(8, 'Hasło musi zawierać conajmiej 8 znaków')
            .required('Nowe hasło nie może być puste'),
        password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Hasła muszą być takie same'),
    });

    const defaultValues = {
        current_password: '',
        password: '',
        password_confirmation: '',
        afterSubmit: ''
    };

    const methods = useForm({
        resolver: yupResolver(ChangePassWordSchema),
        defaultValues,
    });

    const {
        reset,
        setError,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = methods;


    const onSubmit = async (data: FormValuesProps) => {
        try {
            await axios.put('/api/user/password', data);
            reset();
            dispatch(closeEditUserModal())
            enqueueSnackbar('Zapisano!');
        } catch (error) {
            if (isMountedRef.current) {
                setError('afterSubmit', { ...error, message: error.message });
            }
        }
    };

    return (
        <FormProvider methods={ methods } onSubmit={ handleSubmit(onSubmit) }>
            <DialogContent sx={ { pt: 1 } }>
                { !!errors.afterSubmit &&
                    <Alert severity="error" sx={ { mb: 3 } }>{ errors.afterSubmit.message }
                    </Alert>
                }
                <Stack spacing={ 3 } alignItems="flex-end">
                    <RHFTextField name="current_password" type="password" label="Stare hasło"/>
                    <RHFTextField name="password" type="password" label="Nowe hasło"/>
                    <RHFTextField name="password_confirmation" type="password" label="Potwierdź nowe hasło"/>
                </Stack>
            </DialogContent>

            <Divider/>

            <DialogActions>
                <Button color="error" variant="outlined" onClick={ () => dispatch(closeEditUserModal()) }>
                    Anuluj
                </Button>
                <LoadingButton type="submit" color="primary" variant="contained" loading={ isSubmitting }>
                    Zapisz i zaktualizuj liste
                </LoadingButton>
            </DialogActions>
        </FormProvider>
    );
}
