import axios from '../../utils/axios';
import { dispatch } from '../store';
import { createSlice } from '@reduxjs/toolkit';
import { SupplierTenderState, SupplierTenderStateResponse } from "../../@types/supplierTender";
import { RfpClientStatus } from "../../@types/rfpClientStatus";
import { RfpStatus } from "../../@types/rfpStatus";

// ----------------------------------------------------------------------


const initialState: SupplierTenderState = {
    rfp_status_id: RfpStatus.DRAFT,
    rfp_client_status_id: RfpClientStatus.PENDING,
    supplier_tender_status_name: '',
    supplierTenderActiveTab: 'general',
    rfp_name: '',
    rfi_id: '',
    round: 1,
    rfp_client_id: '',
    deadline_questions_submission: '',
    deadline_offer_submission: '',
    is_published: false,
    is_rfp_offer_published: false,
    isLoading: false,
    isLoaded: false,
    error: null,
};

const slice = createSlice({
    name: 'supplierTender',
    initialState,
    reducers: {
        resetSupplierTenderState: () => initialState,

        startSupplierTenderStateLoading(state) {
            state.isLoading = true;
        },

        getSupplierTenderStateSuccess(state, action) {
            const data = action.payload;

            state.isLoaded = true;
            state.isLoading = false;
            state.rfi_id = data.rfi_id;
            state.rfp_name = data.rfp_name;
            state.round = data.round;
            state.supplier_tender_status_name = data.supplier_tender_status_name;
            state.is_published = data.is_published;
            state.is_rfp_offer_published = data.is_rfp_offer_published;
            state.rfp_client_id = data.rfp_client_id;
            state.deadline_questions_submission = data.deadline_questions_submission;
            state.deadline_offer_submission = data.deadline_offer_submission;
            state.rfp_status_id = String(data.rfp_status_id) as RfpStatus;
            state.rfp_client_status_id = String(data.rfp_client_status_id) as RfpClientStatus;
        },

        getSupplierTenderStateHasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setSupplierTenderActiveTab(state, action) {
            state.supplierTenderActiveTab = action.payload;
        },

    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    resetSupplierTenderState,
    setSupplierTenderActiveTab,
} = slice.actions;


export function getSupplierTenderState(rfpId: string) {
    return async () => {
        try {
            const response: { data: { data: SupplierTenderStateResponse } } =
                await axios.get(`/api/v1/frontend/tenders/supplier/${ rfpId }/state`);
            dispatch(slice.actions.getSupplierTenderStateSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.getSupplierTenderStateHasError(error));
        }
    };
}