import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function LoadingButton(theme: Theme) {
    return {
        MuiLoadingButton: {
            styleOverrides: {
                root: {
                    minWidth: 'initial',
                    '&.MuiButton-text': {
                        '& .MuiLoadingButton-startIconPendingStart': {
                            marginLeft: 0
                        },
                        '& .MuiLoadingButton-endIconPendingEnd': {
                            marginRight: 0
                        }
                    },

                    minHeight: '2.35em',

                    '& .iconify': {
                        height: '1.6em',
                        width: 'auto',
                    }
                }
            }
        }
    };
}
