import { AreasSelectState } from "../../@types/areasSelect";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";


const initialState: AreasSelectState = {
    modalIsOpen: false,
    level1Data: {},
    level2Data: {},
    level3Data: {},
    level4Data: {},

    // url_level1: '/api/v1/areas/level1',
    // url_level2: '',
    // url_level3: '',
    // url_level4: '',
    urls: {
        level1: '/api/v1/areas/level1',
        level2: '',
        level3: '',
        level4: '',
    },

    postData: null,
};


const slice = createSlice({
    name: 'areasSelect',
    initialState,
    reducers: {
        setPostData(state, action) {
          state.postData = action.payload;
        },
        closeAreasSelectModal(state) {
            state.postData = initialState.postData;
            state.urls = initialState.urls;
            state.modalIsOpen = false;
        },
        openAreasSelectModal(state) {
            state.postData = initialState.postData;
            state.modalIsOpen = true;
        },
        setLevel1Data(state, action) {
            state.level1Data = action.payload;
            if(action.payload.id) {
                state.urls.level2 = `/api/v1/areas/level1/${action.payload.id}/level2`;
            } else state.urls.level2 = '';
        },
        setLevel2Data(state, action) {
            state.level2Data = action.payload;
            if(action.payload.id) {
                state.urls.level3 = `/api/v1/areas/level1/${state.level1Data.id}/level2/${action.payload.id}/level3`;
            } else state.urls.level3 = '';
        },
        setLevel3Data(state, action) {
            state.level3Data = action.payload;
            if(action.payload.id) {
                state.urls.level4 = `/api/v1/areas/level1/${state.level1Data.id}/level2/${state.level2Data.id}/level3/${action.payload.id}/level4`;
            } else state.urls.level4 = '';
        },

    },
});


export default slice.reducer;

export const {
    openAreasSelectModal,
    closeAreasSelectModal
} = slice.actions;


export function setLevel1Data(data: any) {
    return async () => {
        dispatch(slice.actions.setLevel1Data(data));
    }
}
export function setLevel2Data(data: any) {
    return async () => {
        dispatch(slice.actions.setLevel2Data(data));
    }
}
export function setLevel3Data(data: any) {
    return async () => {
        dispatch(slice.actions.setLevel3Data(data));
    }
}

export function setPostData(data: any) {
    return async () => {
        dispatch(slice.actions.setPostData(data));
    }
}