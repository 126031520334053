export const client = {
    information: {
        edit    : {
            registry_data: {
                tab: 'Dane ogólne',
                instructions: 'Wpisz i na bieżąco aktualizuj dane organizacji. WAŻNE: szczegółowe i wyczerpujące uzupełnienie tej sekcji zwiększy Twoją szansę w procesie wyszukiwania Dostawców przez Zamawiających (RFI). To pozwoli Twojej organizacji częściej otrzymywać zapytania ofertowe w kolejnych procesach. Dodatkowo dane organizacji będą niezbędna do bieżących kontaktów oraz rozliczeń z platformą.',
                label: {
                    registry_data: 'Dane rejestrowe',
                    address: 'Adres',
                    details: 'Dane szczegółowe'
                },
                form: {
                    registry_name: 'Nazwa rejestrowa',
                    short_name: 'Nazwa skrócona',
                    nip: 'NIP',
                    krs: 'KRS',
                    regon: 'REGON',
                    bank_account_number: 'Numer konta do roliczeń',
                    www_address: 'Adres www',
                    area_1_level_id: 'Kraj',
                    street: 'Ulica',
                    city: 'Miasto',
                    unit_number: 'Numer budynku',
                    zip_code: 'Kod pocztowy',
                    subunit_number: 'Numer lokalu',
                    turnover: 'Obrót za ostatnie 3 lata fiskalne',
                    facility_function: 'Obsługiwane typy placówek',
                    business_sector: 'Sektory biznesu',
                    employee: 'Forma zatrudnienia i ilość zatrudnionych',
                    business_start_date: 'Data rozpoczęcia działalności w Polsce',
                    insurance_amount: 'Wartość posiadanej polisy ubezpieczeniowej (w PLN)',
                    max_payment_days: ' Maksymalny akceptowalny termin płatności (w dniach)',
                    last_quality_penalty_summons: ' Data ostatniego wezwania do zapłaty kary umownej z tytułu jakości usług',
                },
                button: {
                    save: 'Zapisz',
                    add_business_sector: 'Dodaj sektor',
                    add_facility_function: 'Dodaj typ placówki',
                    add_employee: 'Dodaj formę zatrudnienia i ilość',
                    missing: {
                        business_sector: 'Brakuje Twojego sektora?',
                        facility_type: 'Brakuje Twojego typu placówki?'
                    }
                },
                validation: {
                    krs: {
                        matches: 'Numer KRS musi mieć dokładnie 10 cyfr.'
                    },
                    nip: {
                        matches: 'Numer NIP musi mieć dokładnie 10 cyfr.'
                    },
                    regon: {
                        matches: 'Numer REGON musi mieć dokładnie 9 cyfr.'
                    },
                    registry_name: {
                        required: 'Nazwa rejestrowa jest wymagana.',
                        max: 'Nazwa rejestrowa nie może być dłuższa niż ${max} znaków.'
                    },
                    short_name: {
                        required: 'Nazwa skrócona jest wymagana.',
                        max: 'Nazwa skrócona nie może być dłuższa niż ${max} znaków.'
                    },
                    bank_account_number: {
                        max: 'Numer konta bankowego nie może być dłuzszy niż ${max} znaków.'
                    },
                    www_address: {
                        url: 'Prawidłowa składnia adresu www jest wymagana (np: https://www.adres.org).',
                        max: 'Adres www nie może być dłuższy niż ${max} znaków.'
                    },
                    area_1_level_id: {
                        required: 'Proszę wybrać kraj z listy rozwijanej.',
                        integer: 'Identyfikator kraju musi być liczbą całkowitą',
                        positive: 'Identyfikator kraju musi byc liczbą dodatnią'
                    },
                    city: {
                        required: 'Nazwa miasta jest wymagana.',
                        max: 'Nazwa miasta nie może być dłuższa niż ${max} znaków'
                    },
                    zip_code: {
                        required: 'Kod pocztowy jest wymagany.',
                        matches: 'Prawidłowy format kodu pocztowego to XX-XXX.'
                    },
                    street: {
                        required: 'Nazwa ulicy jest wymagana.',
                        max: 'Nazwa miasta nie może być dłuższa niż ${max} znaków.'
                    },
                    unit_number: {
                        max: 'Numer budynku nie może być dłuższy niż ${max} znaków.'
                    },
                    subunit_number: {
                        max: 'Numer lokalu nie może być dłuższy niż ${max} znaków.'
                    },
                    turnover: {
                        matches: 'Wartość obrotu musi być wyrażona kwotą pieniężną (maximum 12 cyfr przed przecinkiem).'
                    },
                    insurance_amount: {
                        matches: 'Wartość ubezpieczenia musi być wyrażona kwotą pieniężną (maximum 12 cyfr przed przecinkiem).'
                    },
                    max_payment_days: {
                        matches: 'Termin płatności musi być liczbą naturalna, maximum 3 cyfrową.',
                    }
                },
                message: {
                    submit : {
                        succeeded: 'Dane organizacji zostały zapisane.',
                        failed: 'Wystąpił błąd podczas zapisu danych organizacji: ',
                    },
                    noEmployeeOptions: 'Użyj przycisku aby dodać element.'
                },
                schema: {
                    incoming: {
                        error: 'Wystąpił błąd podczas weryfikowania danych przychodzących: ',
                        business_sector_types: {
                            min: 'Brak danych słownikowych sektorów biznesu.'
                        },
                        facility_functions: {
                            min: 'Brak danych słownikowych typów placówek.'
                        },
                        employment_types: {
                            min: 'Brak danych słownikowych form zatrudnienia.'
                        }
                    },
                    outgoing: {
                        error: 'Wystąpił błąd podczas przygotowywania danych do zapisu: ',
                    },
                },
                dialog: {
                    missing: {
                        title: 'Instrukcja',
                        label: 'Zgłoszenia brakującej pozycji słownikowej należy dokonać wysyłając email z wybranego programu pocztowego na poniższy adres. Treść powinna zawierać proponowany wpis oraz krótkie uzasadnienie.',
                        mailto: 'Wyślij wiadomość do: ',
                        mailto_address: 'zgloszenia@cf.pl',
                        button: {
                            cancel: 'Anuluj',
                            send: 'Zamknij'
                        }
                    }
                }
            },
            verification_criteria: {
                tab: 'Kryteria weryfikacji',
                table: {
                    column: {
                        name: 'Nazwa',
                        description: 'Opis',
                        is_verified: 'Stan zatwierdzenia',
                        status: 'Status kryterium',
                        client_verification_criteria_answer_id: 'Odpowiedź',
                        actions: 'Akcje',
                    },
                    row : {
                        verification_completed: 'zatwierdzone',
                        verification_pending: 'oczekuje na zatwierdzenie',
                        verification_unrequired: 'nie wymaga zatwierdzenia',
                        fulfilled: 'Uzupełnione',
                        unfulfilled: 'Nieuzupełnione',
                        rejected: 'Odrzucone',
                        required_file_present: 'Wymagany plik załączony',
                        required_file_empty: 'Brak wymaganego pliku',
                        file_not_required: 'Plik niewymagany',
                        unanswered: 'Brak odpowiedzi',
                        answered: 'Udzielono odpowiedzi',
                    },
                    tooltip: {
                        answer: 'Odpowiedz',
                        upload: 'Wgraj plik',
                        delete: 'Usuń',
                        download: 'Pobierz plik'
                    },
                    dense: 'Gęsto'
                },
                button : {
                    new: 'Dodaj nowe kryterium',
                    delete_selected: 'Usuń wybrane',
                },
                search: {
                    placeholder: 'Szukaj..'
                },
                instructions: 'Udziel odpowiedzi na poniższe kryteria weryfikacji określone przez administratora platformy, załącz plik jeśli jest wymagany.',
                dialog: {
                    title: 'Edytor kryterium weryfikacji organizacji',
                    form: {
                        verification_criterion_id: 'Kryterium weryfikacji',
                        document_name: 'Nazwa załącznika',
                        document_description: 'Opis załącznika'
                    },
                    button: {
                        cancel: 'Anuluj',
                        save: 'Zapisz',
                    },
                    validation: {
                        verification_criterion_id: {
                            required: 'Proszę wybrać kryterium z listy rozwijanej.',
                            integer: 'Identyfikator kryterium musi być liczbą całkowitą.',
                            positive: 'Identyfikator kryterium musi być liczbą dodatnią.',
                        },
                        document_name: {
                            when_document: 'Nazwa jest wymagana jeśli załączono plik.',
                            when_document_required: 'Nazwa jest wymagana dla kryterium z obowiązkowym załącznikiem.',
                            max: 'Nazwa sokumentu nie może być dłuższa niż ${max} znaków.',
                        },
                        document_description: {
                            max: 'Opis załącznika nie może być dłuższy niż ${max} znaków.'
                        },
                        document: {
                            required: 'Załącznik jest wymagany dla tego kryterium weryfikacji.',
                            test: 'Rozmiar załącznika nie może przekraczać 3 MB.'
                        }
                    },
                    label : {
                        upload: 'Plik załącznika nie może przekraczać 3 MB.',
                        file: 'Załączony plik'
                    },
                    message: {
                        invalid_response: 'Wystąpił błąd pobierania danych.'
                    }
                },
                confirm: {
                    delete: {
                        title: 'Czy na pewno chcesz usunąć wskazane kryterium weryfikacji?',
                        text: 'Operacja jest nieodwracalna, i spowoduje równoczesne usunięcie z kolejki weryfikacyjnej.',
                        button: {
                            cancel: 'Anuluj',
                            confirm: 'Usuń'
                        }
                    }
                },
                message: {
                    deleted: 'Kryterium weryfikacji zostało usunięte.'
                },
                answer: {
                    title: 'Odpowiedź dotycząca kryterium weryfikacji',
                    button: {
                        cancel: 'Anuluj',
                        save: 'Zapisz'
                    },
                    form: {
                        client_verification_criteria_answer_id: 'Odpowiedź',
                    },
                    schema: {
                        incoming: {
                            error: 'Wystąpił błąd podczas weryfikowania danych przychodzących: ',
                        },
                        validation: {
                            client_verification_criteria_answer_id: {
                                required: 'Należy wybrać odpowiedź w selektorze.'
                            }
                        },
                        outgoing: {
                            error: 'Wystąpil bład podczas przygotowywania danych do zapisu: ',
                        }
                    }
                }
            },
            employees: {
                dialog: {
                    title: 'Forma zatrudnienia i ilość zatrudnionych',
                    button: {
                        cancel: 'Anuluj',
                        save: 'Zapisz'
                    },
                    form: {
                        number: 'Ilość zatrudnionych',
                        employment_type_id: 'Forma zatrudnienia',
                    },
                    schema: {
                        validation: {
                            employment_type_id: {
                                moreThan: 'Identyfikator formy zatrudnienia musi być większy niż ${more}',
                                required: 'Identyfikator formy zatrudnienia jest wymagany',
                            },
                            number: {
                                typeError: 'Ilość zatrudnionych musi być liczbą naturalną',
                                moreThan: 'Ilość zatrudnionych musi być większa niż ${more}',
                                LessThan: 'Ilość zatrudnionych musi być mniejsza niż ${less}',
                                required: 'Należy wpisać ilość zatrudnionych',
                            }
                        }
                    }
                }
            }
        },
    }
};