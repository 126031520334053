import { ReactNode } from 'react';
// form
import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

// ----------------------------------------------------------------------

type Props = {
    children: ReactNode;
    methods: UseFormReturn<any>;
    onSubmit?: VoidFunction;
    formId?: string
};

export default function FormProvider({ children, onSubmit, methods, formId }: Props) {
    return (
        <Form { ...methods }>
            <form id={formId} onSubmit={ onSubmit }>{ children }</form>
        </Form>
    );
}
