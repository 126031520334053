import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack } from "@mui/material";
import ClientServiceAttributes from "../../client/client-service/ClientServiceAttributes";
import { LoadingButton } from "@mui/lab";
import { dispatch, useSelector } from "../../../redux/store";
import {
    addClientService,
    closeEditClientServiceModal,
    editClientService,
    updatePartialClientService
} from "../../../redux/slices/clientService";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import RHFRemoteAutocomplete from "../../../components/hook-form/RHFRemoteAutocomplete";
import useAuth from "../../../hooks/useAuth";
import ClientServiceAreas from "../../client/client-service/ClientServiceAreas";
import AreasSelectDialog from "../AreasSelectDialog";


export default function ClientServiceEditDialog() {

    const { t } = useTranslation('client_service', { keyPrefix: 'client_service_modal' });
    const authUser = useAuth().user;


    // --- Reducer
    const {
        clientServiceModal,
        clientService,
        clientServiceDefaultValues
    } = useSelector((state) => state.clientService)


    const ClientServiceSchema = Yup.object().shape({
        clientService: Yup.object({ id: Yup.number().typeError(t('validator.client_service.type_error')) }),
        attributes: Yup.array().of(
            Yup.object({
                attribute: Yup.object({ id: Yup.number().typeError(t('validator.attribute.type_error')) }),
                quantity: Yup.lazy((value) => (value === '' ? Yup.string().required(t('validator.quantity.required')) : Yup.number().typeError(t('validator.quantity.type_error')))),
                unitOfMeasure: Yup.string().required(t('validator.unit_of_measure.required')),
            })
        ),

    });


    const defaultValues = clientServiceDefaultValues;

    const methods = useForm({ resolver: yupResolver(ClientServiceSchema), defaultValues });

    const { reset, handleSubmit } = methods;

    const { setValue } = methods

    useEffect(() => {
        if (clientServiceModal.isOpen) {
            reset(clientServiceDefaultValues);
        }
    }, [clientServiceDefaultValues, clientServiceModal.isOpen, reset]);

    const onSubmit = (data: {}) => {
        (!! clientService?.id) ?
            dispatch(editClientService(authUser?.client_id, clientService?.id, data)) :
            dispatch(addClientService(authUser?.client_id, data));
    };

    const handleOnClientServiceChange = (e:any, newValue:any) => {
        // resetowanie atrybutów przy zmianie nazwy usługi, jeśli wcześniej była jakaś ustawiona
        // (nie usuwamy, jeśli zmiana jest z null na cokolwiek
        if(clientService?.service?.id) {
            setValue('attributes', []);
        }
        dispatch(updatePartialClientService({ service: newValue }));
    }

    return (
        <Dialog fullWidth maxWidth="md" open={clientServiceModal.isOpen}>
            <DialogTitle>
                {
                    (clientServiceModal.hasClientService && t('title.all'))
                    || (clientServiceModal.hasAttributes && t('title.attributes'))
                    || (clientServiceModal.hasAreas && t('title.areas'))
                }
            </DialogTitle>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

                <DialogContent dividers={true}>
                    <Stack divider={<Divider sx={{ my: 2, mx: -3 }}/>}>
                        {(clientServiceModal.hasClientService &&
                            <>
                              <RHFRemoteAutocomplete
                                  remoteUrl="/api/v1/services?without_client_services=1"
                                  inputLabel={ t('client_service.label') }
                                  name="clientService"
                                  helperText={t('client_service.helper_text')}
                                  onChange={handleOnClientServiceChange }
                              />
                              <RHFTextField
                                sx={{ mt:3 }}
                                name="clientServiceDescription"
                                label={t('client_service_description.label')}
                                maxRows={6}
                                rows={3}
                                multiline
                              />
                            </>
                        )}
                        {(clientServiceModal.hasAttributes &&
                          <ClientServiceAttributes title={(clientServiceModal.hasClientService && t('title.attributes'))}/>
                        )}
                        {(clientServiceModal.hasAreas &&
                          <ClientServiceAreas title={(clientServiceModal.hasClientService && t('title.areas'))}/>
                        )}

                    </Stack>

                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={clientServiceModal.isLoading}
                        variant="outlined"
                        color="inherit"
                        onClick={() => dispatch(closeEditClientServiceModal())}
                    >
                        {t('common:button.cancel')}
                    </LoadingButton>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        loading={clientServiceModal.isLoading}
                    >

                        {t('common:button.save')}
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
            <AreasSelectDialog/>

        </Dialog>
    );
}