import React from "react";
import { Button, Grid, MenuItem, Typography } from "@mui/material";
import Iconify from "../../../components/Iconify";
import { useSelector } from "../../../redux/store";
import { useFieldArray, useFormContext } from "react-hook-form";
import { RHFTextField } from "../../../components/hook-form";
import RHFRemoteAutocomplete from "../../../components/hook-form/RHFRemoteAutocomplete";
import { useTranslation } from "react-i18next";

export type ClientServiceAttributesRowData = {
    serviceAttributeValue: { id: string, name: string },
    quantityValue: string | undefined,
    unitOfMeasureValue: string,
};

interface ClientServiceAttributesProps {
    title?: string | boolean;
}

export default function ClientServiceAttributes({ title = false }: ClientServiceAttributesProps) {
    const { t } = useTranslation('client_service', { keyPrefix: 'client_service_modal.attributes' });
    // --- Reducer
    const {
        clientService,
        unitsOfMeasure
    } = useSelector((state) => state.clientService)

    const { control, setValue } = useFormContext();
    const { fields, append } = useFieldArray({
        control,
        name: 'attributes'
    });

    const addEmptyAttribute = () => {
        append({
            attribute: { id: '', name: '' },
            quantity: '',
            unitOfMeasure: '',
        });
    };

    return (
        <>
            {(title) ?
                <Typography variant="h6" sx={{ mb: 3 }}>
                    {title}
                </Typography>
                : ''}
            <Grid container spacing={3}>
                {fields.map((attribute, index) => (
                    <Grid item container key={index} spacing={3} md={12}>
                        <Grid item md={5}>

                            <RHFRemoteAutocomplete
                                disabled={!clientService?.service?.id}
                                key={index}
                                name={`attributes[${index}].attribute`}
                                size={"small"}
                                inputLabel={t('attribute.label')}
                                remoteUrl={`/api/v1/services/${clientService?.service?.id}/attributes?with=attribute_unit_of_measure`}
                                onChange={(e, selectedValue) => {
                                    setValue(`attributes[${index}].unitOfMeasure`, selectedValue.unit_of_measure.id)
                                }}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <RHFTextField
                                label={t('quantity.label')}
                                fullWidth
                                name={`attributes[${index}].quantity`}
                                size="small"
                            />
                        </Grid>
                        <Grid item md={3}>
                            <RHFTextField label={t('unit_of_measure.label')}
                                          select
                                          size="small"
                                          disabled
                                          name={`attributes[${index}].unitOfMeasure`}
                                          fullWidth
                                          SelectProps={{
                                              MenuProps: {
                                                  sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                                              },
                                          }}>
                                {unitsOfMeasure.map((option, key) => (
                                    <MenuItem
                                        key={key}
                                        value={option.id}
                                        sx={{
                                            mx: 1,
                                            my: 0.5,
                                            borderRadius: 0.75,
                                            typography: 'body2',
                                        }}
                                    >
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </RHFTextField>
                        </Grid>
                    </Grid>
                ))}
                <Grid item md={12}>
                    <Button variant="contained"
                            size="small"
                            color="primary"
                            onClick={addEmptyAttribute}
                            startIcon={<Iconify icon={'ic:baseline-plus'}/>}
                    >
                        {(fields.length > 0 && t('button_add.label_first') || t('button_add.label_next'))}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};
