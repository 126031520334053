export const tender = {
    sections: {
        dialog_confirm_delete_tender: {
            title: 'Czy usunąć przetarg z listy ?',
            dialog_content: 'Na tym etapie możliwe jest całkowite usunięcie przetargu z systemu, bez możliwości jego przywrócenia.',
        },
        tab_services: {
            title: 'Dobierz usługi do przetargu',
            instruction_box: 'Wybierz wszystkie usługi, na jakie chciałbyś przeprowadzić postępowanie. Pamiętaj  po wybraniu usług nie będziesz już później mógł dodać kolejnych. W kolejnych etapach możliwe jest jednak usunięcie usług z których chcesz zrezygnować.',
            system_services_card: {
                title: 'Usługi z wybranej kategorii',
                search: {
                    label: 'Wyszukaj usługę po nazwie',
                    placeholder: 'Pisz tutaj...',
                },
            },
            tender_services_card: {
                title: 'Wybrane usługi',
            }
        },
        tender_details: {
            tab_basic_info: {
                title: 'Dane podstawowe',
                instructions: 'Uzupełnij podstawowe informacje o przetargu. Pamiętaj, aby nazwa była jasna zarówno dla Ciebie jak i Dostawców, którzy biorą udział w procesie. Jeśli przed otrzymaniem RFP dostawca powinien podpisać Umowę o poufności (NDA) zaznacz taką opcję. Wówczas konieczne będzie przesłanie draftu tej umowy do Dostawców bezpośrednio mailem. W systemie pokażą się stosownie informacje na jakim etapie jest proces podpisania NDA. RFP zostanie wysłane do Dostawcy dopiero po spełnieniu tego wymogu.',
                nda_required: 'Wymagana umowa o poufności (NDA)',
                additional_dates: 'Zobacz dodatkowe daty, które warto uzupełnić',
                rfp_other_requirements: 'Dodatkowe wymagania',
                tender_schedule: 'Harmonogram przetargu',
                form: {
                    name: 'Nazwa przetargu',
                    description: 'Opis',
                    language: 'Język',
                    offer_validity_days: 'Ważność ofert w dniach',
                    procedure_type: 'Typ procedury przetargu',
                    offer_format: 'Format oferty',

                    // należy pamiętać, że w pliku lang/pl/fields.php na backendzie są te same tłumaczenia
                    tender_from: 'Termin zaproszenia do przetargu',
                    deadline_participation_confirmation: 'Termin potwierdzenia zaproszenia do przetargu',
                    deadline_nda_submission: 'Termin potwierdzania NDA',
                    deadline_questions_submission: 'Termin składania pytań',
                    deadline_answers_submission: 'Termin odpowiadania na pytania',
                    deadline_offer_submission: 'Termin składania ofert',
                    deadline_shortlist_selection: 'Termin wybrania krótkiej listy Dostawców',
                    deadline_final_supplier_selection: 'Data wybrania Dostawcy',
                    deadline_contract_signing: 'Data podpisania umowy',
                    tender_to: 'Data końca przetargu',
                    facility_viewing_date: 'Wizja lokalna',
                    offer_analysis_from: 'Data początku analizowania ofert',
                    offer_analysis_to: 'Data końca analizowania ofert',
                    contract_negotiation_from: 'Początek negocjacji kontraktu',
                    contract_negotiation_to: 'Koniec negocjacji kontraktu',
                    expected_service_implementation_start: 'Data początku wdrożenia usług',
                    expected_service_provision_start: 'Data początku świadczenia usług',
                    planned_publication_date: 'Data publikacji przetargu',
                    // należy pamiętać, że w pliku lang/pl/fields.php na backendzie są te same tłumaczenia /end
                },

                validator: {
                    name: {
                        max: 'Nazwa nie może być dłuższa niż {{amount}} znaków',
                        required: 'Nazwa jest wymagana'
                    },
                    description: {
                        max: 'Opis nie może być dłuższy niż {{amount}} znaków',
                        required: 'Opis jest wymagany'
                    },
                    rfp_language: {
                        required: 'Język jest wymagany'
                    },
                    offer_validity_days: {
                        required: 'Ważność ofert w dniach jest wymagana',
                        numeric: 'Ważność ofert w dniach musi być liczbą',
                    },
                    rfp_procedure_type: {
                        required: 'Typ procedury jest wymagany'
                    },
                    rfp_offer_format: {
                        required: 'Format oferty jest wymagany'
                    },
                    deadline_questions_submission: {
                        date: 'Nieprawidłowy format daty'
                    }
                },

                additional_requirement_add_dialog: {
                    title: 'Dodaj inne wymaganie',
                    form: {
                        name: 'Nazwa wymagania',
                        description: 'Opis wymagania'
                    },
                    validator: {
                        name: {
                            max: 'Nazwa nie może być dłuższa niż {{amount}} znaków',
                            required: 'Nazwa jest wymagana'
                        },
                        description: {
                            max: 'Opis nie może być dłuższy niż {{amount}} znaków',
                        },
                    }
                }
            },

            tab_details: {
                title: 'Szczegóły usług i wyposażenia obiektów',
                instructions: 'Uzupełnij wszystkie pozostałe informacje, niezbędne do przeprowadzenia postępowania. Przypisz parametry i atrybuty usług oraz uzupełnij informacje o obiekcie, niezbędne do wyceny usługi. Pamiętaj , w dużej mierze od Ciebie zależy jak precyzyjne odpowiedzi otrzymasz od dostawców. Dlatego załącz wszystkie ważne informacje.',
                button: {
                    according_to_services: 'Według usług',
                    according_to_facilities: 'Według obiektów',
                    service_price_component: 'Metoda wyceny ({{isSelected}})',
                    edit_attributes: 'Edytuj atrybuty ({{attributesCount}})',
                    edit_facility_equipment: 'Edytuj wyposażenie ({{equipmentCount}})',
                    edit_sla_kpi: 'Edytuj SLA ({{slaCount}})/KPI ({{kpiCount}})',
                },
                tender_facility_service_price_component_select_dialog: {
                    title: 'Metoda wyceny usługi',
                    form: {
                        service_price_component: 'Metoda wyceny'
                    }
                },
                tender_facility_service_attribute_edit_dialog: {
                    title: 'Atrybuty usługi - {{serviceName}}',
                    add_attribute_button: 'Dodaj kolejny',
                    service_attribute_not_defined: 'Ta usługa nie ma zdefiniowanych atrybutów',
                    form: {
                        attribute: 'Atrybut',
                        value: 'Wartość'
                    },
                    validator: {
                        id: {
                            required: "Musisz wybrać atrybut"
                        },
                        value: {
                            type_error: 'Nieprawidłowa wartośc',
                            required: 'Wartość nie może być pusta'
                        }
                    }

                },
                rfp_facility_service_sla_kpi_edit_dialog: {
                    title: 'SLA/KPI usługi - {{serviceName}}',
                    add_sla_button: 'Dodaj nowe SLA',
                    add_kpi_button: 'Dodaj kolejne KPI',
                    form: {
                        kpi_value: 'Wartość',
                        sla_value: 'Wartość'
                    },
                    validator: {
                        sla_id: {
                            required: "Musisz wybrać SLA"
                        },
                        kpi_id: {
                            required: "Musisz wybrać KPI"
                        },
                        value: {
                            type_error: 'Nieprawidłowa wartośc',
                            required: 'Wartość nie może być pusta'
                        }
                    }
                }
            },
            tab_criteria: {
                supplier_criteria: {
                    title: 'Kryteria wyszukiwania dostawcy',
                    instructions: 'Dodaj kryteria, które musi spełniać dostawca, aby mógł zostać zakwalifikowany do wzięcia udziału w planowanym przetargu.',
                    selected_criteria: 'Wybrane kryteria',
                    add_criterion_button: 'Dodaj kryterium',
                    add_supplier_criterion_dialog: {
                        title: 'Dodaj kryterium wyszukiwania dostawcy',
                        form: {
                            selection_criterion: 'Rodzaj',
                        },
                        validator: {
                            selection_criterion: {
                                required: 'Musisz wybrac rodzaj kryterium'
                            }
                        }
                    },
                },
                offer_criteria: {
                    title: 'Kryteria oceny oferty',
                    instructions: 'Dodaj kryteria, według których będą oceniane oferty złożone w przetargu przez dostawców. Na tej podstawie otrzymasz ranking ofert, w kolejności od najwyżej ocenionej zgodnie z wagą procentową zadaną dla każdego z kryteriów.',
                    offer_evaluation_criteria_distribution: 'Rozkład kryteriów oceny oferty',
                    add_offer_criteria_button: 'Dodaj kryterium',
                    left_to_distribute: 'Wolne',
                    total: 'Łącznie',
                    offer_criteria: 'Kryterium Oceny',
                    form: {
                        selection_criterion: 'Rodzaj',
                        weight: 'Waga (w procentach)'
                    },
                    validator: {
                        weight: {
                            max: 'Suma kryteriów ocen nie może przekraczac 100%',
                            numeric: 'Waga musi być liczbą'
                        },
                        selection_criterion: {
                            required: 'Musisz wybrac rodzaj kryterium'
                        }
                    }
                }
            },
            tab_search: {
                summary: {
                    title: 'Wyszukaj',
                    instructions: 'Przed rozpoczęciem wyszukiwania przejrzyj swoje zapytanie. Po rozpoczęciu procesu wyszukiwania nie będzie możliwe dobranie nowych usług i obiektów. Będziesz mógł jednak uzupełnić wymagania co do usług oraz informacje o obiektach.',
                    selected_facilities_and_services: 'Wybrane obiekty i usługi',
                    selected_supplier_criteria: 'Wybrane kryteria wyszukiwania dostawcy',
                    facility: 'Obiekt',
                    services: 'Usługi',
                    search_button: 'Wyszukaj'
                },
                searching: {
                    title: 'Wyszukiwanie w trakcie',
                    instructions: 'Connect Facilities wyszukuje właśnie dostawców według wybranych wcześniej kryteriów. Za chwile otrzymasz listę dostawców spełniających wszystkie zadane kryteria.'
                }
            },
            tab_invite: {
                title_one: 'Wyszukano {{count}} dostawcę',
                title_few: 'Wyszukano {{count}} dostawców',
                title_many: 'Wyszukano {{count}} dostawców',
                selected: '(wybrano {{selected}})', //  z {{total}}
                instructions: 'Poniżej znajduje się lista Dostawców, którzy spełniają wszystkie Twoje kryteria. Jeśli wynik wyszukiwania nie jest satysfakcjonujący – wróć do poprzedniego kroku i zweryfikuj kryteria wyszukiwania. Jeśli ilość wyszukanych dostawców jest wystarczająca na tym etapie możesz odrzucić dostawców, których nie zdecydujesz się zaprosić do postępowania. Pozostaną oni jednak widoczni na liście w wynikach wyszukiwania. Po kliknięciu przycisku „Zaproś” wszyscy Dostawcy, których nie odrzuciłeś otrzymają informację o zaproszeniu do przetargu.',
                client_name: 'Dostawca',
                client_rating: 'Ocena firmy',
                description: 'Opis',
                client_created_at: 'Na platformie od',
                discard_results_button: 'Odrzuć wyniki',
                invite_button: 'Zaproś',
                table: {
                    client_rating_count_one: 'opinia',
                    client_rating_count_few: 'opinie',
                    client_rating_count_many: 'opinii',
                },
                invite_selected_tender_clients_dialog: {
                    title: 'Czy zaprosić wybranych dostawców?',
                    instructions: 'Proszę potwierdzić że wybranych dostawców wysłane zostanie zaproszenie do wzięcia udziału w przetargu.',
                    invite_selected_clients_button: 'Tak, zaproś wybranych dostawców'
                },
                tender_search_result_client_toggle_active_dialog: {
                    title_set_as_inactive: 'Czy na pewno usunąć firmę z listy firm wyszukanych?',
                    title_set_as_active: 'Czy przywrócić udział firmy w przetargu ?',
                    instructions_set_as_inactive: 'Usunięcie z listy spowoduje pominięcie takiej firmy przy zapraszaniu firm do wzięcia udziału w przetargu.',
                    instructions_set_as_active: 'Akcja Przywróć oznacza oznaczenie firmy na liście firm wyszukanych jak spełniającej oczekiwania, do której wysłane zostanie zaproszenie do wzięcia udziału w przetargu.',
                },
                clear_tender_search_result_dialog: {
                    title: 'Czy odrzucić wyniki?',
                    instructions: 'Odrzucenie wyników oznacza wykasowanie z przetargu znalezionych wczesniej dostawców. Aby wyszukać ponownie inną grupę dostawców, należy zmienić warunki wyszukiwania.',
                    clear_search_result_button: 'Tak, odrzuć wszystkich dostawców'
                },
                tender_client_dialog: {
                    client_information: 'Dane dostawcy',
                    client_rating: 'Ocena firmy',

                    settled_tenders_count_one: 'Wygrany przetarg',
                    settled_tenders_count_few: 'Wygrane przetargi',
                    settled_tenders_count_many: 'Wygranych przetargów',

                    invitation_count_one: 'Raz zaproszony do przetargu',
                    invitation_count_few: 'Razy zaproszony do przetargu',
                    invitation_count_many: 'Razy zaproszony do przetargu',
                },
            },
            tab_manage_tender_clients: {
                title: 'Zarządzaj dostawcami',
                instructions: 'Poniżej znajduje się lista Dostawców zaproszonych do przetargu wraz ze statusami formalnych kroków wymaganych przed wysłaniem RFP.',
                client_name: 'Nazwa Dostawcy',
                client_status_name: 'Potwierdzenie udziału',
                nda_sending_status: 'Status wysyłki NDA',
                nda_status: 'Status NDA',
                nda_sent: 'Wysłane',
                nda_not_sent: 'Niewysłane',
                nda_signed: 'Podpisane',
                nda_not_signed: 'Niepodpisane',
                confirm_delete_supplier_dialog: {
                    title: 'Czy na pewno usunąć firmę z listy firm wyszukanych i powiadomionych o wyszukaniu?',
                    instructions: 'Usunięcie z listy spowoduje pominięcie takiej firmy przy zapraszaniu firm do wzięcia udziału w przetargu. Przy formalnej publikacji przetargu nie otrzyma ona powiadomienia o przetargu, ani dostępu do danych szczegółowych.',
                }
            },
            tab_publish: {
                title: 'Publikuj przetarg',
                subtitle: 'Przetarg:',
                instructions: 'Uzupełnij dane o przetargu. Przed użyciem klawisza „Publikuj” przejrzyj swoje zapytanie. Po przesłaniu RFP do Dostawców (opublikowaniu przetargu) nie będzie możliwości zmiany danych, nie będzie możliwości dodawania kolejnych kategorii usług i lokalizacji aż do złożenia przez ofert przez Dostawców i rozpoczęcia kolejnego etapu procesu. W kolejnych etapach przetargu będziesz mógł jednak ograniczać usługi i lokalizacje oraz zmieniać szczegóły techniczne w usługach.',
                back_to_step_button: 'Szczegóły',
                publish_button: 'Publikuj przetarg',
                confirm_publish_tender_dialog: {
                    title: 'Czy opublikować przetarg?',
                    publish_button: 'Tak, opublikuj przetarg',
                    instructions: 'Publikacja przetargu oznacza formalne, publiczne ogłoszenie przetargu i zaproszenie wyselekcjonowanych firm do złożenia ofert. Opublikowanie przetargu powoduje brak możliwości edycji danych określających przedmiot przetargu.',
                },
                cards: {
                    metric: {
                        title: 'Ogólne',
                        status: 'Status:',
                        procedure_type: 'Typ procedury:',
                        language: 'Język:',
                        identifier: 'Identyfikator:'
                    },
                    fill_in_additional_dates: {
                        title: 'Uzupełnij daty',
                        all_dates_filled: 'Wszystkie daty wypełnione',
                        plus_x_more_one: '+ jeszcze {{count}} inna',
                        plus_x_more_few: '+ jeszcze {{count}} inne',
                        plus_x_more_many: '+ jeszcze {{count}} innych',
                        deadline_questions_submission: 'Termin zadawania pytań',
                        deadline_answers_submission: 'Termin złożenia odpowiedzi',
                        deadline_nda_submission: 'Termin złożenia NDA',
                        deadline_offer_submission: 'Termin składania ofert',
                        offer_analysis_from: 'Termin rozpoczęcia negocjacji kontraktów',
                        deadline_shortlist_selection: 'Termin ostatecznego wybrania wykonawcy',
                        expected_service_implementation_start: 'Spodziewany termin rozpoczęcia świadczenia usług',
                        deadline_contract_signing: 'Termin podpisania kontraktu',
                    },
                    invited_suppliers: {
                        title: 'Dostawcy',
                        total_found: 'Wyszukanych',
                        selected: 'Zaproszonych'
                    },
                    facilities_services: {
                        title: 'Obiekty i usługi'
                    },
                    supplier_criteria: {
                        title: 'Kryteria wyszukiwania Dostawców'
                    },
                    offer_criteria: {
                        title: 'Kryteria oceny ofert'
                    },
                    other_requirements: {
                        title: 'Inne wymagania'
                    }
                }
            },
            tab_questions: {
                title: 'Pytania i odpowiedzi',
                instructions: 'Poniżej znajduje się lista pytań Dostawców. Każdy dostawca otrzyma odpowiedź na wszystkie zadane pytania bez informacji od kogo pochodzą. Pamiętaj, że określiłeś datę do której Dostawcy mogą zadawać pytania oraz do kiedy możesz udzielać odpowiedzi. Od momentu udzielenia odpowiedzi Dostawcy musza mieć czas na zweryfikowanie swojej oferty z uwzględnieniem tych informacji.',
                check_offers_button: 'Zobacz oferty',
            },
            tab_offers: {
                title: 'Oferty',
                instructions: 'Poniżej znajduje się lista złożonych ofert od Dostawców wraz ze statusem i ceną.',
                see_question_and_answers_button: 'Zobacz pytania i odpowiedzi',
                client_name: 'Dostawca',
                status: 'Status',
                price: 'Cena',
                created_at: 'Data złożenia oferty',
                offer_sent: 'Oferta przesłana',
                no_offer: 'Brak oferty'
            },
            tab_offers_analysis: {
                title: 'Analiza ofert według kryteriów',
                instructions: 'Poniżej znajduje się lista ofert, złożonych przez Dostawców w kolejności od najwyżej ocenionych, wg. kryteriów oceny oferty zadanych w zapytaniu (RFP). W przypadku kryteriów: Innowacje i Aspekty środowiskowe, moduł AI zaproponuje ocenę procentową na podstawie analizy treści poszczególnych ofert i załączonych do ofert plików. Niezależnie od tego, użytkownik może sam ocenić daną ofertę i nadpisać wartość przyznanych %.',
                client_name: 'Dostawca',
                value: 'Cena',
                total_rating: 'Łączna ocena',
                offers_according_to_criteria: 'Oferty według kryteriów',
                select_offer_criteria: 'Wybierz kryteria do analizy (maks. 4 jednocześnie)',
                rate_button: 'Oceń',
                rate_client_offer_criterion_dialog: {
                    title: 'Oceń {{criterionName}} firmy {{clientName}}',
                    form: {
                        value: 'Ocena procentowa'
                    }
                },
            },
            tab_decision: {
                title: 'Decyzja',
                cancel_tender_button: 'Anulowanie przetargu',
                cancel_tender_instructions: 'Zakończ przetarg bez wyboru Dostawcy. Dostawcy otrzymają informację o zakończeniu przetargu bez rozstrzygnięcia. Nie musisz podawać przyczyny tego trybu. Pamiętaj, że zgodnie z regulaminem jesteś zobligowany do zapłaty za przeprowadzenie procesu przez ConnectFacilities.',
                close_tender_round_button: 'Przejdź do kolejnego etapu',
                close_tender_round_instructions: 'Jeśli na tym etapie chcesz dokonać zmian w założeniach przetargu lub ograniczyć liczbę Dostawców wybierz tą opcję. Pamiętaj, że możesz dokonać tylko wybranych zmian w założeniach. Masz możliwość nieograniczonej ilości etapów przetargu.',
                select_best_offer_button: 'Wybierz najlepszą ofertę',
                select_best_offer_instructions: 'Jeśli na tym etapie znalazłeś odpowiednie rozwiązanie zakończ proces. Wybrany Dostawca otrzyma informację o wyborze jego oferty i będziecie mogli rozpocząć proces negocjowania umowy. Niewybrani Dostawcy otrzymają informację o zakończeniu przetargu i wyborze innego oferenta bez podawania przyczyny. Jednocześnie następuję obowiązek zapłaty za przeprowadzenie procesu przez ConnectFacilities.',
                close_tender_round_dialog: {
                    title: 'Kolejny etap ({{roundNo}}) przetargu',
                    instructions: 'Rozpoczynasz kolejny etap przetargu. Możesz zmienić wybrane założenia i / lub ograniczyć liczbę Dostawców. Podaj, o ile dni chcesz przesunąć cały proces i wynikające z tego wszystkie nadchodzące daty. Jeżeli chcesz samodzielnie zmienić daty wpisz 0.',
                    start_new_round_button: 'Rozpocznij nowy etap przetargu',
                    add_new_days_question: 'Czy dodać automatycznie dodatkowe dni do terminów?',
                    days_margin: {
                        label: 'Automatyczne dodawane dni do terminów',
                    },
                    validator: {
                        days_margin: {
                            type_error: 'Należy podać liczbę!',
                            positive: 'Należy podać liczbę dodatnią',
                        }
                    }
                },
                select_best_offer_dialog: {
                    title: 'Wybierz najlepszą ofertę',
                    instructions: 'Potwierdzając wybór najlepszej oferty zamykasz proces przetargu. Do wybranego Dostawcy zostanie wysłana informacja o wyborze jego oferty. Do niewybranych Dostawców zostanie wysłana informacja o zakończeniu procesu i wyborze innego oferenta. Po zamknięciu przetargu następuje obowiązek zapłaty za przeprowadzenie procesu przez ConnectFacilities. Cieszymy się, że mogliśmy pomóc w procesie!',
                    warning_message: 'Uwaga! Nie oceniono ({{criteriaCount}}) kryteriow w ({{offerCount}}) ofertach. Czy na pewno chcesz wybrać ofertę bez oceny wszystkich pozostałych?',
                    select_best_offer_button: 'Wybierz tą ofertę i zakończ przetarg',
                    table: {
                        client_name: 'Kontrahent',
                        total_percentage: 'Łączna ocena'
                    }
                },
                selected_offer_summary: {
                    title: 'Przetarg zakończony',
                    selected_supplier: 'Wybrany dostawca:'
                }
            },
            common: {
                tender_questions_table: {
                    question: 'Treść pytania',
                    answer: 'Odpowiedź',
                    client_name: 'Kta zadał pytanie',
                    created_at: 'Data zadania',
                    question_dialog: {
                        title: 'Podgląd pytania',
                        question_from: 'Pytanie od {{clientName}} z dnia {{createdAt}}',
                        question_from_without_client: 'Pytanie z dnia {{createdAt}}',
                        answer: 'Odpowiedź',
                        answer_missing: 'Brak odpowiedzi',
                        validator: {
                            answer: {
                                max: 'Odpowiedź może mieć maksymalnie ${max} znaków',
                            },
                        },
                    },
                    ask_question: 'Zadaj nowe pytanie',
                    question_ask_dialog: {
                        title: 'Zadaj nowe pytanie',
                        form: {
                            subject: 'Temat',
                            body: 'Pytanie',
                        },
                        validator: {
                            subject: {
                                required: 'Temat jest wymagany',
                                max: 'Temat może mieć maksymalnie ${max} znaków',
                            },
                            body: {
                                required: 'Pytanie jest wymagane',
                                min: 'Pytanie musi składać się z przynajmniej ${min} znaków',
                                max: 'Temat może mieć maksymalnie ${max} znaków',
                            },
                        },
                        submit: 'Zadaj pytanie',
                    },
                },
                tender_facilities_services_table: {
                    facility: 'Obiekt',
                    services: 'Usługi',
                    error_title: 'Błąd podczas pobierania danych'
                },
                tender_supplier_criteria: {
                    selected_supplier_criteria: 'Wybrane kryteria wyszukiwania dostawcy',
                    back_to_step_button: 'Szczegóły',
                    add_criterion_button: 'Dodaj kryterium'
                },
                cancel_tender_dialog: {
                    title: 'Czy chcesz anulować przetarg?',
                    instructions_1: 'Jeśli nie znalazłeś odpowiedniej oferty lub z innych przyczyn musisz wycofać się z przetargu użyj tej opcji. Pamiętaj, że to postępowanie zostanie zarchiwizowane i będzie dostępne tylko do odczytu. Dostawcy uczestniczący w przetargu zostaną powiadomieni mailowo o anulowaniu przetargu.',
                    instructions_2: 'Jeśli nie znalazłeś odpowiedniej oferty lub z innych przyczyn musisz wycofać się z przetargu użyj tej opcji. Pamiętaj, że to postępowanie zostanie zarchiwizowane i będzie dostępne tylko do odczytu. Dostawcy zaproszeni do przetargu zostaną powiadomieni mailowo o anulowaniu przetargu.',
                    instructions_3: 'Jeśli nie znalazłeś odpowiedniej oferty lub z innych przyczyn musisz wycofać się z przetargu użyj tej opcji. Pamiętaj, że to postępowanie zostanie zarchiwizowane i będzie dostępne tylko do odczytu.',
                    cancel_tender_button: 'Tak, anuluj przetarg'
                },
                tender_offer_summary: {
                    client_information: 'Dane dostawcy',
                    client_rating: 'Ocena firmy',
                    client_offer_information: 'Dane o ofercie',
                    offer_description: 'Opis oferty',
                    offer_files: 'Załączone pliki',
                    other_requirements: 'Inne wymagania',
                    offer_value: 'Cena',
                    criteria: 'Kryteria oceny oferty',
                    services: 'Usługi'
                },
                facility_equipment_preview_dialog: {
                    title: 'Podgląd wyposażenia',
                    equipment_name: 'Nazwa wyposażenia',
                    equipment_description: 'Opis',
                    equipment_category_name: 'Kategoria wyposażenia',
                    equipment_attributes: 'Atrybuty'
                }
            }
        },
        tab_facilities: {
            title: 'Przedmiot przetargu - obiekty',
            instruction_box: 'Dodaj obiekty do swojego postępowania, wybierz je z listy lub utwórz od podstaw. Następnie przypisz usługi do konkretnych obiektów.',
            service_cart: {
                title: 'Wybrane usługi',
                add_to_all_facilities: 'Dodaj wybrane usługi do wszystkich obiektów',
                add_to_chosen_facilities: 'Dodaj wybrane usługi do wybranych obiektów',
            },
            facilities: {
                title: 'Wybrane obiekty ({{count}})',
                add: 'Dodaj obiekt',
            },
            selected_facilities_modal: {
                title: 'Wybierz odpowiednie placówki',
                table: {
                    name: 'Moje placówki',
                    address: 'Adres placówki',
                },
                button: {
                    submit: 'Dodaj do wybranych placówek'
                },
            },

            add_facility_first_step_modal: {
                title: 'Co chcesz dodać?',
                button: {
                    create_new: 'Utwórz nowy obiekt',
                    select_existing: 'Dodaj zdefiniowany do listy obiektów'
                }
            },
            select_existing_facility_modal: {
                title: 'Wybierz obiekt, który chcesz dodać',
                selected_facility: 'Zdefiniowane obiekty',
                submit: 'Dodaj wybrany obiekt',
            },
            select_existing_nested_facility_modal: {
                title: 'Ta placówka ma też placówki podrzędne. Jeśli chcesz je dodać, zaznacz wybrane',
                table: {
                    name: 'Placówki podrzędne',
                    address: 'Adres placówki',
                },
                submit: 'Dodaj zaznaczone',
            },
            create_new_facility_modal: {
                title: '',
                fields: {
                    name: 'Nazwa Obiektu',
                    facility_type: 'Typ obiektu',
                    facility_function: 'Funkcja obiektu',
                    area_1_level: 'Kraj',
                    city: 'Miasto',
                    zip_code: 'Kod pocztowy',
                    street: 'Ulica',
                    unit_number: 'Numer budynku',
                    subunit_number: 'Numer lokalu',
                    description: 'Opis',
                    parent: 'Hierarchia obiektu',
                    parent_empty: 'Obiekt nadrzędny',
                }
            },


        },
    },

    pages: {
        tender_client_rating_list: {
            page_title: 'Przetarg - Wystaw ocenę',
            page_header: 'Wystaw ocenę',
            header_tab: 'Wystaw ocenę',
            instruction_box: 'Oceń firmy, które brały udział w procesie przetargowym. Wystawiając firmie ocenę pamiętaj, że pomagasz sobie i innym zamawiającym promować dobre firmy.',
            table: {
                header: {
                    company_name: 'Dostawca',
                    rating: 'Średnia ocen',
                    actions: 'Akcje',
                },
                rating_label_one: 'opinia',
                rating_label_few: 'opinie',
                rating_label_many: 'opinii',
            },
            tender_client_modal: {
                title: 'Wystaw ocenę firmie {{client_name}}',
                button: {
                    submit: 'Zapisz ocenę',
                }
            }
        },

        tender_details: {
            page_title: 'Przetarg',
            page_header: '{{tenderName}} - etap {{round}}',
            page_header_new: 'Nowy przetarg',

            step_1: 'Dane o przetargu',
            step_2: 'Wyszukaj dostawców',
            step_3: 'Przetarg',
            step_4: 'Decyzja',

            tab_basic_info: 'Dane podstawowe',
            tab_services: 'Usługi',
            tab_facilities: 'Obiekty',
            tab_details: 'Szczegóły',
            tab_criteria: 'Kryteria',
            tab_search: 'Wyszukiwanie',
            tab_invite: 'Zaproś',
            tab_manage_tender_clients: 'Zarządzaj dostawcami',
            tab_publish: 'Publikuj',
            tab_questions: 'Q&A',
            tab_offers: 'Oferty',
            tab_offer_analysis: 'Analiza ofert',
            tab_decision: 'Decyzja',
        },

        tender_list: {
            header_tab: 'Lista przetargów',
            page_title: 'Przetargi',
            page_header: 'Moja lista przetargów',
            page_instruction: 'Poniższa lista zawiera wszystkie Twoje przetargi prowadzone za pomocą ConnectFacilities. Korzystaj z dostępnych filtrów, aby ułatwić proces wyszukiwania.',

            status_name: 'Status',
            round_no: 'Etap',
            procedure_type: 'Typ procedury',
            facilities_count: 'Ilość obiektów',
            services_count: 'Ilość usług',
            rfp_questions: 'Pytania / Odpowiedzi',

            add_button: 'Dodaj nowy przetarg',

            state_options: {
                open: 'W trakcie',
                archived: 'Anulowane',
                closed: 'Zakończone',
            },
        }
    }
};
