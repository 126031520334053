import { useSnackbar } from "notistack";
import { useEffect } from "react";
// redux
import { dispatch, useSelector } from "../redux/store";
import { clearSnackbar } from "../redux/slices/snackbar";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

export default function Snackbar() {
    const { t } = useTranslation();

    const { enqueueSnackbar } = useSnackbar();

    const {
        message,
        options
    } = useSelector((state) => state.snackbar);

    useEffect(() => {
        if (message) {
            enqueueSnackbar(t(message), options);
        }
        dispatch(clearSnackbar());
    }, [t, enqueueSnackbar, message, options]);

    return (<></>)
}
