import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem } from '@mui/material';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// components
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import MyAvatar from '../../../components/MyAvatar';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function AccountPopover() {
    const navigate = useNavigate();

    const { user, logout } = useAuth();

    const isMountedRef = useIsMountedRef();

    const [open, setOpen] = useState<HTMLElement | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleLogout = async () => {
        try {
            await logout();
            navigate(PATH_AUTH.login, { replace: true });

            if (isMountedRef.current) {
                handleClose();
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <IconButtonAnimate
                onClick={ handleOpen }
                sx={ {
                    p: 0, ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                } }
            >
                <MyAvatar/>
            </IconButtonAnimate>

            <MenuPopover
                open={ Boolean(open) }
                anchorEl={ open }
                onClose={ handleClose }
                sx={ {
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                } }
            >
                <Box sx={ { my: 1.5, px: 2.5 } }>
                    <Typography variant="subtitle2" noWrap>
                        { user?.name } { user?.surname }
                    </Typography>
                    <Typography variant="body2" sx={ { color: 'text.secondary' } } noWrap>
                        { user?.email }
                    </Typography>
                </Box>

                <Divider sx={ { borderStyle: 'dashed' } }/>

                <MenuItem onClick={ handleLogout } sx={ { m: 1 } }>
                    Wyloguj
                </MenuItem>
            </MenuPopover>
        </>
    );
}
