import { Stack, SxProps, Typography } from "@mui/material";
import Iconify from "./Iconify";
import { IconifyIcon } from "@iconify/react";


export interface InstructionBoxProps {
    children?: string;
    showIcon?: boolean;
    sx?: SxProps;
    icon?: IconifyIcon | string
}

/**
 * Simple usage:
 * <InstructionBox>Text</InstructionBox>
 * <br/>
 * Icon size will grow with sx's fontSize
 * <br/>
 * You can hide icon with showIcon attribute
 * <br/>
 * You can replace icon with icon atribute
 * @param children
 * @param sx
 * @param showIcon
 * @param icon
 * @constructor
 */
export default function InstructionBox({
                                           children,
                                           sx = {},
                                           showIcon = true,
                                           icon = 'codicon:book'
                                       }: InstructionBoxProps) {
    return (
        <Stack
            sx={{
                color: 'grey.600',
                alignItems: 'center',
                p: 3,
                backgroundColor: 'grey.200',
                borderRadius: 1,
                ...sx
            }}
            direction="row"
            spacing={4}
        >
            {(showIcon) &&
              <Iconify sx={{ height: '3em', width: '3em', minWidth: '3em' }} icon={icon}/>
            }
            <Typography sx={{ fontSize: 'inherit' }}>
                {children}
            </Typography>
        </Stack>
    );
}