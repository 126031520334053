import { useContext } from 'react';

import { AuthContext } from '../contexts/SanctumContext';

// ----------------------------------------------------------------------

export const SUPPLIER_ADMIN = 'Administrator dostawcy';

export const SUPPLIER_USER = 'Dostawca';

export const FACILITY_MANAGER_ADMIN = 'Administrator zamawiającego';

export const FACILITY_MANAGER_USER = 'Zamawiający';

const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) throw new Error('Auth context must be use inside AuthProvider');

    return context;
};

export default useAuth;
