// scroll bar
import 'simplebar/src/simplebar.css';

// @mui
import AdapterDateFns from '@mui/lab/AdapterDateFns';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

import { AuthProvider } from './contexts/SanctumContext';
// redux
import { store } from './redux/store';

//
import App from './App';

import { pl } from "date-fns/locale";

// ----------------------------------------------------------------------

ReactDOM.render(
    <AuthProvider>
        <HelmetProvider>
            <ReduxProvider store={ store }>
                <LocalizationProvider locale={ pl } dateAdapter={ AdapterDateFns }>
                    <SettingsProvider>
                        <CollapseDrawerProvider>
                            <BrowserRouter>
                                <App/>
                            </BrowserRouter>
                        </CollapseDrawerProvider>
                    </SettingsProvider>
                </LocalizationProvider>
            </ReduxProvider>
        </HelmetProvider>
    </AuthProvider>,
    document.getElementById('root')
);
