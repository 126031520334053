import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { User, UserState } from '../../@types/user';
//
import { dispatch } from '../store';
import { unionBy } from 'lodash';

// ----------------------------------------------------------------------

const initialState: UserState = {
    isLoading: false,
    isUserLoading: false,
    error: null,
    users: [],
    user: null,
    userId: null,
    sortBy: null,
    filters: {},
    editUserError: null,
    isOpenModal: false,
    isOpenConfirmModal: false,
    showSuccessSnackbar: false,
    showFailSnackbar: false,
    userEditModalDefaultValues: {},
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },

        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // USER
        startUserLoading(state) {
            state.isUserLoading = true;
        },

        getUsersSuccess(state, action) {
            state.isLoading = false;
            state.users = action.payload;
        },

        updateUserSuccess(state, action) {
            state.isUserLoading = false;
            state.isOpenModal = false;
            state.showSuccessSnackbar = true;
            state.users = unionBy([action.payload], state.users, 'id');
        },

        editUserFailed(state, action) {
            state.isUserLoading = false;
            state.showFailSnackbar = true;
            state.editUserError = action.payload;
        },

        deleteUserSuccess(state, action) {
            state.isUserLoading = false;
            state.showSuccessSnackbar = true;
            state.users = state.users.filter((row) => row.id !== action.payload);
            state.isOpenConfirmModal = false;
        },

        getUserSuccess(state, action) {
            state.isUserLoading = false;
            state.user = action.payload;
        },

        closeEditUserModal(state) {
            state.isOpenModal = false;
        },

        openConfirmDeleteUserModal(state, action) {
            state.userId = action.payload;
            state.isOpenConfirmModal = true;
        },

        closeConfirmDeleteUserModal(state) {
            state.isOpenConfirmModal = false;
        },

        hideSuccessSnackbar(state) {
            state.showSuccessSnackbar = false;
        },

        hideFailSnackbar(state) {
            state.showFailSnackbar = false;
        },

        openEditUserModalSuccess(state, action) {
            const user = action.payload;

            state.userId = user?.id || null;
            state.isUserLoading = false;
            state.editUserError = null;
            state.isOpenModal = true;

            state.userEditModalDefaultValues = {
                name: user?.name || '',
                surname: user?.surname || '',
                position: user?.position || '',
                email: user?.email || '',
                mobile_phone_number: user?.mobile_phone_number || '',
                other_phone_number: user?.other_phone_number || '',
                user_role_id: { id: user?.roles[0].id, name: user?.roles[0].name } || '',
            };
        },

    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    closeEditUserModal,
    openConfirmDeleteUserModal,
    closeConfirmDeleteUserModal,
    hideSuccessSnackbar,
    hideFailSnackbar
} = slice.actions;

// ----------------------------------------------------------------------

export function getUsers() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response: { data: { data: User[] } } = await axios.get('/api/v1/users?with=roles');
            dispatch(slice.actions.getUsersSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getUser(id: string) {
    dispatch(slice.actions.startUserLoading());
    return async () => {
        try {
            const response = await axios.get(`/api/v1/users/${ id }?with=roles`);
            dispatch(slice.actions.getUserSuccess(response.data.data[0]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function editUser(id: string, data: object) {
    dispatch(slice.actions.startUserLoading());
    return async () => {
        try {
            const response = await axios.put(`/api/v1/users/${ id }?with=roles`, data);
            dispatch(slice.actions.updateUserSuccess(response.data.data[0]))
        } catch (error) {
            dispatch(slice.actions.editUserFailed(error.message));
        }
    };
}

// ----------------------------------------------------------------------

export function addUser(data: object) {
    dispatch(slice.actions.startUserLoading());
    return async () => {
        try {
            const response = await axios.post('/api/v1/users?with=roles', data);
            dispatch(slice.actions.updateUserSuccess(response.data.data[0]))
        } catch (error) {
            dispatch(slice.actions.editUserFailed(error.message));
        }
    };
}

// ----------------------------------------------------------------------

export function deleteUser(id: string) {
    dispatch(slice.actions.startUserLoading());
    return async () => {
        try {
            // await new Promise((resolve) => setTimeout(resolve, 1000));
            await axios.delete(`/api/v1/users/${ id }`);
            dispatch(slice.actions.deleteUserSuccess(id))
        } catch (error) {
            dispatch(slice.actions.editUserFailed(error.message));
        }
    };
}

// ----------------------------------------------------------------------

export function openUserEditModal(id: string|null) {
    return async () => {
        try {
            let user = null;

            if (id) {
                dispatch(slice.actions.startUserLoading());
                const response = await axios.get(`/api/v1/users/${ id }?with=roles`);
                user = response.data.data[0];
            }

            dispatch(slice.actions.openEditUserModalSuccess(user));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------
