import axios from "../../utils/axios";

export type TabOfferAnalysisStateResponse = {
    isReadonly: boolean;
    readonlyReason: string;
}

export const getTabOfferAnalysisState = (rfpId: string) => axios
    .get(`/api/v1/frontend/tenders/facility-manager/${ rfpId }/tab-offer-analysis-state`)
    .then((response: { data: { data: TabOfferAnalysisStateResponse } }) => response.data.data)
